import React, {FC, useEffect, useState} from 'react';
import {Input, InputPassword} from "../../../components/FormControls";
import email_svg from "../../../assets/icons/email.svg";
import Button from "../../../components/Button";
import signIn_svg from "../../../assets/icons/signIn.svg";
import google_svg from "../../../assets/icons/google.svg";
import tg_svg from "../../../assets/icons/tg.svg";
import {useGoogleLogin} from "@react-oauth/google";
import {EFieldGroup, ILoginRequest, IUser} from "../../../modules/rest";
import {API} from "../../../modules/api";
import AppStore from "../../../store/AppStore";
import {toast} from "react-toastify";
import {observer} from "mobx-react";
import ChatStore from "../../../store/ChatStore";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {TAuthType} from "../AuthPage";
import AuthModelMessage from "./AuthModelMessage";

interface Props {
  onSuccess: (data: { token: string; user: IUser }) => void;
  authType: TAuthType
}

const AuthSign: FC<Props> = observer(({onSuccess, authType}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  //@ts-ignore
  const login = useGoogleLogin({
    include_granted_scopes: true,
    ux_mode: 'redirect',
    redirect_uri: window.location.href,
    onSuccess: tokenResponse => googleAuth(tokenResponse),
  });

  const [user, setUser] = useState<ILoginRequest>({email: '', password: ''});
  const [loading, setLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);

  const query = new URLSearchParams(window.location.search);
  const queryEmail = query.get('email');

  useEffect(() => {
    if (queryEmail) {
      setUser(prevState => ({...prevState, email: queryEmail}));
    }
  }, []);

  const handleChange = (key: keyof ILoginRequest) => (e: any) => {
    setUser(prevState => ({...prevState, [key]: e.target.value}))
  }

  const googleAuth = async (tokenResponse: any) => {
    try {
      setGoogleLoading(true);
      const utm: any = localStorage.getItem('utm') || {};
      const res = await API.Users.loginWithGoogle(
        {accessToken: tokenResponse.access_token, utm: JSON.parse(utm)},
        [EFieldGroup.UserSubscription, EFieldGroup.UserGender]
      );
      await onSuccess(res);
    } catch (e: any) {
      toast.error(e)
    } finally {
      setGoogleLoading(false);
    }
  }

  const handleLogin = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      let res;
      if (authType === 'register') {
        const utm: any = localStorage.getItem('utm') || {};
        res = await API.Users.register(
          {...user, utm: JSON.parse(utm)},
          [EFieldGroup.UserSubscription, EFieldGroup.UserGender]
        );
      } else {
        res = await API.Users.login(user, [EFieldGroup.UserSubscription, EFieldGroup.UserGender]);
      }
      onSuccess(res);
    } catch (e: any) {
      toast.error(e)
    } finally {
      setLoading(false);
    }
  }

  const authTitle = authType === 'register' ? 'CREATE_ACC' : 'SIGN_IN';

  return (
    <>
      <h2 className='page-title text-accent'>{t(authTitle)}</h2>
      <AuthModelMessage />
      <form onSubmit={handleLogin}>
        <Input
          required
          icon={email_svg}
          label='EMAIL'
          className='mb-3'
          placeholder='E-MAIL'
          value={user.email}
          type='email'
          onChange={handleChange('email')}
        />
        <InputPassword
          onForgotPassword={authType === 'login' ? () => navigate('/auth/recovery') : undefined}
          required
          value={user.password}
          onChange={handleChange('password')}
        />
        <Button
          className='w-100 mt-4'
          icon={authType !== 'register' && signIn_svg}
          iconAbsolute
          loading={loading}
          title={authTitle}
          type='submit'
        />
        <div className='modal-auth-google text-dark-14'>
          <span>{t(authType === 'register' ? 'SIGN_UP_TEXT' : 'SIGN_IN_TEXT')}</span>
        </div>
        <div className='auth__page_footer-btns'>
          <Button
            loading={googleLoading}
            className='flex-1'
            icon={google_svg}
            iconAbsolute
            title={'Google'}
            btnType='secondary'
            onClick={() => login()}
          />
          <Button
            className='flex-1'
            icon={tg_svg}
            iconAbsolute
            title={'Telegram'}
            btnType='secondary'
            href={'telegram'}
          />
        </div>
        <div className='mt-4 text-dark-14 text-center'>
          {authType === 'register'
            ?
            <span>{t('ALREADY_ACC')} <Link to='/auth/login' className='text-accent cursor-pointer'>{t('SIGN_IN')}</Link></span>
            :
            authType === 'login' &&
            <span>{t('NO_ACC')} <Link to='/auth/register'
                                      className='text-accent cursor-pointer'>{t('SIGN_UP')}</Link></span>
          }
        </div>
      </form>
    </>
  );
})

export default AuthSign;