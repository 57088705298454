import React, {FC} from 'react';
import {ReactSVG} from "react-svg";
import {Spinner} from "../../Loadable";

interface Props {
  icon?: any;
  iconText?: string;
  className?: string;
  iconAbsolute?: boolean;
  loading?: boolean;
  price?: number;
  iconPosition?: 'left'|'right';
  children: any;
}

const BtnContent: FC<Props> = ({className, icon, iconPosition, iconAbsolute, children, loading, iconText, price}) => {
  return (
    <div className={`btn-content ${className || ''}`}>
      {icon && iconPosition === 'left' ? <ReactSVG src={icon}
                                                   className={`react-icon btn-icon-left${iconAbsolute ? ' btn-icon-absolute' : ''}`}/> : null}
      {iconText && iconPosition === 'left'
        ?
        <span className={`react-icon btn-icon-left${iconAbsolute ? ' btn-icon-absolute' : ''}`}>{iconText}</span>
        :
        null
      }
      {children}
      {icon && iconPosition === 'right' ? <ReactSVG src={icon}
                                                    className={`react-icon btn-icon-right${iconAbsolute ? ' btn-icon-absolute' : ''}`}/> : null}
      {iconText && iconPosition === 'right'
        ?
        <span className={`react-icon btn-icon-right${iconAbsolute ? ' btn-icon-absolute' : ''}`}>{iconText}</span>
        :
        null
      }
      {price
      ?
        <div className='btn__price'>{price} ⭐</div>
      :
      null
      }
      <Spinner loading={loading || false} absolute  />
    </div>
  );
}

export default BtnContent;