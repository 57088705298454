import React, { FC } from 'react';
import ChatsListItem from './ChatsListItem';
import { useNavigate, useParams } from 'react-router-dom';
import ChatStore from '../../../../store/ChatStore';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import { IConversation } from '../../../../modules/rest';

interface Props {
  className?: string;
}

const ChatsList: FC<Props> = observer(({ className }) => {
  const params = useParams();
  const navigate = useNavigate();

  const onItemClick = (chat: IConversation) => () => {
    if (Number(params.id) !== chat.id) {
      if (ChatStore.activeChat?.id !== chat.id) {
        runInAction(() => {
          ChatStore.messages = [];
          ChatStore.activeChat = undefined;
        });
        setTimeout(() => runInAction(() => {
          ChatStore.activeChat = chat;
        }), 0);
      }
      navigate(`/conversation/${chat.id}`, {replace: true});
    }
  };

  return (
    <div className={className || ''}>
      <div className='chats-side-list-container'>
        <div className='chats-side-list'>
            {ChatStore.chats.map((item) => (
              <ChatsListItem
                onClick={onItemClick(item)}
                key={item.id}
                data={item}
                active={item.id === Number(ChatStore.activeChat?.id)}
              />
            ))}
        </div>
      </div>
    </div>
  );
});

export default ChatsList;