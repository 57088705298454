import React, {FC} from 'react';
import {ICreditsDataItem} from "../modules/utils";
import {useTranslation} from "react-i18next";

interface Props {
  data: ICreditsDataItem;
  credits: string;
  active: boolean;
  onClick: () => void;
}

const CreditsPlanItem: FC<Props> = ({data, active, onClick, credits}) => {
  const {t} = useTranslation();
  return (
    <div className={`premium__item premium__item_stars${active ? ' active' : ''}`} onClick={onClick}>
      {data.bonus ?
        <div className="premium__item_badge">
        <div className="premium__item_badge-content">
        <span className='text-tint text-lowercase'>+{data.bonus}% {t('BONUS')}</span>
      </div>
      </div>
        : null
      }
      <div className="premium__item_content">
        <div className='premium__item_amount'>⭐ {credits}</div>
        <div className='text-center'>${data.price}</div>
      </div>
    </div>
  );
}

export default CreditsPlanItem;