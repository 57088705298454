import React, {FC, useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {IConversation} from '../../modules/rest';
import ModelProfile from './components/ModelProfile';
import {HeaderSecondary} from '../../containers/Header';
import ProfileSettings from './components/ProfileSettings';

interface Props {
}

const ModelProfilePage: FC<Props> = observer(() => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [conversation, setConversation] = useState<IConversation>();

  useEffect(() => {
    if (location.state.conversation) {
      console.log(location.state?.conversation);
      setConversation(location.state?.conversation);
    } else if (params?.conversationId) fetch();
  }, []);

  const fetch = async () => {
    try {

    } catch (e) {

    }
  };

  return (
    <>
      <HeaderSecondary
        coins
        onBack={() => navigate(-1)}
        RightComponent={<ProfileSettings fromModelProfile conversation={conversation}/>}
      />
      <main className='profile__page'>
        <div className="container">
          <ModelProfile conversation={conversation}/>
        </div>
      </main>
    </>
  );
});

export default ModelProfilePage;