import React, {FC, useEffect, useRef } from 'react';
import {API} from "../../../modules/api";
import Button from "../../../components/Button";
import {useTranslation} from "react-i18next";
import AuthSecondaryHeader from "./AuthSecondaryHeader";
import tg_svg from "../../../assets/icons/tg.svg";
import {TTelegram} from "../AuthPage";
import {Spinner} from "../../../components/Loadable";
import {EFieldGroup, IUser} from "../../../modules/rest";

interface Props {
  data?: TTelegram;
  onSuccess: (data: { token: string; user: IUser }) => void;
  fetch: () => void;
}

const AuthTelegram: FC<Props> = ({data, onSuccess, fetch}) => {
  const {t} = useTranslation();
  const _ping: any = useRef(null);

  useEffect(() => {
    if (!data?.code) {
      fetch();
    } else {
      check();
    }
    return () => {
      clear()
    }
  }, []);

  const clear = () => {
    clearTimeout(_ping.current);
  }

  const check = async () => {
    try {
      const res = await API.Users.loginWithTelegram(data?.code!, [EFieldGroup.UserSubscription, EFieldGroup.UserGender]);
      if (res) {
        onSuccess(res as { token: string; user: IUser })
      } else {
        setTimeout(check, 2000);
      }
    } catch (e: any) {
      // toast.error(e)
      clear()
    } finally {

    }
  }


  return (
    <div className='auth__page_telegram'>
      <AuthSecondaryHeader title='SIGN_IN_TELEGRAM'/>
      {data
        ?
        <>
          <p className='text-dark text-center mb-3 pre-wrap'>{t('SIGN_IN_TELEGRAM_TEXT_1')}</p>
          <div className='telegram__qr'>
            <img src={data?.qr} alt="qr"/>
          </div>
          <p className='text-dark text-center mb-4'
             dangerouslySetInnerHTML={{__html: t('SIGN_IN_TELEGRAM_TEXT_2') || ''}}/>
          <Button
            href={data.url}
            icon={tg_svg}
            iconAbsolute
            className='w-100'
            title={'GO_TO_TELEGRAM'}
          />
          <Button
            btnType='secondary'
            href='/auth/login'
            className='w-100 mt-3'
            title={'CANCEL'}
          />
        </>
        :
        <Spinner loading/>
      }

    </div>

  );
}

export default AuthTelegram;