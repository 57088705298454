import React, {FC} from 'react';
import {observer} from 'mobx-react';
import {Link, Navigate, Route, Routes, useParams} from 'react-router-dom';
import TermsOfServicePage from './pages/TermsOfServicePage';
import PrivacyPolicies from './pages/PrivacyPolicies';
import CookiesPolicy from './pages/CookiesPolicy';
import UnderagePolicy from './pages/UnderagePolicy';
import ContentRemovalPolicy from './pages/ContentRemovalPolicy';
import BlockedContentPolicy from './pages/BlockedContentPolicy';
import DMCAPolicy from './pages/DMCAPolicy';
import USCExemption from './pages/USCExemption';
import ComplainPolicy from './pages/ComplainPolicy';
import {useTranslation} from 'react-i18next';
import {HeaderSecondary} from '../../containers/Header';

interface Props {
}

const legalRoutes = [
  {path: 'terms-of-service', title: 'TERMS_OF_SERVICE'},
  {path: 'underage-policy', title: 'UNDERAGE_POLICY'},
  {path: 'content-removal-policy', title: 'CONTENT_REMOVAL_POLICY'},
  {path: 'blocked-content-policy', title: 'BLOCKED_CONTENT_POLICY'},
  {path: 'privacy-policy', title: 'PRIVACY_POLICY'},

  // { path: 'cookies-policy', title: 'COOKIE_POLICY' },
  // { path: 'dmca-policy', title: 'DMCA_POLICY' },
  // { path: 'complaint-policy', title: 'COMPLAIN_POLICY' },
  // { path: 'usc-2257-exemption', title: 'USC_EXEMPTION' }
];

const LegalInformationPage: FC<Props> = observer(() => {
  const {t} = useTranslation();
  const params = useParams();

  // if (params['*']) return (
  return (
    <>

      <Routes>
        <Route path='terms-of-service' element={<TermsOfServicePage/>}/>
        <Route path='underage-policy' element={<UnderagePolicy/>}/>
        <Route path='content-removal-policy' element={<ContentRemovalPolicy/>}/>
        <Route path='blocked-content-policy' element={<BlockedContentPolicy/>}/>
        <Route path='privacy-policy' element={<PrivacyPolicies/>}/>
        <Route path='*' element={<Navigate to={'/'}/>}/>

        {/*<Route path='cookies-policy' element={<CookiesPolicy />} />*/}
        {/*<Route path='dmca-policy' element={<DMCAPolicy />} />*/}
        {/*<Route path='complaint-policy' element={<ComplainPolicy />} />*/}
        {/*<Route path='usc-2257-exemption' element={<USCExemption />} />*/}
      </Routes>
    </>
  );
  // return (
  //   <>
  //     <HeaderSecondary title='LEGAL_INFO' />
  //     <main className='container'>
  //       <h2 className='page-title'>{t('LEGAL_INFO')}</h2>
  //       <div className='legal-btn-list'>
  //         {legalRoutes.map(item => (
  //           <Link to={`/legal-information/${item.path}`} className='btn btn-secondary'>
  //             <div className='btn-content px-3 text-wrap'><span className='letter-uppercase'>{t(item.title)}</span></div>
  //           </Link>
  //         ))}
  //       </div>
  //     </main>
  //   </>
  // );
});

export default LegalInformationPage;