import React, {FC} from 'react';
import Button from "../../../components/Button";
import arrow_left from "../../../assets/icons/chevron_left.svg";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface Props {
  title: string;
}

const AuthSecondaryHeader: FC<Props> = ({title}) => {
  const {t} = useTranslation();
  return (
    <div className='auth__header_secondary-top'>
      <Button
        btnIcon
        icon={arrow_left}
        href={'/auth/register'}
        btnType='secondary'
      />
      <div className='ps-3'>
        <Link to='/auth/login'
              className='text-medium text-accent cursor-pointer'>{t('SIGN_IN')}</Link>
        <h4 className='page-title mb-0'>{t(title)}</h4>
      </div>
    </div>
  );
}

export default AuthSecondaryHeader;