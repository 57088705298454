import React, {FC, useEffect, useState} from 'react';
import {ICreateModelTask} from "../../../modules/rest";
import image from '../../../assets/icons/image_white.svg';
import chat from '../../../assets/icons/chat-add.svg';
import GenerateAI from "../components/GenerateAI";
import Button from "../../../components/Button";
import {Link, useNavigate} from "react-router-dom";
import {API} from "../../../modules/api";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

interface Props {
  finished: boolean;
  showResult: boolean;
  task: ICreateModelTask;
}

const GenerateModel: FC<Props> = ({task, finished, showResult}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();


  const handleChat = async () => {
    try {
      const res = await API.Models.startConversation(task.result!.id);
      navigate(`/conversation/${res.id}`);
    } catch (e: any) {
      toast.error(e);
    }
  }

  return (
    <>
      <GenerateAI
        about
        finished={finished }
        showResult={showResult }
        error={task?.error}
        photo={task?.result?.image}
        model={task?.result}
      />
      {showResult &&
        <>
        <div className='modal-footer gap-3'>
          <Button
            iconAbsolute
            icon={image}
            onClick={() => navigate('/request-photo', {state: {model: task.result}})}
            title={'GENERATE_IMAGE'}
            btnType='secondary'
          />
          <Button
            price={2}
            iconAbsolute
            icon={chat}
            title={'CHAT'}
            onClick={handleChat}
          />
        </div>
          <div className='mt-4 text-center text-14'>
            <span className='text-dark'>{t('MODEL_ADDED')}</span> <Link to='/companions' className='text-accent'>{t('GO_TO')}</Link>
          </div>
        </>
      }
    </>
  );
}

export default GenerateModel;