import React, {FC} from 'react';
import {  EBreastSize, ICreateModelRequest} from "../../../modules/rest";
import {observer} from "mobx-react";
import {
  getImageByModel, preloadSrcButt
} from "../assets/modules/utils";
import AppStore from "../../../store/AppStore";
import PreloadImages from "../../../components/PreloadImages";
import {useTranslation} from "react-i18next";
import StepItem from "./StepItem";

interface Props {
  onStepChange: (data?: Partial<ICreateModelRequest>) => void;
  data?: Partial<ICreateModelRequest>;
}

const Step7BreastSize: FC<Props> = observer(({onStepChange, data}) => {
  const {t} = useTranslation();

  const handleStepChange = (breastSize: ICreateModelRequest['breastSize']) => () => {
    onStepChange({breastSize});
  }

  return (
    <>
      <PreloadImages images={preloadSrcButt} />
      <div className='row g-2 g-lg-3 create__ai_list'>
        {Object.values(EBreastSize).map(breastSize => {
          const image = getImageByModel(AppStore.gender, 'breastSize', breastSize);
          if (!image) return null;
          return <div key={breastSize} className='col-4 col-md-3 col-lg-2'>
            <StepItem
              size='small'
              onClick={handleStepChange(breastSize)}
              active={breastSize === data?.breastSize}
              title={breastSize.toUpperCase()}
            >
            <img src={image} alt={breastSize}/>
          </StepItem>
          </div>
        })}
      </div>
    </>
  );
})

export default Step7BreastSize;