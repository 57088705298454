import React, {ButtonHTMLAttributes, FC} from 'react';
import {ReactSVG} from "react-svg";
import {Link} from "react-router-dom";
import BtnContent from "./components/BtnContent";
import {useTranslation} from "react-i18next";


interface Props extends ButtonHTMLAttributes<any> {
  children?: any;
  title?: string;
  href?: string;
  contentClassName?: string;
  alternativeTitle?: string;
  loading?: boolean;
  circle?: boolean;
  animate?: boolean;
  price?: number;
  iconAbsolute?: boolean;
  btnIcon?: boolean;
  btnType?: 'primary'|'secondary'|'third'|'white'|'danger' | 'outline' | 'outline-secondary' |"outline-third";
  size?: 'sm'|'lg'|'md'|'xs';
  icon?: any;
  iconText?: string;
  iconPosition?: 'left'|'right';
  target?: string;
}

const Button: FC<Props> = ({
                             children,
                             title,
                             alternativeTitle,
                             className,
                             contentClassName,
                             type = 'button',
                             btnType = 'primary',
                             icon,
                             iconText,
                             iconAbsolute,
                             animate,
                             iconPosition = 'left',
                             size,
                             circle,
                             btnIcon,
                             loading,
                             href,
                             price,
                             ...props
                           }) => {
  const {t} = useTranslation();
  const btnSizeClass = size ? ` btn-${size}` : '';
  const btnClassName = `btn btn-${btnType}${btnSizeClass}${loading ? ' loading' : ''}${circle ? ' btn-circle' : ''}${animate ? ' btn-animate' : ''}${btnIcon ? ' btn-icon' : ''} ${className || ''}`
  if (href) return (
    <Link to={href} className={btnClassName} {...props}>
      <BtnContent
        className={contentClassName}
        icon={icon} iconPosition={iconPosition}
        iconAbsolute={iconAbsolute}
                  loading={loading}
        price={price}
      >
        {children || <span className='main__title'>{t(title || '')}</span>}
        {alternativeTitle ? <span className='alternative__title'>{t(alternativeTitle)}</span> : null}
      </BtnContent>
    </Link>
  )
  return (
    <button
      {...props}
      type={type}
      className={btnClassName}
    >
      <BtnContent
        price={price}
        className={contentClassName}
        icon={icon}
        iconText={iconText}
        iconPosition={iconPosition}
        iconAbsolute={iconAbsolute}
        loading={loading}
      >
        {children || <span className='main__title'>{t(title || '')}</span>}
        {alternativeTitle ? <span className='alternative__title'>{t(alternativeTitle)}</span> : null}
      </BtnContent>
    </button>
  );
}


export default Button;