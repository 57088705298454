import {useState} from 'react';
import {useTranslation} from "react-i18next";
import {API} from "../modules/api";
import moment from "moment";
import AppStore from "../store/AppStore";
import {ELanguage} from "../modules/rest";
import {toast} from "react-toastify";



const useLanguage = (): [lang: ELanguage, onChange: (lang: ELanguage) => void] => {
  const {i18n} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [lang, setLang] = useState(i18n.language as ELanguage);

  const onSubmit = async (lang: ELanguage) => {
    if (loading) return;
    setLoading(true);
    setLang(lang);
    try {
      localStorage.setItem('ln', lang);
      moment.locale(lang);
      await i18n.changeLanguage(lang);
      API.setHeadersHandler((headers) => {
        headers['Accept-Language'] = lang;
      });
      await AppStore.updateUser({language: lang as ELanguage});
      await AppStore.getDict();
    } catch (e: any) {
      toast.error(e);
    } finally {
      setLoading(false)
    }
  }

  return [lang, onSubmit]
}



export default useLanguage;