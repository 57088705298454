import React, {FC} from 'react';
import LegalInformationPageWrapper from "../components/LegalInformationPageWrapper";

interface Props {
}

const PrivacyPolicies: FC<Props> = () => {
  return (
    <LegalInformationPageWrapper title='UNDERAGE_POLICY' date={'10/09/2024'}>
      <p>At Stellarmart Limited, we are committed to ensuring that our platform Noodz.ai (“Website”) is used in a responsible and safe way by users who meet the legal age requirements. The Noodz.ai website includes AI-generated adult content for registered users only. Therefore, users must be at least 18 years old or of legal age in their country of residence to access and engage with such content. By using the Website, users affirm that they meet the minimum age requirement and are legally permitted to access adult content. We are committed to ensuring that AI-generated content on our Website does not bear any resemblance to minors or exploit their likeness. For this purpose, we have implemented our proprietary content moderation filter to ensure full compliance with our Terms and Policies.</p>

      <h4>1. Registering</h4>
      <p>When registering for the Website, an initial landing page or age gate strategically designed to restrict entry to individuals who are not of legal age is presented to the user. Prior to gaining access to the main content, users are obligated to confirm their age. Users are prompted to affirm that they are above 18, and this information is used to verify their eligibility to access the website. A transparent disclaimer emphasizes that users must be 18 years or older to access our services. Users are also notified that providing inaccurate information about their age constitutes a violation of our Terms of Service. Stellarmart Limited cannot be held responsible for any inaccuracies or misrepresentations regarding user age. It is the user's responsibility to ensure compliance with their local laws and regulations regarding the access and consumption of adult content.</p>

      <h4>2. Content Responsibility</h4>
      <p>You, as a user of the Website, are solely responsible for the Output generated by the AI Characters through text messages, voice messages, images, and videos. The AI Characters learn and respond based on the conversations you lead and the parameters you select. You understand and agree that Stellarmart Limited does not control or endorse the content generated by the AI Characters. Therefore, you acknowledge that you are fully responsible for the Output generated by the AI and for your own actions while using the Website. You must ensure that your interactions with the AI Characters comply with applicable laws, regulations, our Terms and Policies, in particular the Blocked Content Policy, and you shall not engage in any illegal, unethical, or harmful activities through the Website. We explicitly point at the paragraphs 1d) and 1e) of the Blocked Content Policy prohibiting any content that exploits or poses a danger to minors, including but not limited to child pornography, sexual exploitation, or any form of harm or harassment towards minors, as well as any attempt to generate AI content which would bear any resemblance to minors or exploit their likeness.</p>

      <h4>3. Content Moderation</h4>
      <p>We at Stellarmart Limited value safety, respect, and integrity of all our users. While conversations between users and AI Characters are generally confidential, we have implemented a content moderation filter based on our proprietary LLM technology to ensure compliance with our Terms and Policies, in particular with our Blocked Content Policy. In the event that the moderation filter detects any content that violates our terms, we reserve the right to manually review the flagged content and take appropriate action, which may include terminating the user's account. This measure is implemented to maintain a respectful and secure environment for all users. We strive to strike a balance between privacy and community standards, and we appreciate your understanding and cooperation in adhering to our guidelines.</p>

      <h4>4. Content Removal</h4>
      <p>Any user content that we believe in our sole discretion violates these provisions will be promptly removed.</p>

      <h4>5. Contact Information</h4>
      <p>If you have noticed any violation of these Terms from your perspective, content of any nature whatsoever or you have any questions or require further clarification regarding our Underage Policy, please contact us at <a href="mailto:support@noodz.ai" target='_blank'>support@noodz.ai</a> or directly report in the Website in the “Contact” section.</p>

      <h4>6. Termination</h4>
      <p>We have the right to suspend or terminate the use of the Website by anyone engaged in suspected infringement described above.</p>
    </LegalInformationPageWrapper>
  );
}

export default PrivacyPolicies;