import asyncModal from 'react-async-modal';
import Button from '../../components/Button';
import {ReactSVG} from "react-svg";
import React, {FC, useState} from "react";
import close_svg from "../../assets/icons/close.svg";
import {toast} from "react-toastify";
import {observer} from "mobx-react";
import {InputPassword} from "../../components/FormControls";
import {API} from "../../modules/api";
import {useTranslation} from "react-i18next";


interface ConfirmModalProps {
  resolve(success?: boolean): void;
}

const PasswordModal: FC<ConfirmModalProps> = observer(({resolve}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');

  const submit = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      await API.Users.updateProfile({password});
      resolve(true);
      toast(t('UPDATED_PASSWORD'));
    } catch (e: any) {
      toast.error(e);
    } finally {
      setLoading(false);
    }

  }

  const handleClose = () => {
    resolve()
  }

  return (
    <div className='modal__container modal-gender-settings'>
      <ReactSVG src={close_svg} className='modal-close' onClick={handleClose}/>
      <h4 className='modal__title justify-content-center'>{t('EDIT_PASSWORD')}</h4>
      <form onSubmit={submit}>
        <InputPassword
          placeholder='YOUR_NEW_PASSWORD'
          label='NEW_PASSWORD'
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <div className="modal-footer sm">
          <Button
            onClick={handleClose}
            title={'CANCEL'}
            btnType='secondary'
          />
          <Button
            loading={loading}
            title={'SAVE'}
            type='submit'
          />
        </div>
      </form>
    </div>
  )
    ;
});

const openPasswordModal = (): Promise<boolean> => {
  return asyncModal(PasswordModal, {}, {
    showCloseIcon: false,
    // animationDuration: 0,
    center: true,
    classNames: {modalContainer: 'modal__bottom'}
  });
};

export {openPasswordModal};
