import asyncModal from 'react-async-modal';
import Button from '../components/Button';
import {ReactSVG} from "react-svg";
import React from "react";
import close from "../assets/icons/close.svg";
import {useTranslation} from "react-i18next";

interface InputOptions {
  label?: string;
  placeholder?: string;
  required?: boolean;
}

interface ConfirmDialogOptions {
  danger?: boolean;
  confirmText?: string;
  cancelText?: string;
  title?: string;
  icon?: any;
  input?: InputOptions;
}

interface ConfirmModalProps {
  message: string;
  options?: ConfirmDialogOptions;

  resolve(result: boolean|string): void;
}

const ConfirmModal = ({message, resolve, options}: ConfirmModalProps) => {
  const {t} = useTranslation();
  const submit = () => {
    resolve(true);
  }

  const handleClose = () => {
    resolve(false)
  }

  return (
    <div className='modal__container'>
      <ReactSVG src={close} className='modal-close' onClick={handleClose}/>
      <div className='modal__title justify-content-center mb-3'>
        {options?.icon ? <ReactSVG src={options.icon} className='react-icon me-2'/> : null}
        {options?.title ? <h4>{options?.title || t('ARE_YOU_SURE')}</h4> : null}
      </div>
      <div className='text-dark-14 text-center pre-wrap'>{message}</div>
      <div className="modal-footer">
        <Button
          onClick={handleClose}
          title={options?.cancelText || 'CANCEL'}
          btnType='secondary'
        />
        <Button
          title={options?.confirmText || 'YES'}
          onClick={submit}
        />
      </div>
    </div>
  );
};

const confirmDialog = (message: string, options?: ConfirmDialogOptions): Promise<boolean|string> => {
  return asyncModal(ConfirmModal, {message, options}, {
    showCloseIcon: false,
    center: true,
    // animationDuration: 0,
  });
};

export {confirmDialog};
