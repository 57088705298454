import React, {FC} from 'react';
import {HeaderSecondary} from '../containers/Header';
import {useTranslation} from 'react-i18next';
import home from "../assets/icons/home.svg";
import Button from "../components/Button";

interface Props {

}

const NotFoundPage: FC<Props> = (props) => {
  const {t} = useTranslation();

  return (
    <>
      <HeaderSecondary logo title='PAGE_404'/>
      <main className='align-items-center'>
        <h2 className='page-title'>{t('PAGE_404')}</h2>
          <Button
            className='mt-5'
            style={{minWidth: 280}}
            iconAbsolute
            icon={home}
            btnType={'secondary'}
            href='/'
            title='HOME'
          />
      </main>
    </>
  );
}

export default NotFoundPage;