import React, {FC} from 'react';
import {EHairStyle, ICreateModelRequest} from "../../../modules/rest";
import {observer} from "mobx-react";
import {
  getImageByModel, preloadSrcBodyType
} from "../assets/modules/utils";
import AppStore from "../../../store/AppStore";
import PreloadImages from "../../../components/PreloadImages";
import {useTranslation} from "react-i18next";
import StepItem from "./StepItem";

interface Props {
  onStepChange: (data?: Partial<ICreateModelRequest>) => void;
  data?: Partial<ICreateModelRequest>;
}

const Step4HairStyle: FC<Props> = observer(({onStepChange, data}) => {
  const {t} = useTranslation();

  const handleStepChange = (hairStyle: ICreateModelRequest['hairStyle']) => () => {
    onStepChange({hairStyle});
  }

  return (
    <>
      <PreloadImages images={preloadSrcBodyType}/>
      <div className='row g-2 g-lg-3 create__ai_list'>
        {Object.values(EHairStyle).map(hairStyle => {
          const image = getImageByModel(AppStore.gender, 'hairStyle', hairStyle);
          if (!image) return null;
          return <div key={hairStyle} className='col-4 col-md-3 col-lg-2'>
            <StepItem
              size='small'
              onClick={handleStepChange(hairStyle)}
              active={hairStyle === data?.hairStyle}
              title={hairStyle.toUpperCase()}
            >
              <img src={image} alt={hairStyle}/>
            </StepItem>
          </div>
        })}
      </div>
    </>
  );
})

export default Step4HairStyle;