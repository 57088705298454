import React, { FC } from 'react';
import { Select } from '../../../components/FormControls';
import { getPosesArr } from '../modules/utils';
import AppStore from '../../../store/AppStore';
import { EPose, ESubscriptionStatus } from '../../../modules/rest';
import { observer } from 'mobx-react';
import pose_svg from '../../../assets/icons/pose.svg'

interface Props {
  pose?: EPose;
  onChange: (pose: EPose) => void;
}

const GenerateImagePoses: FC<Props> = observer(({ pose, onChange }) => {
  const posesArr = getPosesArr(AppStore.user?.subscription?.status === ESubscriptionStatus.Active);
  return (
    <Select
      placeholder='CHOOSE_POSE'
      className='mt-4 w-50 generate__ai_poses'
      icon={pose_svg}
      label='POSE'
      value={pose}
      onChange={(e) => onChange(e.target.value)}
    >
      {posesArr?.regular?.map((item, i) => (
        <option key={i} value={item}>{item}</option>
      ))
      }
      {posesArr?.premium?.map((item, i) => (
        <option key={i} value={item}>{item}</option>
      ))}
    </Select>
  );
});

export default GenerateImagePoses;