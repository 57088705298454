import React, {FC, useState} from 'react';
import arrow_left from '../../../assets/icons/chevron_left.svg';
import {Input} from "../../../components/FormControls";
import email_svg from "../../../assets/icons/email.svg";
import {API} from "../../../modules/api";
import {toast} from "react-toastify";
import Button from "../../../components/Button";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import AuthSecondaryHeader from "./AuthSecondaryHeader";

interface Props {
}

const AuthRecovery: FC<Props> = () => {
  const {t} = useTranslation();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState('');


  const onSubmit = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      await API.Users.resetPassword({email});
      setSuccess(true);
    } catch (e: any) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  }
  return (
    <>
      <AuthSecondaryHeader title='RESET_PASSWORD' />
      <p className='text-dark mb-4' style={{maxWidth: 360}}>{t('RESET_PASSWORD_TEXT')}</p>

      <form onSubmit={onSubmit}>
        <Input
          required
          icon={email_svg}
          label='EMAIL'
          placeholder='E-MAIL'
          value={email}
          type='email'
          onChange={(e) => setEmail(e.target.value)}
        />
        {success &&
          <div className='auth-recovery-success'>
            {t('RESET_PASSWORD_SUCCESS_TEXT')}
          </div>
        }
        <Button
          className='w-100 modal-footer'
          loading={loading}
          title={'RESET_PASSWORD'}
          type='submit'
        />
        <Button
          btnType='secondary'
          href='/auth/register'
          className='w-100 mt-3'
          title={'CANCEL'}
        />
      </form>
    </>

  );
}

export default AuthRecovery;