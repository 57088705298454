import asyncModal from 'react-async-modal';
import React, {FC} from "react";
import {IAsset, IModel} from "../modules/rest";
import {thumb} from "../modules/utils";
import {ReactSVG} from "react-svg";
import collapse from "../assets/icons/collapse.svg";
import trash from "../assets/icons/trash.svg";
import generate_svg from "../assets/icons/generate.svg";
import chat_svg from "../assets/icons/chat-add.svg";
import user_svg from "../assets/icons/user-btn_active.svg";
import Slider from "react-slick";
import Button from "../components/Button";
import BlurComponent from "../components/BlurComponent";
import {useTranslation} from "react-i18next";

// const Arrow = (props: any) => {
//   console.log(props);  // currentIndex: 1
//   const handleClick = (e: any) => {
//     e.stopPropagation();
//     props.onClick();
//   }
//   return <button type='button' className={`slick-arrow slick-${props.dir}`} onClick={handleClick}/>
// }

type  TResult = 'remove'|'generate'|'chat'|'allPhotos'|'premium'
type  TOptions = 'remove'|'generate'|'chat'|'allPhotos'

interface Props {
  options?: TOptions[];
  resolve?: (res?: TResult) => void;
  initialIdx?: number
  image?: IAsset|null;
  model?: IModel|null;
}

const GalleryModal: FC<Props> = ({
                                   resolve,
                                   image,
                                   initialIdx,
                                   model,
                                   options,
                                 }) => {
  const {t} = useTranslation();
  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   nextArrow: <Arrow dir='next'/>,
  //   prevArrow: <Arrow dir='prev'/>,
  // };

  const handleClose = (type?: TResult) => (e: any) => {
    e.stopPropagation();
    resolve!(type);
  }


  return (
    <div className='modal__container modal__gallery' onClick={handleClose()}>
      <div className='modal__gallery_header'>
        <div className="container d-flex align-items-center">
          <Button
            className='me-3'
            btnIcon
            icon={collapse}
            size='sm'
            onClick={handleClose()}
            btnType='secondary'
          />
          <div className='flex-1 text-medium'>
            {model?.name} <span className='text-muted'>{model?.age}</span>
          </div>
          {options?.includes('chat') &&
            <Button
              className='ms-2'
              btnIcon
              icon={chat_svg}
              size='sm'
              onClick={handleClose('chat')}
              btnType='secondary'
            />
          }
          {options?.includes('generate') &&
            <Button
              className='ms-2'
              btnIcon
              icon={generate_svg}
              size='sm'
              onClick={handleClose('generate')}
              btnType='secondary'
            />
          }
        </div>
      </div>
      <div className="modal__gallery_content">
        <div className="modal__gallery_content-wrap">
          <div className='gallery-modal-image btn-expand-container btn-trash-container'>
            <ReactSVG src={collapse} className='react-icon btn-expand'/>
            {options?.includes('remove') &&
              <ReactSVG src={trash} className='react-icon btn-trash' onClick={handleClose('remove')}/>}
            <img src={thumb(image?.id, 800)} alt="img"/>
          </div>
        </div>
      </div>
      {(options?.includes('allPhotos') || options?.includes('remove')) &&
        <div className="modal__gallery_footer">
          {options?.includes('allPhotos')
            ?
            <div className='d-flex align-items-center text-accent' onClick={handleClose('allPhotos')}>
              <ReactSVG src={user_svg} className='react-icon me-1'/>
              <div>{t('SEE_ALL_PHOTOS')}</div>
            </div>
            :
            <div/>
          }
          {options?.includes('remove')
            ?
            <ReactSVG src={trash} className='react-icon btn-trash' onClick={handleClose('remove')}/>
            :
            null
          }
        </div>
      }
    </div>
  );
};

const openGalleryModal = (props: Props): Promise<TResult> => {
  return asyncModal(GalleryModal, props, {
    showCloseIcon: false,
    center: true,
    // animationDuration: 0,
  });
};

export {openGalleryModal};
