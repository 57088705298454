import React, {FC} from 'react';
import {IModel} from "../../modules/rest";
import Avatar from "../Avatar";
import {thumbAvatar} from "../../modules/utils";

interface Props {
  model?: IModel;
  active?: boolean;
  className?: string;
  onClick?: () => void;
  children?: any;
}

const ActiveListModel: FC<Props> = ({model, active, onClick, children, className}) => {
  return (
    <div className={`active__list_model${active ? ' active' : ''} ${className || ''}`} onClick={onClick}>
      <Avatar image={thumbAvatar(model?.image?.id, 176)}/>
      <div className='ms-2 flex-1'>
        <div className='d-grid overflow-hidden'>
          <div className='text-medium text-truncate'>{model?.name} <span className='text-dark'>{model?.age}</span></div>
        </div>
        {children || null}
      </div>
    </div>
  );
}

export default ActiveListModel;