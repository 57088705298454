import { IAddStarsRequest, ICreateSubscriptionRequest} from "../../../../../modules/rest";

export const benefitsSubscription = [
  'BENEFITS_TEXT_1',
  'BENEFITS_TEXT_2',
  'BENEFITS_TEXT_3',
  'BENEFITS_TEXT_4',
  'BENEFITS_TEXT_5',
  'BENEFITS_TEXT_6',
]
export const benefitsCredits = [
  'BENEFITS_TEXT_7',
  'BENEFITS_TEXT_8'
]

export interface ISubscriptionDataItem {
  price: string,
  discount: string,
  was: string,
  recommended: boolean,
  planText: string,
  periodText: string
}


export type ISubscriptionData = { [PERIOD in ICreateSubscriptionRequest['period']]: ISubscriptionDataItem }

export const subscriptionsData: ISubscriptionData = {
  '12': {
    price: '$5.99',
    discount: '75%',
    was: '$71.88',
    recommended: true,
    planText: 'PLAN_TEXT_12',
    periodText: 'MONTHS'
  },
  '3': {
    price: '$9.99',
    discount: '60%',
    was: '$29.97',
    recommended: false,
    planText: 'PLAN_TEXT_3',
    periodText: 'MONTHS_a'
  },
  '1': {
    price: '$12.99',
    discount: '50%',
    was: '$12.99',
    recommended: false,
    planText: '',
    periodText: 'MONTH'
  }
}

export interface ICreditsDataItem {
  bonus?: string,
  price: number,
}

export type ICreditsData = { [CREDITS in IAddStarsRequest['stars']]: ICreditsDataItem }

export const creditsData: ICreditsData = {
  "100": {price: 9.99, bonus: ''},
  "350": {price: 34.99, bonus: ''},
  "550": {price: 49.99, bonus: '10'},
  "1150": {price: 99.99, bonus: '15'},
  "2400": {price: 199.99, bonus: '20'},
  "3750": {price: 299.99, bonus: '25'},
}
