import {useLayoutEffect, useRef, useState} from 'react';
import {EFieldGroup, ETaskStatus, IGetTasksRequest, ITask, TIdentifier} from "../modules/rest";
import {API} from "../modules/api";
import {toast} from "react-toastify";
import AppStore from "../store/AppStore";
import {useLocation} from "react-router-dom";

interface Props {
  fields?: EFieldGroup[];
  fetch?: () => void;
  type: IGetTasksRequest["type"];
}

export const useTask = <T>({
                             fetch,
                             type,
                             fields = [],
                           }: Props): [task: T|undefined, generating: boolean|'finished', taskReady: boolean, getTasks: (init?: boolean) => void] => {
  const ping: any = useRef(null);
  const location = useLocation();

  const [generatingAILoading, setGeneratingAILoading] = useState<boolean|'finished'>(true);
  const [taskReady, setTaskReady] = useState(false);
  const [task, setTask] = useState<T>();

  useLayoutEffect(() => {
    if (AppStore.ready) getTasks(true);
    return () => {
      setTaskReady(false);
      clearTimeout(ping.current);
    }
  }, [AppStore.ready]);

  const getTasks = async (init?: boolean) => {
    try {
      const defaultTask = location.state?.task;
      const tasks = await API.Tasks.getList({type, showTerminal: false});
      if (defaultTask && init) {
        setTask(defaultTask)
        await pingTask(defaultTask.id);
      } else if (tasks.length) {
        await pingTask(tasks[0].id);
      }
    } catch (e: any) {
      toast.error(e)
    } finally {
      setTaskReady(true);
    }
  }

  const pingTask = async (id: TIdentifier, init?: boolean) => {
    setGeneratingAILoading(true);
    try {
      const task: ITask = await API.Tasks.getTask(id, fields);
      setTask(task as T);
      if (task.status === ETaskStatus.Completed) {
        if (init) return setGeneratingAILoading(false);
        clearTimeout(ping.current);
        setGeneratingAILoading('finished');
        setTimeout(async () => {
          if (fetch) await fetch();
          setGeneratingAILoading(false);
          getTasks();
        }, 3000);
        // setTask(undefined);
        AppStore.getUser();
      } else if (task.status === ETaskStatus.Failed || task.status === ETaskStatus.Cancelled) {
        if (task.error) toast.error(task.error);
        setGeneratingAILoading(false);
        clearTimeout(ping.current);
        getTasks();
      } else {
        ping.current = setTimeout(() => pingTask(task.id!), 1000);
      }
    } catch (e: any) {
      // toast.error(e);
      setGeneratingAILoading(false);
      clearTimeout(ping.current);
    } finally {
      setTaskReady(true);
    }
  }

  return [task, generatingAILoading, taskReady, getTasks]


}
