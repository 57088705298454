import React, {FC} from 'react';
import {EGender, ICreateModelRequest} from "../../../modules/rest";
import {observer} from "mobx-react";
import {
  getImageByModel,
  getModalHairColor,
  getModelPersonalityData,
  getRelationshipData
} from "../assets/modules/utils";
import AppStore from "../../../store/AppStore";
import {useTranslation} from "react-i18next";
import StepItem from "./StepItem";
import check from "../../../assets/icons/check.svg";
import Button from "../../../components/Button";

interface Props {
  onStepChange: (step: keyof ICreateModelRequest) => void;
  onSubmit?: () => void;
  data: Partial<ICreateModelRequest>;
  loading?: boolean;
}

const Step12Summary: FC<Props> = observer(({onStepChange, onSubmit, data, loading}) => {
  const {t} = useTranslation();

  return (
    <>
      <div className='create__ai_summary'>
        <div className='row g-3'>
          <div className="col-12 col-sm-6 col-md-4">
            <StepItem edit size={'small'} onClick={() => onStepChange('style')}>
              <img src={getImageByModel(AppStore.gender, 'style', data.style!)} alt="style"/>
              <div className='ps-2 flex-1 d-grid'>
                <div className='text-dark-14'>{t('STYLE')}</div>
                <div className='letter-uppercase text-truncate'>{t(data.style?.toUpperCase()!)}</div>
              </div>
            </StepItem>
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <StepItem edit size={'small'} onClick={() => onStepChange('ethnicity')}>
              <img src={getImageByModel(AppStore.gender, 'ethnicity', data.ethnicity!)} alt="ethnicity"/>
              <div className='ps-2 flex-1 d-grid'>
                <div className='text-dark-14'>{t('ETHNICITY')}</div>
                <div className='letter-uppercase text-truncate'>{t(data.ethnicity?.toUpperCase()!)}</div>
              </div>
            </StepItem>
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <StepItem edit size={'small'} onClick={() => onStepChange('age')}>
              <div className='create__ai_list-color'>
                {data.age}
              </div>
              <div className='ps-2 flex-1 d-grid'>
                <div className='text-dark-14'>{t('AGE')}</div>
                <div className='letter-uppercase text-truncate'>{data.age}</div>
              </div>
            </StepItem>
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <StepItem edit size={'small'} onClick={() => onStepChange('hairStyle')}>
              <img src={getImageByModel(AppStore.gender, 'hairStyle', data.hairStyle!)} alt="hairStyle"/>
              <div className='ps-2 flex-1 d-grid'>
                <div className='text-dark-14'>{t('HAIR_STYLE')}</div>
                <div className='letter-uppercase text-truncate'>{t(data.hairStyle?.toUpperCase()!)}</div>
              </div>
            </StepItem>
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <StepItem edit size={'small'} onClick={() => onStepChange('hairColor')}>
              <div className='create__ai_list-color' style={{background: getModalHairColor(data.hairColor!)}}/>
              <div className='ps-2 flex-1 d-grid'>
                <div className='text-dark-14'>{t('HAIR_COLOR')}</div>
                <div className='letter-uppercase text-truncate'>{t(data.hairColor?.toUpperCase()!)}</div>
              </div>
            </StepItem>
          </div>

          <div className="col-12 col-sm-6 col-md-4">
            <StepItem edit size={'small'} onClick={() => onStepChange('bodyType')}>
              <img src={getImageByModel(AppStore.gender, 'bodyType', data.bodyType!)} alt="hairStyle"/>
              <div className='ps-2 flex-1 d-grid'>
                <div className='text-dark-14'>{t('BODY_TYPE')}</div>
                <div className='letter-uppercase text-truncate'>{t(data.bodyType?.toUpperCase()!)}</div>
              </div>
            </StepItem>
          </div>
          {AppStore.gender === EGender.Female &&
            <>
              <div className="col-12 col-sm-6 col-md-4">
                <StepItem edit size={'small'} onClick={() => onStepChange('breastSize')}>
                  <img src={getImageByModel(AppStore.gender, 'breastSize', data.breastSize!)} alt="hairStyle"/>
                  <div className='ps-2 flex-1 d-grid'>
                    <div className='text-dark-14'>{t('BREAST_SIZE')}</div>
                    <div className='letter-uppercase text-truncate'>{t(data.breastSize?.toUpperCase()!)}</div>
                  </div>
                </StepItem>
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <StepItem edit size={'small'} onClick={() => onStepChange('buttSize')}>
                  <img src={getImageByModel(AppStore.gender, 'buttSize', data.buttSize!)} alt="hairStyle"/>
                  <div className='ps-2 flex-1 d-grid'>
                    <div className='text-dark-14'>{t('BUTT_SIZE')}</div>
                    <div className='letter-uppercase text-truncate'>{t(data.buttSize?.toUpperCase()!)}</div>
                  </div>
                </StepItem>
              </div>
            </>
          }
          <div className="col-12 col-sm-6 col-md-4">
            <StepItem edit size={'small'} onClick={() => onStepChange('personality')}>
              <div className='create__ai_list-color'>
                {getModelPersonalityData(data?.personality!).icon}
              </div>
              <div className='ps-2 flex-1 d-grid'>
                <div className='text-dark-14'>{t('PERSONALITY')}</div>
                <div className='letter-uppercase text-truncate'>{t(getModelPersonalityData(data?.personality!).title?.toUpperCase())}</div>
              </div>
            </StepItem>
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <StepItem edit size={'small'} onClick={() => onStepChange('interests')} className='ps-3'>
              <div className='flex-1'>
                <div className='text-dark-14'>{t('INTERESTS')}</div>
                <div className='letter-uppercase text-truncate'>{data.interests?.[0]}</div>
              </div>
            </StepItem>
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <StepItem edit size={'small'} onClick={() => onStepChange('relationship')}>
              <div className='create__ai_list-color'>
                {getRelationshipData(data?.relationship!).icon}
              </div>
              <div className='ps-2 flex-1 d-grid'>
                <div className='text-dark-14'>{t('RELATIONSHIP')}</div>
                <div className='letter-uppercase text-truncate'>{t(getRelationshipData(data?.relationship!)?.[AppStore.gender])}</div>
              </div>
            </StepItem>
          </div>
        </div>
      </div>
      <Button
        loading={loading}
        onClick={onSubmit}
        icon={check}
        iconAbsolute
        title={ 'BRING_AI_TO_LIFE'}
        className='create__ai_summary-btn'
      />
    </>
  );
})

export default Step12Summary;