import asyncModal from 'react-async-modal';
import { ReactSVG } from 'react-svg';
import React, { FC, useEffect, useState } from 'react';
import close from '../../assets/icons/close.svg';
import { EFieldGroup, EGender, EModelStyle, ESortOrder, IGetModelsListRequest, IModel } from '../../modules/rest';
import { API } from '../../modules/api';
import { toast } from 'react-toastify';
import Loadable from '../../components/Loadable';
import EmptyList from '../../components/EmptyList';
import { useTranslation } from 'react-i18next';
import StepItem from '../../pages/CreateAIPage/components/StepItem';
import { shuffleArr, thumbAvatar } from '../../modules/utils';
import GenderSelect from '../../components/GenderSelect';

interface Props {
  model?: IModel;

  resolve(result?: IModel): void;
}

const ChooseModelModal: FC<Props> = ({ resolve, model: defaultModel }) => {
  const { t } = useTranslation();

  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [preset, setPreset] = useState<IGetModelsListRequest['preset']>('public');
  const [modelStyle, setModelStyle] = useState<EModelStyle>();
  const [gender, setGender] = useState<EGender>(EGender.Female);
  const [list, setList] = useState<IModel[]>();

  useEffect(() => {
    fetch();
  }, [preset, modelStyle, gender]);

  const handleChange = (type: 'preset' | 'style', value: EModelStyle | IGetModelsListRequest['preset']) => () => {
    if (type === 'preset') {
      setPreset(value as IGetModelsListRequest['preset']);
      setModelStyle(undefined);
    } else {
      setPreset('all');
      setModelStyle(value as EModelStyle);
    }
  };

  const fetch = async () => {
    setLoading(true);
    try {
      const res = await API.Models.getList(
        {
          style: modelStyle,
          gender,
          limit: 1000,
          preset,
          order: ESortOrder.DESC
        }, [EFieldGroup.ModelFull]);
      setList(shuffleArr(res.data));
    } catch (e: any) {
      toast.error(e);
    } finally {
      setLoading(false);
      setReady(true);
    }
  };
  const handleClose = () => {
    resolve();
  };

  return (
    <div className='modal__container big generate-image-modal_models'>
      <ReactSVG src={close} className='modal-close' onClick={handleClose} />
      <h4 className='px-2 text-center'>{t('CHOOSE_CHARACTER')}</h4>
      <GenderSelect defaultGender={gender} defaultChange={setGender} className='align-self-center mt-2 mb-3' />
      <div className='tabs'>
        <div className={`tab${preset === 'public' ? ' active' : ''}`} onClick={handleChange('preset', 'public')}>
          {t('ALL_MODELS')}
        </div>
        <div className={`tab${preset === 'my' ? ' active' : ''}`} onClick={handleChange('preset', 'my')}>
          {t('MY_AIS')}
        </div>
        <div className={`tab${modelStyle === EModelStyle.Hyperanime ? ' active' : ''}`}
             onClick={handleChange('style', EModelStyle.Hyperanime)}>
          {t('ANIME')}
        </div>
        <div className={`tab${modelStyle === EModelStyle.Real ? ' active' : ''}`}
             onClick={handleChange('style', EModelStyle.Real)}>
          {t('REAL')}
        </div>
      </div>
      {list?.length
        ? <Loadable loading={loading}>
          <div className='row g-2 g-lg-3 generate__modal_character-list'>
            {list.map(model => (
              <div className='col-6 col-sm-4 col-md-3' key={model.id}>
                <StepItem
                  size='small'
                  onClick={() => resolve(model)}
                  active={model.id === defaultModel?.id}
                  titleComponent={<span>{model.firstName} <span className='text-dark'>{model.age}</span></span>}
                >
                  <img src={thumbAvatar(model.image?.id, 176)} alt={model.firstName} />
                </StepItem>
              </div>
            ))
            }
          </div>
        </Loadable>
        :
        <EmptyList visible={ready} />
      }
    </div>
  );
};

const openChooseModelModal = (model?: IModel): Promise<IModel> => {
  return asyncModal(ChooseModelModal, { model }, {
    showCloseIcon: false,
    center: true,
    blockScroll: false,
    classNames: { modalContainer: 'modal__bottom' }
  });
};

export { openChooseModelModal };
