import React, {FC} from 'react';
import {observer} from "mobx-react";
import Avatar from "../../../components/Avatar";
import {thumbAvatar} from "../../../modules/utils";
import {IModel} from "../../../modules/rest";
import ChatStore from "../../../store/ChatStore";
import HomeStore from "../../../store/HomeStore";
import {useTranslation} from "react-i18next";

interface Props {
}

const AuthModelMessage: FC<Props> = observer(() => {
  const {t} = useTranslation();

  const model: IModel = ChatStore.chats?.[0]?.model || HomeStore.randomModels?.[0];

  return (
    <div className='modal__legacy_head mb-4'>
      <Avatar image={thumbAvatar(model?.image?.id, 176)} size='xs'/>
      <div className='ps-2'>
        <div className='text-medium' style={{minHeight: 24}}>{model?.name}</div>
        <div className="modal__legacy_message text-14">{t('AUTH_MODEL_MESSAGE')}</div>
      </div>
    </div>
  );
})

export default AuthModelMessage;