import React, {FC, useEffect, useLayoutEffect} from 'react';
import AppStore from "../../store/AppStore";
import {observer} from "mobx-react";
import {  Route, Routes, useNavigate} from "react-router-dom";
import PremiumPage from "./pages/PremiumPage";

interface Props {
}

const PaymentPage: FC<Props> = observer(() => {
  const navigate = useNavigate();

  useLayoutEffect(() => {
    // localStorage.removeItem('token')
    if ((AppStore.ready && AppStore.user?.isAnonymous)) {
      navigate('/auth/login', {replace: true, state: {prevPathname: '/premium/subscription'}});
    }
  }, [AppStore.ready]);

  if(!AppStore.ready) return null;

  return (
    <Routes>
      <Route path='/' element={<PremiumPage/>}/>
      <Route path=':paymentType' element={<PremiumPage/>}/>
      {/*<Route path=':paymentType/:paymentPlan/:paymentMethodId' element={<BillingAddressPage/>}/>*/}
      {/*<Route path='' element={<Navigate to="/premium/subscription" replace />}/>*/}
    </Routes>
  );
})

export default PaymentPage;