import React, {FC, useEffect} from 'react';
import {observer} from "mobx-react";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Button from "../../components/Button";
import {ReactSVG} from "react-svg";
import {HeaderSecondary} from "../../containers/Header";
import check from '../../assets/icons/check.svg';
import close from '../../assets/icons/close.svg';
import home from '../../assets/icons/home.svg';

interface Props {
}

const PaymentStatusPage: FC<Props> = observer(() => {
  const {t} = useTranslation();
  const navigate = useNavigate()
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const status = query.get('status');
  const errorText = query.get('error');

  useEffect(() => {
    if (status === 'success') {
      const type = query.get('status');
      if (type === 'stars') {
      } else if (type === 'sub') {
      }
    }
    if (!status) navigate('/', {replace: true});
  }, [])

  return (
    <>
      <HeaderSecondary title={'PAYMENT'} onBack={() => navigate('/', {replace: true})}/>
      <main className='container overflow-visible'>
        <h2 className='page-title'>{t('PAYMENT')}</h2>
        {status === 'success' || status === 'failed'
          ?
          <div className={`payment__item ${status}`}>
            <div className='payment__item_title'>
              <ReactSVG src={status === 'success' ? check : close} className='react-icon payment__item_check'/>
              <span>{t(`PAYMENT_TITLE_${status.toUpperCase()}`)}</span>
              {errorText ?
                <p className='text-dark mt-2 pt-1'>{decodeURIComponent(errorText).replaceAll('+', ' ')}</p> : null}
            </div>
            <Button
              className='w-100'
              iconAbsolute
              icon={home}
              btnType={'secondary'}
              href='/'
              title='HOME'
            />
          </div>
          :
          null
        }
      </main>
    </>
  );
})

export default PaymentStatusPage;