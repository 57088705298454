import React,  {FC, InputHTMLAttributes} from 'react';
import {ReactSVG} from "react-svg";
import {useTranslation} from "react-i18next";

interface Props extends InputHTMLAttributes<any> {
  label?: string;
  className?: string;
  classNameWrap?: string;
  classNameInput?: string;
  icon?: any;
}

const Input: FC<Props> = ({
                            label,
                            className,
                            classNameWrap,
                            classNameInput,
                            icon,
                            ...props
                          }) => {
  const {t} = useTranslation();
  return (
    <div className={`form-group ${className || ''}`}>
      {label ?
        <label className={`muted mb-2`}>
          <span>{t(label)}</span>
          {props.required ? <span className='text-danger text-12'>*</span> : null}
        </label>
        : null
      }
      <div className={`form-control-wrap ${className || ''}`}>
        <textarea
          className={`form-control${icon ? ' form-control-icon' : ''} ${classNameInput || ''}`}
          {...props}
          placeholder={t(props.placeholder || '') || ''}
        />
        <div className="form-control-border" />
        <div className="form-control-gradient" />
        {icon ? <div className='input-icon'><ReactSVG src={icon}/></div> : null}
      </div>
    </div>
  );
}

export default Input;