import asyncModal from 'react-async-modal';
import {ReactSVG} from "react-svg";
import React, {FC} from "react";
import close_svg from "../assets/icons/close.svg";
import {observer} from "mobx-react";
import {IConversation} from "../modules/rest";
import {ModelProfile} from "../pages/ModelProfilePage";


interface Props {
  conversation: IConversation;

  resolve(): void;
}

const ModelProfileModal: FC<Props> = observer(({resolve, conversation}) => {

  const handleClose = () => {
    resolve();
  }

  return (
    <div className='modal__container big'>
      <ReactSVG src={close_svg} className='modal-close' onClick={handleClose}/>
      <ModelProfile conversation={conversation} canOpenPhoto={false}/>
    </div>
  );
});

const openModelProfileModal = (conversation: IConversation) => {
  return asyncModal(ModelProfileModal, {conversation}, {
    showCloseIcon: false,
    center: true,
    // animationDuration: 0,
  });
};

export {openModelProfileModal};
