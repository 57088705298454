import React, {FC} from 'react';
import {ReactSVG} from "react-svg";
import star_svg from './assets/icons/star.svg';

interface Props {
  className?: string;
  light?: boolean;
  sm?: boolean;
  star?: boolean;
  progress: number;
}

const Progress: FC<Props> = ({className, progress, light, sm, star}) => {
  return (
      <div className={`progress__container${light ? ' progress__container_light' : '' }${sm ? ' progress__container_sm' : '' } ${className || ''}`}>
        <div className="progress__content">
          <div className="progress" style={{transform: `translateX(${progress}%)`}} />
        </div>
        <div className="progress__shadow" style={{width: `${progress}%`}}>
          {star && <ReactSVG src={star_svg} className='react-icon' />}
        </div>

      </div>
  );
}

export default Progress;