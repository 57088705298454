import React, {FC, useLayoutEffect, useState} from 'react';
import gallery_models from '../../../assets/icons/gallery_models.svg';
import gallery_models_2 from '../../../assets/icons/gallery_models_2.svg';
import chevron_down from '../../../assets/icons/chevron_down.svg';
import {observer} from 'mobx-react';
import {ReactSVG} from 'react-svg';
import {IModel} from '../../../modules/rest';
import {API} from '../../../modules/api';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import ActiveListModel from '../../../components/ActiveListModel';
import AppStore from "../../../store/AppStore";
import Avatar from "../../../components/Avatar";
import {thumbAvatar} from "../../../modules/utils";
import OutsideClick from "../../../components/OutsideClick";
import Skeleton from "../../../components/Skeleton";

interface Props {
  ready?: boolean;
  activeModel?: null|IModel;
  onChange: (model: IModel|null) => void;
}

const GalleryNav: FC<Props> = observer(({activeModel, onChange, ready}) => {
  const {t} = useTranslation();

  const [visible, setVisible] = useState(false);
  const [models, setModels] = useState<IModel[]>();


  useLayoutEffect(() => {
    if (AppStore.ready && AppStore.user?.id) {
      fetchModels();
    }

  }, [AppStore.ready]);

  const fetchModels = async () => {
    try {
      const res = await API.Gallery.getModels();
      setModels(res);
    } catch (e: any) {
      toast.error(e);
    }
  };

  const toggleVisible = (e: any) => {
    e.stopPropagation();
    setVisible(prevState => !prevState);

  }

  const onSelect = (model: null|IModel) => () => {
    setVisible(false)
    onChange(model);
  };

  if(!models?.length) return null;

  return (
    <OutsideClick outsideClickEvent={() => setVisible(false)}
                  className={`gallery__nav${visible ? ' gallery__nav_visible' : ''}`}>
      <div className="gallery__nav_value" onClick={toggleVisible}>
        {activeModel
          ?
          <>
            <Avatar size='xs' image={thumbAvatar(activeModel.image?.id, 176)}/>
            <div className='d-grid overflow-hidden ps-2 flex-1'>
              <div className='text-medium text-truncate'>{activeModel?.name} <span
                className='text-dark'>{activeModel?.age}</span></div>
            </div>
          </>
          :
          <>
            <ReactSVG src={gallery_models_2} className='react-icon'/>
            <div className='text-14 flex-1'>{t('ALL_MODELS')}</div>
          </>
        }
        <ReactSVG src={chevron_down} className='react-icon gallery__nav_arrow'/>
      </div>
      <div className="gallery__nav_list">
        {ready
          ?
          <>
            <div className={`active__list_model${!activeModel ? ' active' : ''} `} onClick={onSelect(null)}>
              <div className='active__list_model-icon'/>
              <div className='ms-2 flex-1'>
                {t('ALL_MODELS')}
              </div>
            </div>
            {models?.map(model => (
              <ActiveListModel
                model={model}
                key={model.id}
                active={activeModel?.id === model?.id}
                className={`gallery-models-item${activeModel?.id === model?.id ? ' active' : ''}`}
                onClick={onSelect(model)}
              />
            ))}
          </>
          :
          Array(4).fill({}).map((_, i) => (
            <Skeleton key={i} type='nav'/>
          ))
        }

      </div>
    </OutsideClick>
  );
});

export default GalleryNav;