import React, { FC, useLayoutEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { HeaderSecondary } from '../../containers/Header';
import AppStore from '../../store/AppStore';
import Button from '../../components/Button';
import { EEmotion, EGender, EPose, IModel } from '../../modules/rest';
import { API } from '../../modules/api';
import GenerateModel from './components/GenerateModel';
import GeneratePromptInput from './components/GeneratePromptInput';
import image_svg from '../../assets/icons/image_white.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { openModalNoMoney } from '../../modals';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import GenerateImagePoses from './components/GenerateImagePoses';
import GenerateImageEmotion from './components/GenerateImageEmotion';

interface Props {
}

const promptGender: Record<EGender, string> = {
  [EGender.Male]: 'man',
  [EGender.Female]: 'woman'
};

const GenerateImagePage: FC<Props> = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState<IModel>();
  const [prompt, setPrompt] = useState('');
  const [pose, setPose] = useState<EPose>();
  const [emotion, setEmotion] = useState<EEmotion>(EEmotion.Default);

  const gender = model?.gender || EGender.Female;

  useLayoutEffect(() => {
    if ((AppStore.ready && !AppStore.user?.id)) {
      navigate('/auth/login', { replace: true, state: { prevPathname: '/request-photo' } });
    }

  }, [AppStore.ready]);

  useLayoutEffect(() => {
    if (location.state) {
      let { model, pose, emotion, prompt } = location.state || {};
      setModel(model);
      setPose(pose);
      setEmotion(emotion);
      setPrompt(prompt);
    }
  }, []);

  const onSubmit = async () => {
    if (loading) return;
    try {
      setLoading(true);
      const req = { prompt: prompt || promptGender[gender], pose, emotion };
      const task = await API.Models.createExtraPhoto(model?.id!, req);
      navigate(`/generate/photo`, { state: { ...req, model, task } });
    } catch (e: any) {
      const code = API.getStatusCode();
      if (code === 402) {
        if (AppStore.user?.isAnonymous) {
          navigate('/auth', { state: { prevPathname: '/request-photo' } });
        } else {
          openModalNoMoney('create-ai').then((path) => {
            if (path) navigate(path);
          });
        }
      } else {
        toast.error(e);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <HeaderSecondary title='GENERATE_IMAGE'   />
      <main className='container overflow-visible'>
        <div className='generate__image_page blur__bg_container'>
          <h2 className='page-title text-accent'>{t('GENERATE_IMAGE')}</h2>
          {AppStore.ready &&
          <>
            {/*<h6 className='text-center mb-3'>{t('CHOOSE_CHARACTER')}</h6>*/}
            <GenerateModel model={model} onChange={setModel} />
            <GenerateImagePoses pose={pose} onChange={(pose) => {
              setPose(pose);
              setPrompt('');
            }} />
            <GeneratePromptInput prompt={prompt} onChange={setPrompt} gender={gender} pose={pose} />
            <GenerateImageEmotion emotion={emotion} onChange={setEmotion} gender={gender} />
            <Button
              disabled={!model}
              iconAbsolute
              className='modal-footer w-100'
              title={'SEND_PHOTO'}
              icon={image_svg}
              onClick={onSubmit}
            />
          </>
          }
        </div>
      </main>
    </>
  );
});

export default GenerateImagePage;