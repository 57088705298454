import React, {FC} from 'react';
import AppStore from "../../../../store/AppStore";
import {ESubscriptionStatus} from "../../../../modules/rest";
import Button from "../../../../components/Button";
import {ReactSVG} from "react-svg";
import plus_active from "../../../../assets/icons/plus.svg";
import crown from "../../../../assets/icons/crown.svg";
import Avatar from "../../../../components/Avatar";
import {isSub, thumb} from "../../../../modules/utils";
import {observer} from "mobx-react";
import {Link} from "react-router-dom";

interface Props {
}

const HomeRightHeader: FC<Props> = observer(() => {
  if (!AppStore.ready) return null;

  return (
    <>
      {!isSub(AppStore.user)
        ?
        <Button
          animate
          btnType='secondary'
          href={'/premium/subscription'}
          size='md'
          icon={crown}
          alternativeTitle='PREMIUM'
          title={'SUB_OFF_75'}
          className='home__header_premium-btn'
        />
        :
        null
      }
      <Button
        animate
        btnType='secondary'
        href={'/premium/stars'}
        size='md'
        className={`btn-animate_secondary btn__header_coins${AppStore.user?.isAnonymous ? ' anonymous' : ''}`}
      >
                    <span className='text-medium position-relative'>⭐<span
                      className='ms-2'>{AppStore.user?.stars}</span></span>
        <ReactSVG src={plus_active} className='react-icon ms-2 ms-lg-3'/>
      </Button>
      <Link to={'/profile'} className='header-avatar'>
        <Avatar user size='sm'/>
      </Link>
    </>
  );
})

export default HomeRightHeader;