import React, {FC} from 'react';
import LegalInformationPageWrapper from "../components/LegalInformationPageWrapper";

interface Props {
}

const ContentRemovalPolicy: FC<Props> = () => {
  return (
    <LegalInformationPageWrapper title='CONTENT_REMOVAL_POLICY' date='10/09/2024'>
      <p>At Stellarmart Limited, we strive to maintain a safe and respectful experience for all our users. Our Content Removal Policy outlines our approach to addressing concerns related to content that may inadvertently resemble real individuals. All content accessible on Noodz.ai (the “WEBSITE”) is generated exclusively through artificial intelligence technology, allowing users to create and interact with AI-generated characters. It is important to note that any similarity to an actual person is unintentional and purely coincidental.</p>

      <h4>1. Unintentional Resemblance to Actual Persons</h4>
      <p>Despite the AI-generated nature of the content on the WEBSITE, we acknowledge that there might be instances where the generated content unintentionally resembles actual persons. Recognizing the concerns that may arise in such situations, we are dedicated to promptly addressing them.</p>

      <h4>2. Content Removal Process</h4>
      <p>If a user believes that any content on our WEBSITE bears resemblance to them or another actual person, they can request its removal by contacting our support team at <a href="mailto:support@noodz.ai" target='_blank'>support@noodz.ai</a> or directly report their concern on the WEBSITE in the “Contact” section. We will thoroughly review the request and take appropriate action within a reasonable timeframe.</p>

      <h4>3. User Verification</h4>
      <p>To ensure the accuracy and legitimacy of content removal requests, we may request the user to provide adequate evidence of their identity or relationship to the person depicted in the content. This verification process is implemented to responsibly handle requests and safeguard the rights and interests of all users.</p>

      <h4>4. Content Removal</h4>
      <p>Upon verification and confirmation of a valid content removal request, the specified content will be removed in a timely manner from the WEBSITE. Our goal is to complete this process promptly while ensuring compliance with applicable laws and regulations.</p>

      <h4>5. Privacy</h4>
      <p>Our highest priority is to respect user privacy throughout the entire content removal process. All requests are treated as strictly confidential, and we do not disclose any personal information or details of the requests to any third parties without explicit consent, unless required by law.</p>

      <h4>6. Contact Information</h4>
      <p>If you have any questions or require further clarification regarding our Content Removal Policy, please contact us at: <a href="mailto:support@noodz.ai" target='_blank'>support@noodz.ai</a> or directly report it on the WEBSITE in the “Contact” section. We are committed to addressing concerns in a timely manner and ensuring a positive experience for all our users.</p>
    </LegalInformationPageWrapper>
  );
}

export default ContentRemovalPolicy;