import React, {FC} from 'react';
import {ICreateModelRequest} from "../../../modules/rest";
import {observer} from "mobx-react";
import { personalityData} from "../assets/modules/utils";
import {useTranslation} from "react-i18next";
import StepItem from "./StepItem";


interface Props {
  onStepChange: (data?: Partial<ICreateModelRequest>) => void;
  data?: Partial<ICreateModelRequest>;
}

const Step9Personality: FC<Props> = observer(({onStepChange, data}) => {
  const {t} = useTranslation();

  const handleStepChange = (personality: ICreateModelRequest['personality']) => () => {
    onStepChange({personality});
  }

  return (
    <>
      <div className='row g-3'>
        {Object.entries(personalityData).map(([value, item], i) => (
          <div className='col-6 col-md-4' key={i}>
            <StepItem
              onClick={handleStepChange(value)}
              active={value === data?.personality}
              className='flex-column h-100'
            >
              <div className='text-14 item__personality_title'>
                <span>{item.icon}</span><span>{t(item.title.toUpperCase())}</span>
              </div>
              <p className='text-dark mt-2 pt-1'>{t(item.title.toUpperCase() + '_TEXT')}</p>
            </StepItem>
          </div>
        ))}
      </div>
    </>
  );
})

export default Step9Personality;