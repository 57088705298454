import React, {FC, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

interface Props {
  finished: boolean;
  showResult: boolean;
  className?: string;
  taskId?: string;
}

const titles = ['GENERATE_IMAGE_TITLE_1', 'GENERATE_IMAGE_TITLE_2', 'GENERATE_IMAGE_TITLE_3', 'GENERATE_IMAGE_TITLE_4', 'GENERATE_IMAGE_TITLE_5'];

const GenerateImageTitle: FC<Props> = ({finished, showResult, className, taskId}) => {
  const {t} = useTranslation();
  const _timer: any = useRef(null);
  const [activeIdx, setActiveIdx] = useState(0);

  useEffect(() => {
    if (showResult) {
      setActiveIdx(999);
      stop();
    } else if (finished) {
      stop();
      setActiveIdx(4);
    } else {
      start();
    }
    return () => {
      stop();
    };
  }, [finished, showResult]);

  useEffect(() => {
    stop()
    setActiveIdx(0);
    start()
  }, [taskId]);


  const start = () => {
    setActiveIdx(prevState => {
      if (prevState === 3) {
        stop();
        return 4;
      }
      _timer.current = setTimeout(start, 2000);
      return prevState >= 4 ? 0 : prevState + 1;
    });
  };

  const stop = () => {
    clearTimeout(_timer?.current);
  };

  return (
    <h2 className={className}>
      {titles.map((title, i) => (
        <span key={i} className={i === activeIdx ? 'visible' : ''}>{t(title)}</span>
      ))}
      <span className={showResult ? 'visible' : ''}>{t('GENERATE_IMAGE_TITLE_RESULT')}</span>
    </h2>
  );
};

export default GenerateImageTitle;