import React, {FC} from 'react';
import { EButtSize, ICreateModelRequest} from "../../../modules/rest";
import {observer} from "mobx-react";
import {
  getImageByModel
} from "../assets/modules/utils";
import AppStore from "../../../store/AppStore";
import {useTranslation} from "react-i18next";
import StepItem from "./StepItem";

interface Props {
  onStepChange: (data?: Partial<ICreateModelRequest>) => void;
  data?: Partial<ICreateModelRequest>;
}

const Step8ButtSize: FC<Props> = observer(({onStepChange, data}) => {

  const handleStepChange = (buttSize: ICreateModelRequest['buttSize']) => () => {
    onStepChange({buttSize});
  }

  return (
    <>
      <div className='row g-2 g-lg-3 create__ai_list'>
        {Object.values(EButtSize).map(buttSize => {
          const image = getImageByModel(AppStore.gender, 'buttSize', buttSize);
          if (!image) return null;
          return <div key={buttSize} className='col-4 col-md-3 col-lg-2'>
            <StepItem
              size='small'
              onClick={handleStepChange(buttSize)}
              active={buttSize === data?.buttSize}
              title={buttSize.toUpperCase()}
            >
            <img src={image} alt={buttSize}/>
          </StepItem>
          </div>
        })}
      </div>
    </>
  );
})

export default Step8ButtSize;