import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import Header, {HeaderSecondary} from '../../../containers/Header';
import {useNavigate} from 'react-router-dom';

interface Props {
  children: any;
  title: string;
  date?: string;
}

const LegalInformationPageWrapper: FC<Props> = ({title, date, children}) => {
  const {t} = useTranslation();
  const navigate  = useNavigate();
  return (
    <>
      <HeaderSecondary title={title} onBack={() => navigate('/')}/>
      <main className='container'>
        <h2 className='page-title'>{t(title)}</h2>
        <h3 className='legal-information-date'>Date of Revision: [{date || '01/01/2024'}]</h3>
        <div className='legal-information-content'>
          {children}
        </div>
      </main>
    </>
  );
};

export default LegalInformationPageWrapper;