import React, {FC} from 'react';
import Timer from "../../../components/Timer";
import useTimer from "../../../hooks/useTimer";
import moment from "moment";

interface Props {
  size?: 'sm'
}

let date = localStorage.getItem('timer_date');
const diff: any = moment().diff(date, 'minutes');
if (diff >= 1 || isNaN(diff)) {
  date = moment().add(1, 'hours').format('YYYY-MM-DD HH:mm:ss');
  localStorage.setItem('timer_date', date);
}


const HomeTimer: FC<Props> = ({size}) => {
  const [timer] = useTimer(date as string);
  // const timer: any = {}

  return (
    <div className={`timer ${size || ''}`}>
      {timer.days > 0 &&
        <Timer time={timer.days} separator disabled={timer.days === 0}/>
      }
      <Timer
        time={timer.hours}
        separator
        disabled={timer.hours === 0 && timer.days === 0}
      />
      <Timer
        time={timer.minutes}
        separator
        disabled={timer.minutes === 0 && timer.hours === 0 && timer.days === 0}
      />
      <Timer
        time={timer.seconds}
        disabled={timer.seconds === 0 && timer.minutes === 0 && timer.hours === 0 && timer.days === 0}
      />
    </div>
  );
}

export default HomeTimer;