import asyncModal from 'react-async-modal';
import Button from '../components/Button';
import {ReactSVG} from "react-svg";
import React, {FC, useState} from "react";
import close from "../assets/icons/close.svg";
import logo from "../assets/images/logo.png";
import {requestPermissionMessaging} from "../modules/firebase";
import {useTranslation} from "react-i18next";

interface Props {
  resolve(permission?: string): void;
}

const PushNotificationModal: FC<Props> = ({resolve}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    if (loading) return;
    setLoading(true);
    const permission = await requestPermissionMessaging();
    setLoading(false)
    resolve(permission);
  }

  const handleClose = () => {
    resolve()
  }

  return (
    <div className='modal__container'>
      <ReactSVG src={close} className='modal-close' onClick={handleClose}/>
      <div className='modal__pushNotification_logo'>
        <img src={logo} alt="logo"/>
      </div>
      <h4 className='text-center'>{t('PUSH_NOTIF_TITLE')}</h4>
      <p className='text-center mx-auto text-dark' style={{maxWidth: 350, marginTop: 12}}>{t('PUSH_NOTIF_TEXT')}</p>
      <div className="modal-footer">
        <Button
          loading={loading}
          contentClassName={'px-3'}
          title={'SURE'}
          onClick={submit}
        />
      </div>
    </div>
  );
};

const openPushNotificationModal = (): Promise<string> => {
  return asyncModal(PushNotificationModal, {}, {
    showCloseIcon: false,
    center: true,
    // animationDuration: 0,
  });
};

export {openPushNotificationModal};
