import React, {FC, useState} from 'react';
import {EGender} from '../../modules/rest';
import GenderIcon from './GenderIcon';
import {useTranslation} from 'react-i18next';
import {ReactSVG} from 'react-svg';
import chevron from '../../assets/icons/chevron_top_16.svg';
import OutsideClick from '../OutsideClick';
import {observer} from "mobx-react";
import AppStore from "../../store/AppStore";

interface Props {
  className?: string;
  icon?: boolean;
  onChange?: (gender: EGender) => void;
  defaultGender?: EGender;
  defaultChange?: (gender: EGender) => void;
}

const GenderSelect: FC<Props> = observer(({className, icon, onChange, defaultGender, defaultChange}) => {
  const {t} = useTranslation();
  const [visible, setVisible] = useState(false);

  const toggleVisible = (e: any) => {
    e.stopPropagation()
    setVisible(prevState => !prevState)
  }

  const handleChange = (gender: EGender) => () => {
    AppStore.changeGender(gender);
    setVisible(false);
    if (onChange) onChange(gender);
  }

  const gender = defaultGender || AppStore.gender;

  return (
    <OutsideClick outsideClickEvent={() => setVisible(false)}
                  className={`gender-select${visible ? ' visible' : ''} ${className || ''}`}>
      <div className='gender-select-value' onClick={toggleVisible}>
        <GenderIcon gender={gender}/>
        {!icon && <span className='ms-2 me-1 letter-uppercase'>{t(gender.toUpperCase())}</span>}
        <ReactSVG src={chevron} className='react-icon gender-select-value-arrow'/>
      </div>
      <div className='gender-select-hidden'>
        {Object.values(EGender).reverse().map(item => (
          <div
            key={item}
            className={`gender-select-hidden-item${gender === item ? ' active' : ''}`}
            onClick={defaultChange ? (() => defaultChange(item)) : handleChange(item)}
          >
            <GenderIcon gender={item as EGender}/>
            <span className='ms-2 letter-uppercase'>{t(item.toUpperCase())}</span>
          </div>
        ))}
      </div>
    </OutsideClick>
  );
});

export default GenderSelect;