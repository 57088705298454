import React, {FC} from 'react';
import Button from '../../../components/Button';
import generate from '../../../assets/icons/generate.svg';
import crown from '../../../assets/icons/crown.svg';
import user from '../../../assets/icons/user-btn.svg';
import ellipse from '../../../assets/icons/ellipse.svg';
import chat from '../../../assets/icons/chat.svg';
import arrow_tr from '../../../assets/icons/arrow_tr.svg';
import GenderSelect from '../../../components/GenderSelect';
import {observer} from 'mobx-react';
import AppStore from '../../../store/AppStore';
import {IModel} from '../../../modules/rest';
import {useTranslation} from 'react-i18next';
import {ReactSVG} from 'react-svg';
import TopCarousel from './TopCarousel';
import {useNavigate} from 'react-router-dom';
import HomeTimer from './HomeTimer';
import HomeMobileCarousel from "./HomeMobileCarousel";


interface Props {
  onAnswer: (model: IModel) => void;
}

const TopSection: FC<Props> = observer(({onAnswer}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleClick = (path: string) => () => {
    if (!AppStore.user?.id) {
      return navigate('/auth/login', {state: {prevPathname: path}});
    }
    navigate(path);
  };

  return (
    <section className='home-top-section'>
      <HomeMobileCarousel onClick={onAnswer}/>
      <div className='container home-top-section-container'>
        <div className='home-top-left'>
          <GenderSelect/>
          <TopCarousel onAnswer={onAnswer}/>
        </div>
        {/*<TopCarousel onAnswer={onAnswer} />*/}
        <div className='home-top-right'>
          <div className='home-top-right-content'>
            <h1 dangerouslySetInnerHTML={{__html: t('HOME_TITLE') || ''}}/>
            <div className='home-top-text'>
              {t('HOME_TEXT')}
            </div>
            <div className='d-flex flex-column'>
              <Button href={'/create-ai'} size='lg' className='btn__user-animate'>
                <div className='btn__user_animate-icon me-0'>
                  <ReactSVG src={ellipse} className='btn__user_animate-icon-ellipse'/>
                  <ReactSVG src={ellipse} className='btn__user_animate-icon-ellipse'/>
                  <ReactSVG src={user} className='react-icon'/>
                </div>
                <span className='flex-1'>{t('CREATE_YOUR_AI')}</span>
              </Button>
            </div>
            {/*<Button*/}
            {/*  btnIcon*/}
            {/*  icon={crown}*/}
            {/*  btnType='secondary'*/}
            {/*  href={'/premium/subscription'}*/}
            {/*  size='lg'*/}
            {/*/>*/}
            <div className='home-top-footer'>
              <div className='d-flex gap-2'>
                <Button
                  size='lg'
                  href={'/conversation'}
                  btnType='outline-third'
                >
                  <div className='home-top-btn_top'>
                    <ReactSVG src={chat} className='home-top-btn_icon'/>
                    <ReactSVG src={arrow_tr} className='home-top-btn_icon react-icon-arrow'/>
                  </div>
                  <div className='mt-2'>{t('CHAT_WITH_AI')}</div>
                </Button>
                <Button
                  size='lg'
                  onClick={handleClick('/request-photo')}
                  btnType='outline-third'
                >
                  <div className='home-top-btn_top'>
                    <ReactSVG src={generate} className='home-top-btn_icon'/>
                    <ReactSVG src={arrow_tr} className='home-top-btn_icon react-icon-arrow'/>
                  </div>
                  <div className='mt-2'>{t('SPICY_PHOTOS')}</div>
                </Button>
              </div>
              <Button
                className='w-100'
                size='lg'
                href={'/premium/subscription'}
                btnType='outline-secondary'
              >
                <div className='home-top-btn_top'>
                  <ReactSVG src={crown} className='home-top-btn_icon'/>
                  <ReactSVG src={arrow_tr} className='home-top-btn_icon react-icon-arrow'/>
                </div>
                <div className='mt-2 d-flex align-items-center justify-content-between w-100 flex-wrap'>
                  <div className='pe-2 pre-wrap text-start'>{t('FIRST_SUB')} <span
                    className='text-tint'>{t('UP_OFF_75')}</span></div>
                  <HomeTimer/>
                </div>
              </Button>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
});

export default TopSection;