import React, {FC, useState} from 'react';
import {ICreateModelExtraPhotosTask, IModel} from "../../../modules/rest";
import GenerateAI from "../components/GenerateAI";
import Button from "../../../components/Button";
import image_svg from "../../../assets/icons/image_white.svg";
import flask from "../../../assets/icons/flask.svg";
import refresh from "../../../assets/icons/refresh.svg";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {API} from "../../../modules/api";
import {openModalNoMoney} from "../../../modals";
import {toast} from "react-toastify";
import AppStore from '../../../store/AppStore';

interface Props {
  finished: boolean;
  showResult: boolean;
  task: ICreateModelExtraPhotosTask;
  getTasks: () => void;

}

const GenerateImage: FC<Props> = ({task, finished, getTasks, showResult}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [btnLoading, setBtnLoading] = useState(false);

  const handleReGenerate = async () => {
    if (btnLoading) return;
    try {
      setBtnLoading(true);
      if (location.state) {
        let {model, task, ...req} = location.state || {};
        const res = await API.Models.createExtraPhoto(model?.id!, req || {});
        navigate(`/generate/photo`, {state: {...req, model, task: res}});
        getTasks()
      }
    } catch (e: any) {
      const code = API.getStatusCode();
      if (code === 402) {
        if (AppStore.user?.isAnonymous) {
          navigate('/auth', {state: {prevPathname: '/request-photo'}})
        } else {
          openModalNoMoney('create-ai').then((path) => {
            if (path) navigate(path);
          });
        }

      } else {
        toast.error(e);
      }
    } finally {
      setBtnLoading(false);
    }
  };

  return (
    <>
      <GenerateAI
        showResult={showResult}
        finished={finished}
        error={task?.error}
        photo={task?.result?.photo}
        model={task?.result?.model}
      />
      {showResult &&
        <>
          <Button
            loading={btnLoading}
            iconAbsolute
            className='mt-3 w-100'
            onClick={handleReGenerate}
            title='GENERATE_MORE'
            icon={image_svg}
            price={2}
          />

          <Button
            btnType='secondary'
            icon={flask}
            title='CHANGE_PARAMS'
            iconAbsolute
            className='mt-3 w-100'
            onClick={() => navigate('/request-photo', {state: location.state})}
          />
          <Button
            btnType='secondary'
            icon={refresh}
            title='SELECT_OTHER_MODEL'
            iconAbsolute
            className='mt-3 w-100'
            href='/request-photo'
          />
          <div className='text-center mt-4 text-14'>
            <span className='text-dark'>{t('PHOTO_ADDED_GALLERY')}</span> <Link to='/gallery'
                                                                                className='text-accent'>{t('GO_TO')}</Link>
          </div>

        </>
      }

    </>
  );
}

export default GenerateImage;