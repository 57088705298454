import React, {FC, useEffect, useLayoutEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {Route, Routes, useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {AuthRecovery, AuthSign} from './index';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {HeaderSecondary} from '../../containers/Header';
import ChatStore from '../../store/ChatStore';
import {toast} from 'react-toastify';
import AppStore from '../../store/AppStore';
import {googleClientId} from "../../modules/firebase";
import AuthTelegram from "./components/AuthTelegram";
import {API} from "../../modules/api";
import {IUser} from "../../modules/rest";

interface Props {
}

const dataTitles: Record<TAuthType, string> = {
  'login': 'SIGN_IN',
  'register': 'CREATE_ACC',
  'recovery': 'RESET_PASSWORD',
  'telegram': 'SIGN_IN_TELEGRAM',
};

export type TAuthType = 'login'|'register'|'recovery'|'telegram'
export type TTelegram = { code: string; url: string; qr: string };

const AuthPage: FC<Props> = observer(() => {


  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [prevState, setPrevState] = useState<{
    prevPathname?: string,
    sharedChatId?: string,
    modelIdConversation?: string
  }>();
  const [telegram, setTelegram] = useState<TTelegram>();

  useLayoutEffect(() => {
    setPrevState(location.state || {})
    if (AppStore.ready && AppStore.user) {
      if (!AppStore.user?.isAnonymous) {
        navigate('/', {replace: true})
      } else {
        fetchTg();
      }
    }
  }, [AppStore.ready]);


  useEffect(() => {
    if (searchParams.get('event') === 'password_updated') {
      toast.success('Password updated');
      searchParams.delete('event');
      setSearchParams(searchParams);
    }
    if (searchParams.get('email')) {
      searchParams.delete('email');
      setSearchParams(searchParams);
    }

  }, []);

  const fetchTg = async () => {
    try {
      const utm: any = localStorage.getItem('utm') || {};
      const res = await API.Users.initLoginWithTelegram({utm: JSON.parse(utm)});
      setTelegram(res);
    } catch (e: any) {
      toast.error(e);
    }
  }

  const onSuccess = async (data: { token: string; user: IUser }) => {
    window.localStorage.setItem('token', data.token);
    API.setToken(data.token);
    await AppStore.login(data.user);
    let conversationId;
    if (prevState?.modelIdConversation) {
      const res = await API.Models.startConversation(prevState.modelIdConversation);
      await ChatStore.getList(true);
      await ChatStore.getChat(res.id);
      conversationId = res.id;
    }
    handleBack(conversationId);
  }

  const handleBack = (conversationId?: number) => {
    if (typeof conversationId === 'number') {
      navigate(`/conversation/${conversationId}`, {replace: true});
    } else {
      navigate(prevState?.prevPathname || '/', {replace: true});
    }
  }

  const authType = params['*'] || 'login';


  return (
    <div className='auth__page'>
      <HeaderSecondary title={dataTitles[authType as TAuthType] || 'SIGN_IN'} onBack={() => navigate('/')}/>
      {(AppStore.ready && AppStore.user?.isAnonymous) &&
        <main className='container overflow-visible'>
          <div className='auth-page-content blur__bg_container'>

            <Routes>
              <Route path='recovery' element={<AuthRecovery/>}/>
              <Route path='telegram' element={<AuthTelegram onSuccess={onSuccess} data={telegram} fetch={fetchTg}/>}/>
              <Route path='*' element={
                <GoogleOAuthProvider
                  clientId={googleClientId}>
                  <AuthSign
                    authType={authType as TAuthType}
                    onSuccess={onSuccess}
                  />
                </GoogleOAuthProvider>
              }/>
            </Routes>

          </div>
        </main>
      }
    </div>
  );
});

export default AuthPage;