import {initializeApp} from "firebase/app";
import {getMessaging, getToken, isSupported,} from "firebase/messaging";
import {getAnalytics,} from "firebase/analytics";
import AppStore from "../store/AppStore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


export const googleClientId = '969775468828-mqj8ghlni4j4ep184752kjsio92pm2u1.apps.googleusercontent.com'
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyD-cznPZ7AWBNKYNaBhncz5OxYgS5_R8wU",
  authDomain: "chatto-428207.firebaseapp.com",
  projectId: "chatto-428207",
  storageBucket: "chatto-428207.appspot.com",
  messagingSenderId: "969775468828",
  appId: "1:969775468828:web:e3d1b6c2cd35070cea2ad9",
  measurementId: "G-JKPV7PCF9Q"
};

let messaging: any;

// Initialize Firebase
export const initFirebase = async () => {
  const app = initializeApp(firebaseConfig);
  try {
    const isSup = await isSupported();
    if (isSup) messaging = getMessaging(app);
  } catch (e) {
  }
}


export async function requestPermissionMessaging() {
  //requesting permission using Notification API
  if (!messaging) return;
  const permission = await Notification.requestPermission();
  localStorage.setItem('firebaseMessagingPermission', permission);
  if (permission === "granted") {
    const token = await getToken(messaging);
    AppStore.updateUser({fcmToken: token});
    localStorage.setItem('fcm', token);
    //We can send token to server
    // console.log("Token generated : ", token);
  } else if (permission === "denied") {
    //notifications are blocked
  }
  return permission;
}



