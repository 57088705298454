import React, {FC} from 'react';
import chatStore from "../../../../store/ChatStore";
import ChatStore from "../../../../store/ChatStore";
import Message from "../../../../components/Message";
import {ESender, IAsset, IPhoto} from "../../../../modules/rest";
import {observer} from "mobx-react";
import AppStore from "../../../../store/AppStore";
import {openGalleryModal} from "../../../../modals";
import {toJS} from "mobx";
import {useNavigate} from "react-router-dom";

interface Props {
  loading: boolean;
  typing: boolean;
  uploading: boolean;
}

const ChatBoard: FC<Props> = observer(({typing, uploading, loading}) => {
  const navigate = useNavigate();

  const handleOpenImage = (photo?: IAsset | null) => {
    openGalleryModal({image: photo, model: ChatStore.activeChat?.model, options: ['generate']}).then((res) => {
      if(res === 'generate') {
        navigate('/request-photo', {state: {model: toJS(ChatStore.activeChat?.model)}})
      }
    })
  }

  return (
    <div >
      {/*{(loading) &&*/}
      {/*  <div className='d-flex justify-content-center'>*/}
      {/*    <Spinner loading absolute style={{marginTop: -8}}/>*/}
      {/*  </div>*/}
      {/*}*/}
      {chatStore.messages?.map((item, i) => (
        <Message
          onImageClick={handleOpenImage}
          name={item.sender === ESender.Model ? ChatStore.activeChat?.model?.firstName : AppStore.user?.name}
          avatarId={ChatStore.activeChat?.model.image?.id}
          data={item}
          key={item.id}
          hideAvatar={item.sender === chatStore.messages[i + 1]?.sender || (i === chatStore.messages.length - 1 && item.sender === ESender.Model && (typing || uploading))}
        />
      ))}
      {typing  &&  <Message avatarId={ChatStore.activeChat?.model?.image?.id} name={ChatStore.activeChat?.model?.firstName} typing/>}
      {uploading && <Message avatarId={ChatStore.activeChat?.model?.image?.id} name={ChatStore.activeChat?.model?.firstName} uploading/>}
    </div>
  );
})

export default ChatBoard;