import React, { FC  } from 'react';
import Button from '../../../../components/Button';
import ProfileSettings from '../../../ModelProfilePage/components/ProfileSettings';
import ChatStore from "../../../../store/ChatStore";
import {observer} from "mobx-react";

interface Props {
  onProfileClick: (e: any) => void;
}

const ChatBoardControls: FC<Props> = observer(({onProfileClick}) => {
  return (
    <div className={`chat__board_controls`}>
      <Button
        onClick={onProfileClick}
        btnType='secondary'
        size='md'
        title='VIEW_PROFILE'
      />
      <ProfileSettings conversation={ChatStore.activeChat} />
    </div>
  );
});

export default ChatBoardControls;