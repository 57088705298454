import React, { FC, useLayoutEffect, useState } from 'react';
import { Textarea } from '../../../components/FormControls';
import Button from '../../../components/Button';
import refresh from '../../../assets/icons/refresh.svg';
import prompt_svg from '../../../assets/icons/prompt.svg';
import {  TTemplatePrompt } from '../modules/utils';
import { EGender, EPose } from '../../../modules/rest';
import { useTranslation } from 'react-i18next';
import { API } from '../../../modules/api';
import { shuffleArr } from '../../../modules/utils';

interface Props {
  prompt: string;
  onChange: (prompt: string) => void;
  gender?: EGender;
  pose?: EPose;
}

const GeneratePromptInput: FC<Props> = ({ prompt, onChange, pose = EPose.Default }) => {
  const { t } = useTranslation();

  const [templates, setTemplates] = useState<TTemplatePrompt[]>();
  const [shuffleTemplates, setShuffleTemplates] = useState<TTemplatePrompt[]>();
  const [activeTemplateId, setActiveTemplateId] = useState<string>();

  useLayoutEffect(() => {
    fetch();
  }, [pose]);

  const fetch = async () => {
    try {
      const res = await API.Dictionary.getSuggestions({pose})
      setTemplates(res);
      setShuffleTemplates(shuffleArr(res).splice(0, 3));
    } catch (e) {

    }
  }

  const handleChangeTemplates = () => {
    setShuffleTemplates(shuffleArr(templates || []).splice(0, 3));
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    if (!value) setActiveTemplateId(undefined);
    onChange(value);
  };

  return (
    <>
      <div className='mb-2 mt-4 text-dark-14'>{t('ENTER_PROMPT')}</div>
      <div className='generate-prompt-card'>
        <Textarea
          icon={prompt_svg}
          value={prompt}
          onChange={handleChange}
        />
        <div className={`d-flex mt-3 gap-2 flex-wrap${!shuffleTemplates?.length ? ' opacity-0' : ''}`}>
          {shuffleTemplates?.map((template, i) => (
            <div key={i}>
              <Button
                onClick={() => {
                  setActiveTemplateId(template.title);
                  onChange(template.description);
                }}
                className={`${template.title === activeTemplateId ? 'active' : ''}`}
                btnType='secondary'
                size='xs'
                title={template.title}
              />
            </div>
          ))}
          <Button
            icon={refresh}
            onClick={handleChangeTemplates}
            btnType='secondary'
            size='xs'
            btnIcon
          />
        </div>
      </div>

    </>
  );
};

export default GeneratePromptInput;