import React, {FC} from 'react';
import {EGender} from "../../modules/rest";
import {ReactSVG} from "react-svg";
import female from "../../assets/icons/female.svg";
import male from "../../assets/icons/male.svg";

const icons: { [GENDER in EGender]: { icon: any  } } = {
  [EGender.Female]: {icon: female },
  [EGender.Male]: {icon: male },
}

interface Props {
  gender: EGender;
  className?: string;
  size?: 'xl'
}

const GenderIcon: FC<Props> = ({className,  gender, size}) => {
  return (
      <ReactSVG src={icons[gender].icon} className={`react-icon gender-select-icon ${className || ''}`}/>
  );
}

export default GenderIcon;