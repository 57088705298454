import React, {FC} from 'react';

interface Props {
  className?: string;
  type?: 'model' | 'nav'
}

const Skeleton: FC<Props> = ({className, type = 'model'}) => {
  return (
    <div className={`skeleton skeleton-${type} ${className || ''}`} />
  );
}

export default Skeleton;