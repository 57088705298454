import React, {FC} from 'react';
import LegalInformationPageWrapper from "../components/LegalInformationPageWrapper";

interface Props {
}

const PrivacyPolicies: FC<Props> = () => {
  return (
        <LegalInformationPageWrapper title='PRIVACY_POLICY' date='10/09/2024'>
          <p>Noodz.ai (the “WEBSITE”) is owned and operated by STELLARMART LIMITED, duly incorporated in Hong Kong, having its address at ROOM 511 5/F MING SANG IND BLDG 19-21 HING YIP STREET Kwun Tong, with registration number 76903430. Noodz.ai is an online chat application that uses artificial intelligence algorithms to generate virtual and fictional characters (the “AI Character”). The WEBSITE also generates images and messages so that you can chat with the AI Character. Parts of the Services offered by the WEBSITE may require you to create a user account. For purposes of this Privacy Policy, “you” and “your” means you as the user of the APP.</p>

          <h4>GDPR</h4>
          <p>If you are a resident of the European Union, you are protected by and enjoy additional rights under the European Union’s General Data Protection Regulation 2016/679, also known as GDPR.</p>

          <h4>1. Definitions</h4>
          <p>As we are committed to respecting your privacy, such Services will always be provided with your consent. In this document, we provide clear information on how your data is processed when you visit the Website and when you use our Services. At any time, please refer to the following definitions:</p>
          <ul>
            <li><strong>Content:</strong> Information that you will provide us with so that we can register you as a User and the information you will upload on our Platform. This information shall include your Personal Data and the discussions with the AI Character.</li>
            <li><strong>Consent:</strong> Any freely given, specific, informed, and unambiguous indication of your wishes by which you, by a statement or by a clear affirmative action, signify agreement to the Processing of Personal Data relating to you.</li>
            <li><strong>Performance of our Services:</strong> The actions necessary for us to provide our Services.</li>
            <li><strong>Personal Data:</strong> Any information relating to you as an identified person, directly or indirectly, such as your name, address, marital status, date of birth, gender, office location, position, company name, spoken languages, photos, your identification number, your location data, or factors specific to your physical, physiological, genetic, mental, economic, cultural, or social identity, your networks and connections made available to us or obtained on your behalf, and any personal information you would provide us with.</li>
            <li><strong>Platform:</strong> The WEBSITE.</li>
            <li><strong>Processing:</strong> Any operation or set of operations which is performed on Personal Data or on sets of Personal Data, whether or not by automated means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination, or otherwise making available, alignment or combination, restriction, erasure, or destruction.</li>
            <li><strong>Services:</strong> All the steps for enabling you to chat with the AI Character.</li>
            <li><strong>Subscription:</strong> An arrangement between Stellarmart Limited and you to enable you to benefit from and/or use the Services.</li>
            <li><strong>User:</strong> “You” and “your” collectively mean a person or company that has visited or is using the WEBSITE and/or the Services.</li>
            <li><strong>Visitor:</strong> Anyone who is browsing the Website without a valid Subscription.</li>
            <li><strong>Website:</strong> Noodz.ai.</li>
          </ul>

          <h4>2. Purpose of Personal Data Processing</h4>
          <p>When visiting the Website, we will collect information for the following purposes:</p>
          <ul>
            <li>Analyzing and understanding your behavior on the Website;</li>
            <li>Performing our Services;</li>
            <li>Making improvements to the Website and Services;</li>
            <li>Enabling us to enhance, customize, or modify our communications;</li>
            <li>Improving data security;</li>
            <li>Determining whether marketing campaigns are effective.</li>
          </ul>
          <p>When registering and using our Services, after a free test of a few messages, you will be asked to register so we can identify you. At this stage, we receive and store any information you enter on the Website or that you provide us with in any other way. The types of Personal Data collected may include (but are not limited to) your name, date of birth, usage information regarding your use of our Services, and browser information.</p>

          <h4>3. Personal Data Collection</h4>
          <p><strong>Personal Data Collection for Visitors</strong></p>
          <p>The Website can be visited anonymously. The Website may process anonymous metadata through cookies such as the URL of the requested file, the amount of data transferred, the date and time of the request from the User, Internet browser information, operating system type, the IP address of the used device, the Internet service provider, and reference links such as user clicks on certain links. This metadata cannot be used by the Website to obtain personally identifiable information. This information is only used for general purposes such as technical improvements, statistical and marketing analysis.</p>

          <p><strong>Personal Data Collection for Users</strong></p>
          <p>We use different methods to collect Personal Data from and about you, including through:</p>
          <ul>
            <li>From the information you provide to us when you register for Subscription;</li>
            <li>From the Content you provide to us via your personal account on the Website;</li>
            <li>When you communicate with us in writing, by telephone, fax, website registration, email, or other forms of electronic communication. In this respect, we may monitor, record, and store any such communication;</li>
            <li>Automated technologies or interactions. As you interact with the AI Character, we may automatically collect technical data about your equipment, browsing actions, and patterns. Our moderation team checks that you are in compliance with law, and we collect this Personal Data by using cookies, server logs, and other similar technologies. We may also receive technical data about you if you visit other websites employing our cookies; and</li>
            <li>Third parties or publicly available sources. We may receive Personal Data about you from various third parties and public sources, such as:</li>
            <ul>
              <li>Analytics providers such as Google Analytics;</li>
              <li>Advertising networks/agencies;</li>
              <li>Search information providers.</li>
            </ul>
          </ul>

          <h4>4. Marketing</h4>
          <p>We may send you marketing about services we provide which may be of interest to you, as well as other information in the form of alerts, newsletters, and invitations to events or functions which we believe might be of interest to you, or in order to update you with information (such as legal or commercial news) which we believe may be relevant to you. We may communicate this to you in a number of ways, including by post, telephone, email, or other digital channels.</p>

          <h4>5. Third-Party Marketing</h4>
          <p>We will get your express opt-in consent before we share your Personal Data with any company outside Usway for marketing purposes. You can ask us or third parties to stop sending you marketing messages at any time by logging into the Website or third parties' websites and checking or unchecking relevant boxes to adjust your marketing preferences or by following the opt-out links on any marketing message sent to you.</p>

          <h4>6. Cookies</h4>
          <p>You can set your browser to refuse all or some browser cookies or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of the Website may become inaccessible or not function properly.</p>

          <h4>7. Change of Purpose</h4>
          <p>We will only use your Personal Data for the purposes for which we collected it unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us. If we need to use your Personal Data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so. Please note that we may process your Personal Data without your knowledge or consent in compliance with the above rules where this is required or permitted by law.</p>

          <h4>8. Sharing Your Personal Information</h4>
          <p>Noodz.ai may share your information with:</p>
          <ul>
            <li>Service providers we work with to deliver the Services;</li>
            <li>Our professional advisers where it is necessary for us to obtain their advice or assistance, including lawyers, accountants, IT or public relations advisers;</li>
            <li>Legal and regulatory authorities as required by applicable laws and regulations; and</li>
            <li>Our employees.</li>
          </ul>
          <p>We will not disclose, sell, trade, or otherwise transfer your personal information to any third parties without your consent or unless otherwise stated in this Privacy Policy. This Privacy Policy does not apply to the practices of service providers and other third parties that we do not own or control or individuals that we do not employ or manage.</p>

          <h4>9. Sensitive Information</h4>
          <p>We will never ask you to provide us with Personal Data revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership, genetic data, biometric data for the purpose of uniquely identifying a natural person, or data concerning health. If you decide to provide us with such sensitive information, you understand that you willingly disclose such sensitive information.</p>

          <h4>10. Controller</h4>
          <p>This Privacy Policy is issued on behalf of Stellarmart Limited, so when we mention “we” or “us” in this Privacy Policy, we are referring to Stellarmart Limited. We have appointed a Controller within our team who is responsible for overseeing questions in relation to this Privacy Policy. If you have any questions about this Privacy Policy, including any requests to exercise your legal rights, please contact the Controller using the details set out below.</p>
          <p><strong>Contact details:</strong> <a href="mailto:support@noodz.ai" target='_blank'>support@noodz.ai</a></p>

          <h4>11. Request to Access Your Personal Data</h4>
          <p>At any time, you have the right to access your Personal Data. This enables you to receive a copy of the Personal Data we hold about you and to check that we are lawfully Processing it.</p>

          <h4>12. Period</h4>
          <p>We retain your personal information for as long as your account is in existence or necessary to fulfill the purposes for which we collect it or as needed to provide you with the Services, except if required otherwise by law. However, when you terminate your account, we will still retain your personal information for a period of time. Usually, we will store your personal information for a period of 6 (six) years after you cease being a User of our Services, beginning at the date your account is closed. Retention periods may be changed from time to time based on business or regulatory requirements.</p>

          <h4>13. Personal Information of Minors</h4>
          <p>Stellarmart Limited does not provide Services and collect personal information from anyone under 18 years of age or equivalent minimum age depending on jurisdiction. Our Services are intended for use only by adults who are 18 years of age and over. If we learn that we have been misled by an individual under 18 or equivalent minimum age depending on jurisdiction, we will take steps to delete the information as soon as possible and block this user.</p>

          <h4>14. Third-Party Links</h4>
          <p>The Website may include links to third-party websites, plug-ins, and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. We encourage you to read the Privacy Policy of every website that can be accessed through the Website.</p>

          <h4>15. Merge of Company</h4>
          <p>If Stellarmart Limited merges with or is acquired by another company or organization, or sells all or a portion of its assets, your personal information may be disclosed to our advisers and any prospective purchaser or any prospective purchaser’s adviser, and may be among the assets transferred. However, personal information will always remain subject to this Privacy Policy.</p>

          <h4>16. Right to Rectification</h4>
          <p>You have the right to obtain from the Controller, without undue delay, the rectification of inaccurate Personal Data concerning you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.</p>

          <h4>17. Right to Request Restriction of Processing of Your Personal Data</h4>
          <p>This enables you to ask our Controller to suspend the processing of your Personal Data in the following scenarios:</p>
          <ul>
            <li>If you want us to establish the Personal Data’s accuracy;</li>
            <li>Where our Processing of the Personal Data is unlawful and you do not want us to erase it and request us to suspend the Processing instead;</li>
            <li>Where you need us to hold the Data to establish, exercise, or defend legal claims; or</li>
            <li>You have objected to our Processing of your Personal Data and we need to verify whether we have overriding legitimate grounds to use it.</li>
          </ul>

          <h4>18. Withdrawal of Consent</h4>
          <p>We are committed to making it as easy to withdraw as to give consent. You have the right to withdraw your Consent at any time. The withdrawal of Consent shall not affect the lawfulness of Processing of your Personal Data based on consent before its withdrawal. If you withdraw your Consent, we will not be able to provide any more of our Services to you, and we will take reasonable steps, taking into account available technology and means, to delete your user account in a timely manner. We will also delete the Personal Data we have collected on you for internal use. In the eventuality you withdraw your Consent, we are not responsible for Content that would still be hosted on third-party websites.</p>

          <h4>19. Right to Be Forgotten</h4>
          <p>You have the right to obtain from the Controller the erasure of Personal Data concerning you without undue delay, and the Controller undertakes to erase Personal Data without undue delay. This enables you to ask us to delete or remove Personal Data where there is no good reason for us continuing to Process it. You also have the right to ask us to delete or remove your Personal Data where you have successfully exercised your right to object to processing, where we may have processed your information unlawfully, or where we are required to erase your Personal Data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you if applicable at the time of your request. Please object to the Processing of your Personal Data immediately if you feel that such Processing impacts your fundamental rights and freedoms. You also have the right to object where we are processing your Personal Data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.</p>

          <h4>20. Time Limit to Respond</h4>
          <p>We try to respond to all legitimate requests within one month. Occasionally, it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</p>

          <h4>21. Data Security</h4>
          <p>We have put in place appropriate security measures to prevent your Personal Data from being accidentally lost, used, or accessed in an unauthorized way, altered, or disclosed. We have put in place procedures to deal with any suspected Personal Data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>

          <h4>22. Changes to the Privacy Policy and Your Duty to Inform Us of Changes</h4>
          <p>We may revise this Privacy Policy from time to time. Therefore, we may undertake a few changes to it. This version of the Privacy Policy will govern our use of your information. If we make any material changes in the way we use your Personal Data, we shall notify you by posting and sending you an email in order to obtain your approval. By continuing to access or use the Services after those changes become effective, you agree to be bound by the revised Privacy Policy. Please keep a copy of this document at all times and feel free to ask us any questions or address any concerns you would have regarding our Privacy Policy.</p>
    </LegalInformationPageWrapper>
  );
}

export default PrivacyPolicies;