import React, {FC} from 'react';
import {ICreateModelRequest} from "../../../modules/rest";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import StepItem from "./StepItem";
import AppStore from "../../../store/AppStore";


interface Props {
  onStepChange: (data?: Partial<ICreateModelRequest>) => void;
  data?: Partial<ICreateModelRequest>;
}

const Step10Interests: FC<Props> = observer(({onStepChange, data}) => {
  const {t} = useTranslation();

  const handleStepChange = (interests: ICreateModelRequest['interests']) => () => {
    onStepChange({interests});
  }

  return (
    <>
      <div className='row g-3'>
        {AppStore.dict?.[AppStore.gender]['interests'].map((item, i) => (
          <div className='col-6 col-md-4' key={i}>
            <StepItem
              onClick={handleStepChange([item])}
              active={data?.interests?.includes(item)}
              className='step__item_personality'
            >
              <div className='text-14 item__personality_title'>
                {item}
              </div>
            </StepItem>
          </div>
        ))}
      </div>
    </>
  );
})

export default Step10Interests;