import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {toast} from "react-toastify";
import {API} from "../modules/api";
import {EFieldGroup, IConversation, IMessage, TIdentifier} from "../modules/rest";

class ChatStore {
  loading: boolean = false;
  ready: boolean = false;
  chats: IConversation[] = [];
  activeChat?: IConversation;
  messages: IMessage[] = [];

  constructor() {
    makeAutoObservable(this, {
      loading: observable,
      ready: observable,
      chats: observable,
      activeChat: observable,
      messages: observable,

      getList: action,
      getChat: action,
      getMessages: action,
      clean: action,
    });
  }

  getList = async (firstActive?: boolean) => {
    try {
      const res = await API.Conversations.getList([EFieldGroup.ModelFull]);
      runInAction(() => {
        this.chats = res;
      })
      if (firstActive && res[0]) await this.getChat(res[0].id);
      return res;
    } catch (e: any) {
      toast.error(e);
    } finally {
      runInAction(() => {
        this.ready = true;
      })
    }
  };

  getChat = async (id: TIdentifier, withoutSet?: boolean): Promise<[IConversation, IMessage[]]|undefined> => {
    try {
      runInAction(() => {
        this.loading = true;
      })
      const [chat, messages] = await Promise.all([
        await API.Conversations.getConversation(id, [EFieldGroup.ModelFull]),
        await API.Conversations.getMessages(id, {limit: 30}, [EFieldGroup.ModelFull])
      ])

      if (!withoutSet) {
        runInAction(() => {
          this.activeChat = chat;
          this.messages = messages;
        })
      }
      return [chat, messages];
    } catch (e: any) {
      toast.error(e.message, {toastId: 'chat-error'});
    } finally {
      runInAction(() => {
        this.loading = false;
      })
    }
  };

  getMessages = async (id: TIdentifier) => {
    try {
      const messages = await API.Conversations.getMessages(id, {}, [EFieldGroup.ModelFull])
      runInAction(() => {
        this.messages = messages;
      })
    } catch (e: any) {
      toast.error(e.message, {toastId: 'message-error'});
    }
  };

  clean = () => {
    runInAction(() => {
      this.ready = false;
      this.chats = [];
      this.activeChat = undefined;
      this.messages = [];
    })
  };

}

export default new ChatStore();