import React, {FC} from 'react';
import {EBodyType, EHairStyle, ICreateModelRequest} from "../../../modules/rest";
import {observer} from "mobx-react";
import {
  getImageByModel, preloadSrcBoobs
} from "../assets/modules/utils";
import AppStore from "../../../store/AppStore";
import PreloadImages from "../../../components/PreloadImages";
import {useTranslation} from "react-i18next";
import StepItem from "./StepItem";

interface Props {
  onStepChange: (data?: Partial<ICreateModelRequest>) => void;
  data?: Partial<ICreateModelRequest>;
}

const Step6BodyType: FC<Props> = observer(({onStepChange, data}) => {
  const {t} = useTranslation();

  const handleStepChange = (bodyType: ICreateModelRequest['bodyType']) => () => {
    onStepChange({bodyType});
  }

  return (
    <>
      <PreloadImages images={preloadSrcBoobs}/>
      <div className='row g-2 g-lg-3 create__ai_list'>
        {Object.values(EBodyType).map(bodyType => {
          const image = getImageByModel(AppStore.gender, 'bodyType', bodyType);
          if (!image) return null;
          return <div key={bodyType} className='col-4 col-md-3 col-lg-2'>
            <StepItem
              size='small'
              onClick={handleStepChange(bodyType)}
              active={bodyType === data?.bodyType}
              title={bodyType.toUpperCase()}
            >
              <img src={image} alt={bodyType}/>
            </StepItem>
          </div>
        })}
      </div>
    </>
  );
})

export default Step6BodyType;