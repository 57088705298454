import React, {FC} from 'react';
import {NavLink} from "react-router-dom";
import {ReactSVG} from "react-svg";
import {TMenu} from "../Header";
import {useTranslation} from "react-i18next";

interface Props {
  menu: TMenu;
  onClick?: () => void;
}

const MenuListItem: FC<Props> = ({menu, onClick}) => {
  const {t} = useTranslation();

  return (
    <li key={menu.path}>
      <NavLink to={menu.path} className={`menu-item`} onClick={onClick}>
        <ReactSVG src={menu.icon} className='react-icon me-2'/>
        <span className='menu-item-text'>{t(menu.title!)}</span>
        {/*{menu.smallTitle ? <span className='menu-item-text__small'>{t(menu.smallTitle)}</span> : null}*/}
      </NavLink>
    </li>
  );
}

export default MenuListItem;