import {
  EBodyType,
  EBreastSize,
  EButtSize,
  EEthnicity,
  EGender,
  EHairColor,
  EHairStyle,
  EModelStyle
} from "../../../../modules/rest";

import body_petite_f from "../images/bodyType/female/petite.png";
import body_slim_f from "../images/bodyType/female/slim.png";
import body_athletic_f from "../images/bodyType/female/athletic.png";
import body_voluptuous_f from "../images/bodyType/female/voluptuous.png";
import body_curvy_f from "../images/bodyType/female/curvy.png";
import body_slim_m from "../images/bodyType/male/slim.png";
import body_muscular_m from "../images/bodyType/male/muscular.png";
import body_wide_m from "../images/bodyType/male/wide.png";

import boobs_flat_f from "../images/breastSize/female/flat.png";
import boobs_small_f from "../images/breastSize/female/small.png";
import boobs_medium_f from "../images/breastSize/female/medium.png";
import boobs_large_f from "../images/breastSize/female/large.png";
import boobs_huge_f from "../images/breastSize/female/huge.png";

import butt_small_f from "../images/buttSize/female/small.png";
import butt_medium_f from "../images/buttSize/female/medium.png";
import butt_large_f from "../images/buttSize/female/large.png";

import anime_f from "../images/style/female/anime.png";
import real_f from "../images/style/female/real.png";
import real_m from "../images/style/male/real.png";
import anime_m from "../images/style/male/anime.png";

import arab_f from "../images/ethnicity/female/arab.png";
import asian_f from "../images/ethnicity/female/asian.png";
import black_f from "../images/ethnicity/female/black.png";
import latina_f from "../images/ethnicity/female/latina.png";
import caucasian_f from "../images/ethnicity/female/european.png";
import indian_f from "../images/ethnicity/female/indian.png";
import arab_m from "../images/ethnicity/male/arab.png";
import asian_m from "../images/ethnicity/male/asian.png";
import black_m from "../images/ethnicity/male/black.png";
import latina_m from "../images/ethnicity/male/latina.png";
import caucasian_m from "../images/ethnicity/male/european.png";
import indian_m from "../images/ethnicity/male/indian.png";


import straight_f from "../images/hairStyle/female/straight.png";
import braids_f from "../images/hairStyle/female/braids.png";
import bangs_f from "../images/hairStyle/female/bangs.png";
import curly_f from "../images/hairStyle/female/curly.png";
import bun_f from "../images/hairStyle/female/bun.png";
import short_f from "../images/hairStyle/female/short.png";
import long_f from "../images/hairStyle/female/long.png";
import ponytail_f from "../images/hairStyle/female/ponytail.png";
import pigtails_f from "../images/hairStyle/female/pigtails.png";
import buzzCut_f from "../images/hairStyle/female/buzz-cut.png";
import dreadlocks_f from "../images/hairStyle/female/dreadlocks.png";
import bald_f from "../images/hairStyle/female/bald.png";
import slickedBack_f from "../images/hairStyle/female/slicked-back.png";

import curly_m from "../images/hairStyle/male/curly.png";
import bun_m from "../images/hairStyle/male/bun.png";
import short_m from "../images/hairStyle/male/short.png";
import long_m from "../images/hairStyle/male/long.png";
import buzzCut_m from "../images/hairStyle/male/buzz-cut.png";
import dreadlocks_m from "../images/hairStyle/male/dreadlocks.png";
import bald_m from "../images/hairStyle/male/bald.png";
import slickedBack_m from "../images/hairStyle/male/slicked-back.png";


type TType = 'style'|'ethnicity'|'hairStyle'|'bodyType'|'breastSize'|'buttSize'
type TCategories = EModelStyle|EEthnicity|EHairStyle|EBodyType|EBreastSize|EButtSize

export const preloadSrcStyle: any = [anime_f, real_f, real_m, anime_m];
export const preloadSrcHairStyle: any = [curly_m, bun_m, short_m, long_m, buzzCut_m, dreadlocks_m, bald_m, slickedBack_m, straight_f, braids_f, bangs_f, curly_f, bun_f, short_f, long_f, ponytail_f, pigtails_f, buzzCut_f, dreadlocks_f, bald_f, slickedBack_f,];
export const preloadSrcEthnicity: any = [arab_f, asian_f, black_f, latina_f, caucasian_f, indian_f, arab_m, asian_m, black_m, latina_m, caucasian_m, indian_m,];
export const preloadSrcButt: any = [butt_small_f, butt_medium_f, butt_large_f];
export const preloadSrcBodyType: any = [body_petite_f, body_slim_f, body_athletic_f, body_voluptuous_f, body_curvy_f, body_slim_m, body_muscular_m, body_wide_m,];
export const preloadSrcBoobs: any = [boobs_flat_f, boobs_small_f, boobs_medium_f, boobs_large_f, boobs_huge_f,];

export const preloadSrcList: any = [
  ...preloadSrcStyle, ...preloadSrcHairStyle,  ...preloadSrcEthnicity, ...preloadSrcButt, ...preloadSrcBodyType, ...preloadSrcBoobs
]
const modelStyle: { [GENDER in EGender]: { [STYLE in EModelStyle]: any } } = {
  [EGender.Female]: {
    [EModelStyle.Hyperanime]: anime_f,
    [EModelStyle.Real]: real_f,
  },
  [EGender.Male]: {
    [EModelStyle.Hyperanime]: anime_m,
    [EModelStyle.Real]: real_m,
  }

}
const ethnicity: { [GENDER in EGender]: { [DATA in EEthnicity]: any } } = {
  [EGender.Female]: {
    [EEthnicity.Arab]: arab_f,
    [EEthnicity.Asian]: asian_f,
    [EEthnicity.Afro]: black_f,
    [EEthnicity.Latin]: latina_f,
    [EEthnicity.European]: caucasian_f,
    [EEthnicity.Indian]: indian_f,
  },
  [EGender.Male]: {
    [EEthnicity.Arab]: arab_m,
    [EEthnicity.Asian]: asian_m,
    [EEthnicity.Afro]: black_m,
    [EEthnicity.Latin]: latina_m,
    [EEthnicity.European]: caucasian_m,
    [EEthnicity.Indian]: indian_m,
  },

}
const hairStyle: { [GENDER in EGender]: { [DATA in EHairStyle]: any } } = {
  [EGender.Female]: {
    [EHairStyle.Straight]: straight_f,
    [EHairStyle.Braids]: braids_f,
    [EHairStyle.Bangs]: bangs_f,
    [EHairStyle.Curly]: curly_f,
    [EHairStyle.Bun]: bun_f,
    [EHairStyle.Short]: short_f,
    [EHairStyle.Long]: long_f,
    [EHairStyle.Ponytail]: ponytail_f,
    [EHairStyle.Pigtails]: pigtails_f,
    [EHairStyle.BuzzCut]: buzzCut_f,
    [EHairStyle.SlickedBack]: slickedBack_f,
    [EHairStyle.Dreadlocks]: dreadlocks_f,
    [EHairStyle.Bald]: bald_f,
  },
  [EGender.Male]: {
    [EHairStyle.Straight]: undefined,
    [EHairStyle.Braids]: undefined,
    [EHairStyle.Bangs]: undefined,
    [EHairStyle.Curly]: curly_m,
    [EHairStyle.Bun]: bun_m,
    [EHairStyle.Short]: short_m,
    [EHairStyle.Long]: long_m,
    [EHairStyle.Ponytail]: undefined,
    [EHairStyle.Pigtails]: undefined,
    [EHairStyle.BuzzCut]: buzzCut_m,
    [EHairStyle.SlickedBack]: slickedBack_m,
    [EHairStyle.Dreadlocks]: dreadlocks_m,
    [EHairStyle.Bald]: bald_m,
  },

}
const bodyTypeImages: { [GENDER in EGender]: { [DATA in Partial<EBodyType>]: any } } = {
  [EGender.Female]: {
    [EBodyType.Petite]: body_petite_f,
    [EBodyType.Slim]: body_slim_f,
    [EBodyType.Athletic]: body_athletic_f,
    [EBodyType.Voluptuous]: body_voluptuous_f,
    [EBodyType.Curvy]: body_curvy_f,
    [EBodyType.Muscular]: undefined,
    [EBodyType.Wide]: undefined,
  },
  [EGender.Male]: {
    [EBodyType.Petite]: undefined,
    [EBodyType.Slim]: body_slim_m,
    [EBodyType.Athletic]: undefined,
    [EBodyType.Voluptuous]: undefined,
    [EBodyType.Curvy]: undefined,
    [EBodyType.Muscular]: body_muscular_m,
    [EBodyType.Wide]: body_wide_m,
  },
}
const breastSizeImages: { [GENDER in EGender]: { [DATA in Partial<EBreastSize>]: any } } = {
  [EGender.Female]: {
    [EBreastSize.Flat]: boobs_flat_f,
    [EBreastSize.Small]: boobs_small_f,
    [EBreastSize.Medium]: boobs_medium_f,
    [EBreastSize.Large]: boobs_large_f,
    [EBreastSize.Huge]: boobs_huge_f,
  },
  [EGender.Male]: {
    [EBreastSize.Flat]: undefined,
    [EBreastSize.Small]: undefined,
    [EBreastSize.Medium]: undefined,
    [EBreastSize.Large]: undefined,
    [EBreastSize.Huge]: undefined,
  },
}
const buttSizeImages: { [GENDER in EGender]: { [DATA in Partial<EButtSize>]: any } } = {
  [EGender.Female]: {
    [EButtSize.Small]: butt_small_f,
    [EButtSize.Medium]: butt_medium_f,
    [EButtSize.Large]: butt_large_f,
    [EButtSize.Skinny]: undefined,
    [EButtSize.Athletic]: undefined,

  },
  [EGender.Male]: {
    [EButtSize.Small]: undefined,
    [EButtSize.Medium]: undefined,
    [EButtSize.Large]: undefined,
    [EButtSize.Skinny]: undefined,
    [EButtSize.Athletic]: undefined,
  },
}

const data: { [KEY in TType]: any } = {
  style: modelStyle,
  ethnicity: ethnicity,
  hairStyle: hairStyle,
  bodyType: bodyTypeImages,
  breastSize: breastSizeImages,
  buttSize: buttSizeImages,
}

export const getImageByModel = (gender: EGender, type: TType, category: TCategories): any => {
  return data[type]?.[gender]?.[category] || null
}

export const getModelAge = (age: number): string => {
  if (age < 20) return 'TEEN_18';
  if (age < 30) return '20_S'
  if (age < 40) return '30_S'
  return '40-50'
}
export const getModalHairColor = (hairColor: EHairColor): string => {
  if (hairColor === EHairColor.Blonde) return '#fff';
  else if (hairColor === EHairColor.Ginger) return '#b06500'
  else if (hairColor === EHairColor.Redhead) return '#B83C08'
  else if (hairColor === EHairColor.Brunette) return '#664238'
  else if (hairColor === EHairColor.Multicolor) return 'linear-gradient(to left, #fff 0%, red 33%, yellow 66%, pink 100%)'
  return hairColor;
}

export type TPersonality = { icon: string, title: string, text: string }
export const personalityData: { [KEY in string]: TPersonality } = {
  caregiver: {
    icon: '😇',
    title: 'Caregiver',
    text: 'Nurturing, protective, and always there to offer comfort.'
  },
  sage: {icon: '🧐', title: 'Sage', text: 'Wise, reflective, and a source of guidance.'},
  innocent: {icon: '👶', title: 'Innocent', text: 'Optimistic, naive, and sees world with wonder.'},
  jester: {icon: '🤪', title: 'Jester', text: 'Playful, humorous, and always there to make you laugh.'},
  temptress: {
    icon: '💋',
    title: 'Temptress',
    text: 'Flirtatious, playful, and always leaving you wanting more.'
  },
  dominant: {icon: '😎', title: 'Dominant', text: 'Assertive, controlling, and commanding.'},
  submissive: {icon: '😌', title: 'Submissive', text: 'Obedient, yielding, and happy to follow.'},
  lover: {icon: '💖', title: 'Lover', text: 'Romantic, affectionate, and cherishes deep emotional'},
  nympho: {icon: '💦', title: 'Nympho', text: 'Insatiable, passionate, and constantly craving intimacy.'},
  mean: {icon: '👿', title: 'Mean', text: 'Cold, dismissive, and often sarcastic.'},
  confidant: {
    icon: '🤝',
    title: 'Confidant',
    text: 'Trustworthy, a good listener, and always can offer advice.'
  },
  experimenter: {
    icon: '🤩',
    title: 'Experimenter',
    text: 'Curious, willing, and always eager to try new things.'
  },
}

export const getModelPersonalityData = (personality: string): TPersonality => {
  return personalityData[personality]
}

type TRelationship = { icon: string, female: string, male: string }
export const relationshipData: { [VALUE in string]: { male: string, female: string, icon: string } } = {
  stranger: {icon: '🕵️', female: 'Stranger', male: 'Stranger'},
  schoolmate: {icon: '🎒', female: 'School Mate', male: 'Schoolmate'},
  colleague: {icon: '💻', female: 'Colleague', male: 'Work Colleague'},
  mentor: {icon: '🦉', female: 'Mentor', male: 'Mentor'},
  relationship: {icon: '💖', female: 'Girlfriend', male: 'Boyfriend'},
  'sex friend': {icon: '🌶️', female: 'Sex Friend', male: 'Sex Friend'},
  spouse: {icon: '💍', female: 'Wife', male: 'Husband'},
  lover: {icon: '👠', female: 'Mistress', male: 'Lover'},
  friend: {icon: '🍕', female: 'Friend', male: 'Friend'},
  'best friend': {icon: '🔗', female: 'Best Friend', male: 'Best Friend'},
  summarized: {icon: '👩', female: 'Step Sister', male: 'Step Brother'},
  'step father': {icon: '🍏', female: 'Step Mom', male: 'Step Father'},
}
export const getRelationshipData = (personality: string): TRelationship => {
  return relationshipData[personality]
}