import React, {FC, useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {observer} from "mobx-react";
import {EFieldGroup, IModel} from "../../modules/rest";
import {API} from "../../modules/api";
import AppStore from "../../store/AppStore";
import ModelCard from "../../components/ModelCard";
import {toast} from "react-toastify";
import {HeaderSecondary} from "../../containers/Header";
import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";
import ellipse from "../../assets/icons/ellipse.svg";
import ellipse_big from "../../assets/icons/ellipse_big.svg";
import user from "../../assets/icons/user-btn.svg";
import user_big from "../../assets/icons/user_big.svg";
import Button from "../../components/Button";
import plus from "../../assets/icons/plus.svg";
import image_white from "../../assets/icons/image_white.svg";
import chevron_right from "../../assets/icons/chevron_r_16.svg";
import chevron_right_24 from "../../assets/icons/chevron_right.svg";
import HomeStore from "../../store/HomeStore";
import {thumbAvatar} from "../../modules/utils";
import Skeleton from "../../components/Skeleton";

interface Props {
}

const MyAIPage: FC<Props> = observer(() => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [models, setModels] = useState<IModel[]>();

  useEffect(() => {
    if (AppStore.ready) {
      if (AppStore.user?.id) {
        fetch();
      } else {
        setReady(true);
      }
    }
  }, [AppStore.ready]);

  const fetch = async () => {
    try {
      setLoading(true);
      const res = await API.Models.getList({preset: 'my', limit: 1000}, [EFieldGroup.ModelFull]);
      setModels(res.data)
    } catch (e: any) {
      toast.error(e);
    } finally {
      setLoading(false);
      setReady(true);
    }
  }

  const handleAnswer = async (id: number) => {
    try {
      const res = await API.Models.startConversation(id);
      navigate(`/conversation/${res.id}`);
    } catch (e) {

    }
  }


  return (
    <>
      <HeaderSecondary
        title={'MY_AI'}
        LeftComponent={
          <Button
            href={'/gallery'}
            size='sm'
            btnType='secondary'
            contentClassName='ps-2 pe-1'
          >
            <ReactSVG src={image_white} className='react-icon'/>
            <ReactSVG src={chevron_right} className='react-icon'/>
          </Button>
        }
        RightComponent={
          <Button
            btnIcon
            href={'/create-ai'}
            size='sm'
            icon={plus}
            btnType='secondary'
          />
        }
      />
      <main className='my__ai_page'>
        <div className='container px-4'>
          <Link to='/gallery' className="my__ai_gallery">
            <div className='my__ai_gallery-users'>
              {HomeStore.models?.length
                ?
                <>
                  <img src={thumbAvatar(HomeStore.models[0]?.image?.id, 176)} alt="model"
                       className="my__ai_gallery-item"/>
                  <img src={thumbAvatar(HomeStore.models[1]?.image?.id, 176)} alt="model"
                       className="my__ai_gallery-item"/>
                  <img src={thumbAvatar(HomeStore.models[2]?.image?.id, 176)} alt="model"
                       className="my__ai_gallery-item"/>
                </>
                :
                null
              }
            </div>
            <div className='flex-1'>{t('GALLERY')}</div>
            <ReactSVG src={chevron_right_24} className='react-icon'/>
          </Link>
          <div className='justify-content-between align-items-end mb-4 d-none d-lg-flex'>
            <h2 className='page-title text-accent mb-0'>{t('MY_AI')}</h2>
            <Link to='/create-ai' className='text-accent btn__user-animate'>
              <div className='btn__user_animate-icon'>
                <ReactSVG src={ellipse} className='btn__user_animate-icon-ellipse'/>
                <ReactSVG src={ellipse} className='btn__user_animate-icon-ellipse'/>
                <ReactSVG src={user} className='react-icon'/>
              </div>
              <span>{t('CREATE_YOUR_AI')}</span>
            </Link>
          </div>
          <div className="row g-3 g-lg-4">
            <div className='col-6 col-md-4 col-lg-4 col-xl-3'>
              <Link to='/create-ai' className='my__ai_item item__new'>
                <div className='my__ai_animate'>
                  <ReactSVG src={ellipse_big} className='my__ai_animate-ellipse'/>
                  <ReactSVG src={ellipse_big} className='my__ai_animate-ellipse'/>
                  <ReactSVG src={user_big} className='my__ai_animate-user'/>
                </div>
                <div className='align-self-end text-center'>{t('CREATE_YOUR_AI')}</div>
              </Link>
            </div>
            {ready && models
              ?
              models.map((model) => (
                <div className='col-6 col-md-4 col-lg-4 col-xl-3' key={model.id}>
                  <ModelCard
                    className='my__ai_item'
                    onClick={() => handleAnswer(model.id)}
                    model={model}
                    // category={model.style}
                    imageSize={800}
                    chatBtn
                  />
                </div>
              ))
              :
              Array(7).fill({}).map((_, i) => (
                <div className='col-6 col-md-4 col-lg-4 col-xl-3' key={i}>
                  <Skeleton/>
                </div>
              ))
            }
          </div>
        </div>
      </main>
    </>
  );
})

export default MyAIPage;