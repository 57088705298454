import React, { FC, useLayoutEffect, useState } from 'react';
import { observer } from 'mobx-react';
import ChatsList from './components/ChatsList';
import Chat from './components/Chat';
import AppStore from '../../store/AppStore';
import { useNavigate  } from 'react-router-dom';
import ChatStore from '../../store/ChatStore';
import HomeList from '../HomePage/components/HomeList';
import { API } from '../../modules/api';
import { IModel } from '../../modules/rest';
import { runInAction, toJS } from 'mobx';
import { ModelProfile } from '../ModelProfilePage';
import { toast } from 'react-toastify';
import { HeaderSecondary } from '../../containers/Header';

interface Props {
  chatPageType: 'list' | 'chat' | 'profile';
}

const ChatPage: FC<Props> = observer(({ chatPageType }) => {
  const navigate = useNavigate();
  const [profileVisible, setProfileVisible] = useState(false);

  useLayoutEffect(() => {
    if (AppStore.ready) {
      init();
    }
    return () => {
      runInAction(() => {
        ChatStore.activeChat = undefined;
        ChatStore.messages = [];
      });
    };
  }, [AppStore.ready]);

  const init = async () => {
    try {
      ChatStore.getList();
    } catch (e) {

    }
  };

  const handleProfileClick = (e: any) => {
    e.stopPropagation();
    if (window.innerWidth < 992) {
      navigate(`/model/${ChatStore.activeChat?.id}`, { state: { conversation: toJS(ChatStore.activeChat) } });
    } else {
      setProfileVisible(prevState => !prevState);
    }
  };

  const handleAnswer = async (model: IModel) => {
    try {
      const res = await API.Models.startConversation(model.id);
      navigate(`/conversation/${res.id}`);
      await init();
    } catch (e: any) {
      toast.error(e);
    }
  };


  const handleBack = () => {
    runInAction(() => {
      ChatStore.activeChat = undefined;
      ChatStore.messages = [];
      if (!AppStore.user?.id) ChatStore.chats = [];
    });
    navigate('/');
  };

  if (!ChatStore.ready) return null;

  if (!ChatStore.chats?.length) return (
    <>
      <HeaderSecondary title='CHOOSE_AI' coins />
      <main>
        <HomeList onAnswer={handleAnswer} className='mt-0 mb-3 mb-md-4' title='CHOOSE' titleClassName={'page-title'} />
      </main>
    </>
  );

  return (
    <>

      <main className='main-chat'>
        <div className={`chat-page chat-page-type-${chatPageType}${profileVisible ? ' profile_visible' : ''}`}>
          <ChatsList className='chats-section' />
          <Chat onProfileClick={handleProfileClick} chatPageType={chatPageType} />
          <div className={`chat-profile chats-section`}>
            {ChatStore.activeChat?.id
              ?
              <ModelProfile onHideChatProfile={() => setProfileVisible(false)} conversation={ChatStore.activeChat} />
              :
              null
            }
          </div>
        </div>
      </main>
    </>
  );
});

export default ChatPage;