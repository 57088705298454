import React, { FC, ImgHTMLAttributes } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface Props extends ImgHTMLAttributes<any> {
  preloadSrc?: string;
  lazy?: boolean;
}

const ImageComponent: FC<Props> = ({ src, preloadSrc, className, lazy, ...props }) => {
  return <>
    {preloadSrc ? <img src={preloadSrc} alt='img' {...props} className={`${className || ''} preload-image`} /> : null}
    {lazy
      ?
      <LazyLoadImage src={src} alt='img' {...props} className={className || ''} />
      :
      <img src={src} alt='img' {...props} className={className || ''} />
    }
  </>;
};

export default ImageComponent;