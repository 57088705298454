import React, { FC } from 'react';
import { ReactSVG } from 'react-svg';
import chevron_right from '../../../assets/icons/chevron_right.svg';
import check from '../../../assets/icons/check.svg';
import edit_svg from '../../../assets/icons/edit.svg';
import { useTranslation } from 'react-i18next';

interface Props {
  active?: boolean;
  edit?: boolean;
  className?: string;
  size?: 'small' | 'xs';
  children: any;
  titleComponent?: any;
  onClick: () => void;
  title?: string;
}

const StepItem: FC<Props> = ({
                               active,
                               className,
                               children,
                               onClick,
                               size,
                               edit,
                               title,
                               titleComponent,
                             }) => {
  const { t } = useTranslation();
  return (
    <div className={`create__ai_item${active ? ' active' : ''} ${size || ''} ${className || ''}`} onClick={onClick}>
      {children}

      <div className='letter-uppercase create__ai_item-footer'>
        {title ? <div className='d-grid overflow-hidden'><span className='text-truncate'>{t(title)}</span></div> : null}
        {titleComponent ?
          <div className='d-grid overflow-hidden'><span className='text-truncate'>{titleComponent}</span></div> : null}
        {edit
          ?
          <ReactSVG src={edit_svg} className='react-icon create__ai_item-edit' />
          :
          <ReactSVG src={active ? check : chevron_right} className='create__ai_item-arrow react-icon' />
        }
      </div>


    </div>
  );
};

export default StepItem;