import React, {FC, useState} from 'react';
import more from '../../../assets/icons/more.svg';
import broom from '../../../assets/icons/broom.svg';
import trash from '../../../assets/icons/trash.svg';
import generate from '../../../assets/icons/generate.svg';
import {ReactSVG} from "react-svg";
import {toast} from "react-toastify";
import {Link, useNavigate} from "react-router-dom";
import {runInAction, toJS} from "mobx";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {confirmDialog} from "../../../modals";
import ChatStore from "../../../store/ChatStore";
import {API} from "../../../modules/api";
import OutsideClick from "../../../components/OutsideClick";
import AppStore from "../../../store/AppStore";
import {IConversation} from "../../../modules/rest";

interface Props {
  fromModelProfile?: boolean;
  conversation?: IConversation
}

const ProfileSettings: FC<Props> = observer(({conversation, fromModelProfile}) => {
  const {t} = useTranslation();
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false);

  const handleClean = async () => {
    setVisible(false);
    const confirmed = await confirmDialog(t('Confirm_message_clear_chat'), {
      title: t('CLEAR_CHAT') || '',
      icon: broom,
      confirmText: 'CLEAR',
    })
    if (confirmed) {
      try {
        await API.Conversations.resetConversation(conversation?.id!);
        await ChatStore.getList();
        await ChatStore.getMessages(conversation?.id!);
      } catch (e: any) {
        toast.error(e);
      }
    }
  }

  const handleRemove = async () => {
    setVisible(false);
    const confirmed = await confirmDialog(t('Confirm_message_delete_room'), {
      title: t('DELETE_CHAT')!,
      icon: trash,
      confirmText: 'DELETE',
    })
    if (confirmed) {
      try {
        await API.Conversations.deleteConversation(conversation?.id!);
        await ChatStore.getList();
        const lastChat = ChatStore.chats[0];
        if (lastChat && !fromModelProfile) {
          await ChatStore.getChat(lastChat.id);
          navigate(`/conversation/${lastChat.id}`);
        } else {
          runInAction(() => {
            ChatStore.messages = [];
            ChatStore.activeChat = undefined;
          })
          navigate('/conversation');
        }
      } catch (e: any) {
        toast.error(e);
      }
    }

  }


  const toggleVisible = (e: any) => {
    e.stopPropagation()
    setVisible(prevState => !prevState);
  }

  if (!AppStore.user) return null;

  return (
    <OutsideClick outsideClickEvent={() => setVisible(false)} className='model-profile-more'>
      <div className={`popover${visible ? ' visible' : ''}`}>
        <ReactSVG src={more} className='react-icon model-profile-more-control' onClick={toggleVisible}/>
        <div className="popover-panel-container">
          <div className="popover-panel text-nowrap">
            <Link to={'/request-photo'} state={{model: toJS(conversation?.model)}}
                  className='popover-panel-item'>
              <ReactSVG src={generate} className='react-icon me-2'/>
              <span>{t('GENERATE_IMAGE')}</span>
            </Link>
            <div className='popover-panel-item' onClick={handleClean}>
              <ReactSVG src={broom} className='react-icon me-2'/>
              <span>{t('CLEAR_CHAT')}</span>
            </div>
            <div className='popover-panel-item' onClick={handleRemove}>
              <ReactSVG src={trash} className='react-icon me-2'/>
              <span className='text-danger'>{t('DELETE_CHAT')}</span>
            </div>
          </div>
        </div>
      </div>
    </OutsideClick>
  );
})

export default ProfileSettings;