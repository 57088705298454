import React, {FC, Fragment} from 'react';

interface Props {
  step: number;
  len: number;
  onClick: (idx: number) => void;
}

const StepNumber: FC<Props> = ({step, onClick, len}) => {

  const handleClick = (idx: number) => () => {
     onClick(idx);
  }

  const getClass = (idx: number, className: string) => {
    let addClass = '';
    if (idx === step) addClass = '-current';
    else if (idx < step) addClass = '-complete'
    return className + addClass;
  }

  return (
    <div className='create__ai_steps'>
      {Array(len).fill({}).map((_, i) => (
        <div
          className={`create__ai_step ${getClass(i, 'create__ai_step')}`}
          key={i}
          onClick={handleClick(i)}
        />
      ))}
    </div>
  );
}

export default StepNumber;