import asyncModal from 'react-async-modal';
import {ReactSVG} from "react-svg";
import React, {FC, useEffect, useRef} from "react";
import close_svg from "../../assets/icons/close.svg";
import {observer} from "mobx-react";
import {API} from "../../modules/api";
import {EPaymentStatus} from "../../modules/rest";
import {toast} from "react-toastify";


interface ConfirmModalProps {
  resolve(): void;
  url: string;
  id: string;
}

// const mochaUrl = 'https://pay.aeunleash.pro/?payment_intent_secret=pi_3Pvy69BF3Sgiq1PU197aKyrm_secret_LN1mh7Tw7fFUWIFPWdFbSi6Qi&publishable_key=pk_test_51Plp0NBF3Sgiq1PUYve4npn8XGxQ9e533BFaFvzam882reZ3Up8ztWALo0zxoIi309RwZUgQAMWdpVWsTiehK9Ir00rmRowzTa'

const PaymentIframeModal: FC<ConfirmModalProps> = observer(({resolve, url, id}) => {
  const _ping: any = useRef(null);

  useEffect(() => {
    _ping.current = setInterval(pingStatus, 2000);
    return () => {
      stopPing()
    }
  }, []);

  const stopPing = () => {
    clearInterval(_ping.current);
  }

  const pingStatus = async () => {
    try {
      const res = await API.Subscriptions.getPayment(id);
      if (res.status === EPaymentStatus.Success) {
        stopPing();
        handleClose();
      } else if (res.status === EPaymentStatus.Failed) {
        stopPing();
      }
    } catch (e:any) {
      toast.error(e);
      stopPing();
      handleClose();
    }
  }

  const handleClose = () => {
    resolve();
  }

  return (
    <div className='modal__container payment__iframe_modal-container'>
      <ReactSVG src={close_svg} className='modal-close' onClick={handleClose}/>
      {/*<h4 className='modal__title justify-content-center'>{t('PAYMENT')}</h4>*/}
      <iframe src={url} className='payment__iframe_modal'/>
    </div>
  );
});

const openPaymentIframeModal = (props: { url: string, id: string }): Promise<boolean> => {
  return asyncModal(PaymentIframeModal, props, {
    showCloseIcon: false,
    center: true,
    classNames: {modalContainer: 'modal__bottom'}
    // animationDuration: 0,
  });
};

export {openPaymentIframeModal};
