import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {ELanguage} from "./modules/rest";
import en from './translates/en/translation.json';
import ru from './translates/ru/translation.json';
import es from './translates/es/translation.json';

import moment from "moment";
import 'moment/locale/ru'
import 'moment/locale/es'
import 'moment/locale/en-gb'


export const availableLanguages = Object.values(ELanguage);

export const getLang = (ln) => {
  if (!ln) ln = localStorage.getItem('ln') || ( window?.navigator?.userLanguage || window.navigator.language ).substring(0, 2);
  const rusList = ['ru', 'uk', 'kk', 'be', 'az', 'lv', 'ee', 'tr', 'ka', 'hy', 'uz', 'mo', 'bg'];
  if (rusList.includes(ln || '')) ln = 'ru';
  ln = availableLanguages.includes(ln) ? ln : 'en';
  moment.locale(ln);
  return ln;
}


const resources = {
  [ELanguage.English]: {
    translation: en,
  },
  [ELanguage.Russian]: {
    translation: ru,
  },
  [ELanguage.Spanish]: {
    translation: es,
  },

};


i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    lng: getLang(),

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;