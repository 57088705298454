import React, {FC} from 'react';
import {IModel, IPhoto} from "../../../modules/rest";
import {thumb, thumbAvatar} from "../../../modules/utils";
import {ReactSVG} from "react-svg";
import expand from "../../../assets/icons/expand.svg";
import trash from "../../../assets/icons/trash.svg";
import Avatar from "../../../components/Avatar";
import {openGalleryModal} from "../../../modals";
import ImageComponent from "../../../components/ImageComponent";
import {toJS} from "mobx";
import BlurComponent from "../../../components/BlurComponent";

interface Props {
  item: IPhoto;
  onRemove: (e: any) => void;
  onChangeModel: (model: IModel) => void;
}

const GalleryItem: FC<Props> = ({item, onRemove, onChangeModel}) => {

  const handleOpenGallery = (e: any) => {

    openGalleryModal({image: item.photo, options: ['remove', 'allPhotos'], model: item.model}).then(res => {
      if (res === 'remove') {
        onRemove(e);
      } else if(res === 'allPhotos') {
        onChangeModel(item.model!);
      }
    })
  }

  return (
    <div className='col-6 col-sm-4'>
      <BlurComponent
        contentContainerClassName='gallery__item_container'
        isNsfw={item?.photo?.isNsfw}
        className='gallery__item btn-expand-container btn-trash-container'
        onClick={handleOpenGallery}
      >
        <ImageComponent
          src={thumb(item.photo.id, 800)}
          preloadSrc={thumb(item.photo.id, 50)}
          alt="img"
          className='gallery__item_photo'
        />
        <ReactSVG src={expand} className='react-icon btn-expand'/>
        <ReactSVG src={trash} className='react-icon btn-trash' onClick={onRemove}/>
        <div className="gallery__item_model">
          <Avatar image={thumbAvatar(item.model?.image?.id, 176)} size='sm'/>
          <div className='gallery-item-name text-medium'>
            {item.model?.firstName} <span className='text-dark'> {item.model?.age}</span>
          </div>
        </div>
      </BlurComponent>
    </div>
  );
}

export default GalleryItem;