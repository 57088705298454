import React, {FC, useEffect, useLayoutEffect, useState} from 'react';
import {EFieldGroup, ICreateModelExtraPhotosTask, ICreateModelTask, IGetTasksRequest, IModel} from '../../modules/rest';
import {useTask} from '../../hooks/useTask';
import create_ai from '../../assets/icons/create_ai.svg';
import {Route, Routes, useNavigate, useParams} from 'react-router-dom';
import GenerateModel from './pages/GenerateModel';
import GenerateImage from './pages/GenerateImage';
import {HeaderSecondary} from '../../containers/Header';
import {useTranslation} from 'react-i18next';
import {ReactSVG} from 'react-svg';
import GenerateImageTitle from './components/GenerateImageTitle';

type TTask = ICreateModelExtraPhotosTask|ICreateModelTask;

interface Props {
}

const GeneratePage: FC<Props> = () => {
  const {t} = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const generateType = params['*'] as IGetTasksRequest['type'];

  const [showResult, setShowResult] = useState(false);

  useLayoutEffect(() => {
    if (generateType !== 'model' && generateType !== 'photo') {
      navigate('/', {replace: true});
    }
  }, []);


  const [task, generatingAILoading, taskReady, getTasks] = useTask<TTask>({
    type: generateType,
    fields: [EFieldGroup.ModelFull, EFieldGroup.PhotoModel]
  });

  useEffect(() => {
    let timout: any;
    if (generatingAILoading === 'finished') {
      timout = setTimeout(() => setShowResult(true), 1500);
    }
    return () => {
      clearTimeout(timout);
    };
  }, [generatingAILoading]);


  useLayoutEffect(() => {
    if (taskReady && !task) {
      if (generateType === 'photo') {
        navigate('/request-photo', {replace: true});
      } else if (generateType === 'model') {
        navigate('/companions');
      }
    }
  }, [taskReady]);

  const handleReGenerate = () => {
    setShowResult(false);
    getTasks();
  }

  const finished = !generatingAILoading || generatingAILoading === 'finished';


  return (
    <>
      <HeaderSecondary
        gender
        title={generateType === 'model' ? 'CREATE_MY_AI' : ''}
        coins className='generate__page_header'
      >
        {generateType === 'photo' ?
          <GenerateImageTitle
            taskId={task?.id}
            finished={finished}
            showResult={showResult}
            className='generate__image_title'
          />
          : null
        }
      </HeaderSecondary>
      {(taskReady && task) &&
        <main className={`container generate__page generate__page_${generateType}`}>
          <div className='blur__bg_container'>
            <ReactSVG src={create_ai} className='react-icon mb-2 generate__page_icon'/>
            {generateType === 'photo'
              ?
              <GenerateImageTitle
                finished={finished}
                showResult={showResult}
                className='page-title text-accent generate__image_title'
              />
              :
              <h2 className='page-title text-accent'>{t('CREATE_MY_AI')}</h2>
            }
            <Routes>
              <Route path='model'
                     element={<GenerateModel
                       showResult={showResult}
                       finished={finished}
                       task={task as ICreateModelTask}/>}
              />
              <Route path='photo'
                     element={<GenerateImage
                       showResult={showResult}
                       getTasks={handleReGenerate}
                       finished={finished}
                       task={task as ICreateModelExtraPhotosTask}/>}/>
            </Routes>
          </div>
        </main>
      }
    </>
  );
};

export default GeneratePage;