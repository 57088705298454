import React, {FC, HTMLProps} from 'react';
import {observer} from "mobx-react";
import AppStore from "../store/AppStore";
import {ESubscriptionStatus} from "../modules/rest";
import {openModalNoMoney} from "../modals";
import Button from "./Button";
import {useLocation, useNavigate} from "react-router-dom";
import crown from '../assets/icons/crown.svg';
import ChatStore from "../store/ChatStore";

interface Props extends HTMLProps<any> {
  isNsfw?: boolean;
  enableClick?: boolean;
  contentContainerClassName?: string;
}

const BlurComponent: FC<Props> = observer(({
                                             enableClick,
                                             className,
                                             isNsfw,
                                             onClick,
                                             contentContainerClassName,
                                             ...props
                                           }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isPrem = AppStore.user?.subscription?.status === ESubscriptionStatus.Active;

  isNsfw = isNsfw && !isPrem;

  const handleClick = (e: any) => {
    if (onClick && (!isNsfw || enableClick)) onClick(e);
  }

  const handleContainerClick = () => {
    if (isNsfw) {
      if (AppStore.user?.isAnonymous) {
        navigate('/auth', {state: {prevPathname: location.pathname}});
      } else {
        openModalNoMoney('chat').then((path) => {
          if (path) navigate(path);
        });
      }
    }
  }


  if (!AppStore.ready) return null;

  return (
    <div className={`${contentContainerClassName || ''}${isNsfw ? ' blur-container' : ''}`}
         onClick={handleContainerClick}>
      <div className={`${className || ''}${isNsfw ? ' blur-content' : ''}`} onClick={handleClick} {...props}>
        {props.children}
      </div>
      {isNsfw &&
        <Button
          size='sm'
          className='blur-btn'
          icon={crown}
          title={'PREMIUM'}
        />
      }
    </div>
  );
})

export default BlurComponent;