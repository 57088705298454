import React, {FC} from 'react';
import {IModel} from "../../../modules/rest";
import {useNavigate} from "react-router-dom";
import Button from "../../../components/Button";
import user_svg from '../../../assets/icons/user-btn.svg';
import refresh_active from '../../../assets/icons/refresh_active.svg';
import {openChooseModelModal} from "../../../modals";
import Avatar from "../../../components/Avatar";
import {thumbAvatar} from "../../../modules/utils";
import {ReactSVG} from "react-svg";
import {useTranslation} from "react-i18next";
import ellipse from '../../../assets/icons/ellipse.svg';
import user from '../../../assets/icons/user-btn.svg';

interface Props {
  model?: IModel;
  onChange: (model: IModel) => void;
}

const GenerateModel: FC<Props> = ({model, onChange}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    openChooseModelModal(model).then(model => {
      if (model) onChange(model);
    });
  }

  if (!model) return (
    <div className='d-flex flex-column flex-md-row gap-2 gap-lg-4'>
      <Button
        className='flex-1'
        title='EXISTING_CHARACTER'
        btnType='secondary'
        icon={user_svg}
        iconAbsolute
        onClick={handleClick}
      />
      <Button href={'/create-ai'} btnType='secondary' className='btn__user-animate'>
        <div className='btn__user_animate-icon ms-2 ps-1'>
          <ReactSVG src={ellipse} className='btn__user_animate-icon-ellipse'/>
          <ReactSVG src={ellipse} className='btn__user_animate-icon-ellipse'/>
          <ReactSVG src={user} className='react-icon'/>
        </div>
        <div className='text-center w-100'>{t('CREATE_NEW_CHARACTER')}</div>
      </Button>
    </div>
  )
  return (
    <div className='generate__image_model' onClick={handleClick}>
      <Avatar size='sm' image={thumbAvatar(model.image?.id, 176)}/>
      <div className='ps-2 ms-1 pe-3 flex-1'>{model.name}</div>
      <div className='d-flex align-items-center cursor-pointer' >
        <ReactSVG src={refresh_active} className='react-icon' />
        <div className='text-accent text-14 ps-1'>{t('CHANGE')}</div>
      </div>
    </div>
  );
}

export default GenerateModel;