import React, {FC, useEffect} from 'react';
import {Spinner} from "../../../components/Loadable";
import {HeaderSecondary} from "../../../containers/Header";
import {useNavigate, useParams} from "react-router-dom";
import {observer} from "mobx-react";
import AppStore from "../../../store/AppStore";
import {API} from "../../../modules/api";
import {toast} from "react-toastify";
import ChatStore from "../../../store/ChatStore";

interface Props {
}

const ChatCreateConversation: FC<Props> = observer(() => {
  const navigate = useNavigate();
  const params: any = useParams()
  useEffect(() => {
    if (AppStore.ready && AppStore.user?.id) {
      startChat();
    }
  }, [AppStore.ready, AppStore.user?.id]);

  const startChat = async () => {
    try {
      const res = await API.Models.startConversation(params?.modelId);
      await ChatStore.getList();
      navigate(`/conversation/${res.id}`, {replace: true});
    } catch (e: any) {
      toast.error(e);
      navigate(`/`, {replace: true});
    }
  };

  return (
    <>
      <HeaderSecondary title={'CHAT'} onBack={() => navigate('/', {replace: true})}/>
      <main className='align-items-start'>
        <div className='w-100'>
          <Spinner loading/>
        </div>
      </main>
    </>
  );
})

export default ChatCreateConversation;