import React, {FC} from 'react';
import {IModel} from '../../../modules/rest';
import {thumbAvatar} from '../../../modules/utils';
import {observer} from 'mobx-react';
import Avatar from '../../../components/Avatar';
import ModelCard from '../../../components/ModelCard';
import NameAge from "../../../components/NameAge";
import {useTranslation} from "react-i18next";
import line from '../assets/images/line.svg';
import arrow from '../../../assets/icons/arrow_tr.svg';
import {ReactSVG} from "react-svg";

interface Props {
  item: IModel;
  onClick?: (e: any) => void;
  onChat?: () => void;
  active?: boolean;
  disableCardClick?: boolean;
  idx?: number;
  onHover?: () => void;
  onBlur?: () => void;
}

const TopCarouselItem: FC<Props> = observer(({
                                               item,
                                               onClick,
                                               onChat,
                                               active,
                                               idx,
                                               onHover,
                                               onBlur,
                                               disableCardClick,
                                             }) => {
  const {t} = useTranslation();

  return (
    <div
      onMouseOver={onHover}
      onMouseOut={onBlur}
      className={`top-carousel-item${active ? ' active' : ''} 
      item__position_${idx || 0}`}
      onClick={onClick}
    >
      <div className='position-relative'>
        <ModelCard
          model={item}
          chatBtn
          disableCardClick={disableCardClick}
          onClick={onChat}
        >
          <div className='top-carousel-item-avatar'>
            <Avatar image={thumbAvatar(item.image?.id, 176)} size='xs'/>
            <ReactSVG src={line} className='top-carousel-item-avatar-line'/>
          </div>
          <div className='text-12 model-card-about-welcome'>
            {item.welcomeMessageLocal} <span onClick={onChat}
                                             className='text-accent cursor-pointer'>{t('ANSWER')}</span>
          </div>
          <div className='model-online'/>
        </ModelCard>
      </div>
      <div className='top-carousel-item-footer'>
        <Avatar image={thumbAvatar(item.image?.id, 176)} size='xs'/>
        <div className='carousel__item_footer-top'>
          <NameAge age={item.age} name={item.firstName} className='ms-2'/>
          <ReactSVG src={arrow} className='react-icon d-flex d-lg-none'/>
        </div>
        <div className='d-flex align-items-center mt-1 mt-lg-0'>
          <div className='model-online'/>
          <div className='carousel__item_footer-message'>{t('NEW_MESSAGE')}</div>
        </div>
      </div>
    </div>
  );
});

export default TopCarouselItem;