import React, {FC} from 'react';
import {ReactSVG} from "react-svg";
import done from "../../../../../assets/icons/check.svg";
import {useTranslation} from "react-i18next";

interface Props {
  title: string;
  data: string[];
}

const PremiumBenefits: FC<Props> = ({title, data}) => {
  const {t} = useTranslation();
  return (
    <div className="premium__benefits">
      <div className='text-medium text-center'>{t(title)}</div>
      <div className='d-flex flex-wrap justify-content-center pt-1 mt-2'>
        {data.map((item, i) => (
          <div className="premium__benefits_item" key={i}>
            <ReactSVG className='react-icon me-2' src={done}/>
            <div className='text-dark-14'>{t(item)}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PremiumBenefits;