import React, {FC} from 'react';
import asyncModal from "react-async-modal";
import close from "../assets/icons/close.svg";
import crown from "../assets/icons/crown.svg";
import {ReactSVG} from "react-svg";
import {thumb} from "../modules/utils";
import HomeStore from "../store/HomeStore";
import {observer} from "mobx-react";
import AppStore from "../store/AppStore";
import {ESubscriptionStatus} from "../modules/rest";
import Slider from "react-slick";
import {useTranslation} from "react-i18next";
import Button from "../components/Button";

const dataTitlesCoins = {
  chat: {title: 'NO_MONEY_TITLE_4', text: 'NO_MONEY_TEXT_4', btnText: 'NO_MONEY_BTN_4'},
  ['create-ai']: {title: 'NO_MONEY_TITLE_3', text: 'NO_MONEY_TEXT_3', btnText: 'NO_MONEY_BTN_3'},
}
const dataTitlesPremium = {
  chat: {title: 'NO_MONEY_TITLE_2', text: 'NO_MONEY_TEXT_2', btnText: 'UPDATE_TO_PREM'},
  ['create-ai']: {title: 'NO_MONEY_TITLE_1', text: 'NO_MONEY_TEXT_1', btnText: 'GO_PREM'},
}

interface Props {
  resolve: (path?: string) => void;
  position: 'create-ai'|'chat';
  imageId?: string;
}

const ModalNoMoney: FC<Props> = observer(({position, imageId, resolve}) => {
  const {t} = useTranslation();

  const settings = {
    dots: false,
    pauseOnHover: false,
    infinite: true,
    arrows: false,
    speed: 5000,
    autoplaySpeed: 0,
    slidesToShow: 4,
    animation: 'linear',
    // slidesToShow: 3,
    slidesToScroll: 1,
    swipe: false,
    // centerPadding: '124px',
    // centerMode: true,
    autoplay: true,
  };

  const isPrem = AppStore?.user?.subscription?.status === ESubscriptionStatus.Active;

  const handleClose = () => {
    resolve()
  }
  const handleClick = () => {
    if (isPrem) {
      resolve('/premium/stars');
    } else {
      resolve('/premium/subscription');
    }
  }


  const dataTitles = (isPrem ? dataTitlesCoins : dataTitlesPremium)[position];

  return (
    <div className='modal__container modal__payment'>
      <ReactSVG src={close} className='modal-close' onClick={handleClose}/>
      <Slider {...settings} className='modal__payment_slider animate__show'>
        {HomeStore.randomModels?.map((model, i) => (
          <div className={`modal__payment_slider-item item__${i % 2}`} key={model.id}>
            <img src={thumb(model?.image?.id, 800)} alt={model.firstName}/>
          </div>
        ))}
      </Slider>
      <div className='modal__payment_content'>
        <div className='position-relative'>
          <h4 className='text-center' dangerouslySetInnerHTML={{__html: t(dataTitles.title) || ''}}/>
          <div className='text-medium mt-3 text-center mx-auto' style={{maxWidth: 380}}>{t(dataTitles.text)}</div>
          <Button
            btnType={isPrem ? 'white' : 'primary'}
            onClick={handleClick}
            iconAbsolute
            className='w-100 modal-footer'
            iconText={isPrem ? '⭐' : ''}
            icon={!isPrem ? crown : null}
            title={dataTitles.btnText}
          />
        </div>
      </div>
    </div>
  );
})

const openModalNoMoney = (position: 'create-ai'|'chat', imageId?: string): Promise<string> => {
  return asyncModal(ModalNoMoney, {position, imageId}, {
    showCloseIcon: false,
    center: true,
    classNames: {modalContainer: 'modal__bottom'}
  });
};

export {openModalNoMoney};