import React, {FC} from 'react';
import { ICreateModelRequest} from "../../../modules/rest";
import {observer} from "mobx-react";
import {
  preloadSrcHairStyle
} from "../assets/modules/utils";
import PreloadImages from "../../../components/PreloadImages";
import StepItem from "./StepItem";

interface Props {
  onStepChange: (data?: Partial<ICreateModelRequest>) => void;
  data?: Partial<ICreateModelRequest>;
}

const Step3Age: FC<Props> = observer(({onStepChange, data}) => {
  const handleStepChange = (age: ICreateModelRequest['age']) => () => {
    onStepChange({age});
  }

  return (
    <>
      <PreloadImages images={preloadSrcHairStyle}/>
      <div className='gap-2 gap-lg-3 d-flex flex-wrap justify-content-center create__ai_step-age'>
        {Array(21).fill({}).map((_, i) => (
          <StepItem size='xs'
                    onClick={handleStepChange(i + 20)}
                    key={i}
                    active={i + 20 === data?.age}>
            <span>{i + 20}</span>
          </StepItem>
        ))}
      </div>
    </>
  );
})

export default Step3Age;