import { ReactSVG } from 'react-svg';
import React, { FC, useEffect, useRef, useState } from 'react';
import close_svg from '../../assets/icons/close.svg';
import check from '../../assets/icons/check.svg';
import fire_active from '../../assets/icons/fire_active.svg';
import { observer } from 'mobx-react';
import { PhotoInterract } from '../../pages/ChatPage/components/Chat/Chat';
import Avatar from '../../components/Avatar';
import { API } from '../../modules/api';
import Modal from 'react-responsive-modal';
import Progress from '../../components/Progress';
import Button from '../../components/Button';
import { thumbAvatar } from '../../modules/utils';

const mochaData: any = {
  'event': 'show_photo_request',
  'conversation_id': 6,
  'interract_id': 'p4MYecPAD9n3lNtv',
  'message': 'Sure, I can send you a photo. Which of these options would you like to see?',
  'options': [
    {
      'option': 'A playful pose with a hint of flirtation.',
      'prompt': 'Model winking with a teasing smile',
      'pose': 'Winking',
      'stars': 2
    },
    {
      'option': 'An intimate moment just before things get steamy.',
      'prompt': 'Model in a just before sex pose',
      'pose': 'Just Before Sex',
      'stars': 4
    },
    {
      'option': 'A bold and passionate embrace, showing off my curves.',
      'prompt': 'Model in a POV missionary pose',
      'pose': 'POV Missionary',
      'stars': 6
    }
  ]
};


interface Props {
  data?: PhotoInterract;
  onClose: () => void;
}

const ChatImageConfirmModal: FC<Props> = observer(({ onClose, data }) => {
  const _timer: any = useRef(null);
  const [timer, setTimer] = useState(100);

  useEffect(() => {
    if (data) {
      setTimer(100);
      startTimer();
    }
    return () => {
      clearTimeout(_timer.current);
    };
  }, [data]);

  const startTimer = () => {
    _timer.current = setTimeout(() => {
      setTimer(prevState => {
        if (prevState < 1) {
          handleClose();
          return 0;
        } else {
          startTimer();
        }
        return prevState - 1;
      });
    }, 300);
  };

  const onConfirmImageClick = (option: any) => async () => {
    try {
      onClose();
      await API.Interract.send(data?.interract_id!, { data: option });
    } catch (e) {

    }
  };

  const handleClose = () => {
    API.Interract.send(data?.interract_id!, { data: false });
    onClose();
  };

  return (
    <Modal
      open={Boolean(data)}
      onClose={handleClose}
      center
      showCloseIcon={false}
      blockScroll={false}
      // animationDuration={0}
      classNames={{ modalContainer: 'modal__bottom' }}
    >
      <div className='modal__container modal__chat__image_confirm'>
        <ReactSVG src={close_svg} className='modal-close' onClick={handleClose} />
        <div className='modal__chat__image_confirm-head'>
          <Avatar image={thumbAvatar(data?.model?.image?.id, 176)} />
          <div className='w-100 ms-2'>
            <h5>{data?.model?.name}</h5>
            <div className='modal__chat_image_request-message'>
              <div>{data?.message}</div>
              <Progress progress={timer} light sm star />
            </div>
          </div>
        </div>
        <div>
          {data?.options?.map((option, i) => (
            <div className='card__active_16 modal__chat_image_request-option' key={i}
                 onClick={onConfirmImageClick(option)}>
              <div className='d-flex align-items-center'>
                <div className='pe-3 flex-1'>
                  {option.option}
                </div>
                <div className='d-flex align-items-center'>
                  <div className='text-medium'>{option.stars} ⭐</div>
                  <ReactSVG src={check} className='react-icon modal__chat_image_request-check' />
                </div>
              </div>
              <div className='d-flex align-items-center mt-2'>
                <div className='text-14 text-accent'>{option.pose}</div>
                {i === 1 && <ReactSVG src={fire_active} className='react-icon ms-1' />}
                {i === 2 && <ReactSVG src={fire_active} className='react-icon ms-1 modal__chat_image_request-fire' />}
              </div>

            </div>
          ))}
        </div>
        <Button
          onClick={handleClose}
          className='modal-footer w-100'
          btnType='secondary'
          title='DONT_WANT'
        />
      </div>
    </Modal>
  );
});

export default ChatImageConfirmModal;
