import asyncModal from 'react-async-modal';
import Button from '../../components/Button';
import {ReactSVG} from "react-svg";
import React, {FC } from "react";
import close_svg from "../../assets/icons/close.svg";
import {observer} from "mobx-react";
import Avatar from "../../components/Avatar";
import {thumbAvatar} from "../../modules/utils";
import {IModel} from "../../modules/rest";
import NameAge from "../../components/NameAge";
import chat_svg from '../../assets/icons/chat-add.svg';
import generate from '../../assets/icons/generate.svg';


interface Props {
  model: IModel
  resolve(type?: 'chat'|'generate'): void;
}

const ModelClickModal: FC<Props> = observer(({resolve, model}) => {

  const handleClose = () => {
    resolve()
  }

  return (
    <div className='modal__container d-flex flex-column pt-4 align-items-center'>
      <ReactSVG src={close_svg} className='modal-close' onClick={handleClose}/>
      <Avatar image={thumbAvatar(model.image?.id, 176)} size='xl'/>
      <NameAge name={model.name} age={model.age} className='mt-3'/>
      <p className='text-center text-dark mt-3 mx-auto' style={{maxWidth: 420}}>{model.aboutLocal}</p>
      <div className='modal-footer w-100'>
        <Button
          iconAbsolute
          icon={chat_svg}
          onClick={() => resolve('chat')}
          title={'CHAT'}

        />
        <Button
          iconAbsolute
          icon={generate}
          onClick={() => resolve('generate')}
          btnType='secondary'
          title={'GENERATE_IMAGE'}
        />
      </div>
    </div>
  );
});

const openModelClickModal = (model: IModel): Promise<'chat'|'generate'|void> => {
  return asyncModal(ModelClickModal, {model}, {
    showCloseIcon: false,
    center: true,
    // animationDuration: 0,
    classNames: {modalContainer: 'modal__bottom'}
  });
};

export {openModelClickModal};
