
import poses_regular from '../jsons/poses/regular.json';
import poses_premium from '../jsons/poses/premium.json';

export type TTemplatePrompt = {
  title: string; description: string
}


export const getPosesArr = (isSub?: boolean): { regular: string[], premium: string[] } => {
  if (isSub) return { regular: [...poses_regular, ...poses_premium], premium: [] };
  return { regular: poses_regular, premium: poses_premium };
};