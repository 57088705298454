import React, {FC} from 'react';
import {observer} from 'mobx-react';
import user from '../../../assets/icons/user-btn-big.svg'
import disgusted from '../assets/images/emotion/disqusted.png'
import angry from '../assets/images/emotion/angry.png'
import ouch from '../assets/images/emotion/ouch.png'
import scared from '../assets/images/emotion/scared.png'
import shocked from '../assets/images/emotion/shocked.png'
import smiling from '../assets/images/emotion/smiling.png'
import upset from '../assets/images/emotion/upset.png'
import winking from '../assets/images/emotion/winking.png'
import StepItem from '../../CreateAIPage/components/StepItem';
import {useTranslation} from 'react-i18next';
import {ReactSVG} from 'react-svg';
import { EEmotion, EGender } from '../../../modules/rest';

const emotionData: Record<EEmotion, any> = {
  Default: undefined,
  Angry: angry,
  Upset: upset,
  Smiling: smiling,
  Disgusted: disgusted,
  Scared: scared,
  Ouch: ouch,
  Shocked: shocked,
  Winking: winking
}

interface Props {
  gender?: EGender;
  emotion?: EEmotion;
  onChange: (emotion: EEmotion) => void;
}

const GenerateImageEmotion: FC<Props> = observer(({emotion, onChange, gender = EGender.Female}) => {
  const {t} = useTranslation();

  return (
    <>
      <div className='mt-4 mb-2 text-dark-14'>{t('EMOTION')}</div>
      <div className='row g-2 g-lg-3 row-cols-lg-5 row generate__image_emotion'>
        {Object.entries(emotionData).map(([value, image]) => (
          <div className='col-6 col-sm-3 col-lg' key={value}>
            <StepItem
              size='small'
              onClick={() => onChange(value as EEmotion)}
              active={value === emotion}
              title={value.toUpperCase()}
            >
              {value === EEmotion['Default']
                ?
                <ReactSVG src={user} className='react-icon create__ai_item_image-wrap'/>
                :
                <img src={image} alt={value}/>
              }
            </StepItem>
          </div>
        ))}
      </div>
    </>
  );
});

export default GenerateImageEmotion;