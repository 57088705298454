import {backendUrl} from "./api";
import moment from "moment";
import langs from '../translates/en/langs.json'
import {ESubscriptionStatus, IUser} from "./rest";

export const addZeroToTime = (time: number) => {
  return time < 10 ? `0${time}` : time
}

export const generatePassword = (length: number = 8) => {
  let charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

export const thumb = (id: string|undefined, size: number) => {
  if (!id) return '';
  return `${backendUrl}/thumb/${size}/${id}`;
};
export const thumbAvatar = (id: string|undefined, size: number) => {
  if (!id) return '';
  return `${backendUrl}/avatar/${size}/${id}`;
};

export const formatDate = (date: string): string => {
  if (!moment(date).isValid()) return date
  return moment(date).toDate().toLocaleDateString();
};

export const getLanguageName = (ln: string): string => {
  //@ts-ignore
  const lang = langs[ln];
  if (!lang) return ln;
  return lang;
};

export const shuffleArr = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return [...array];
};

export const isSub = (user?: IUser | null): boolean => {
  if (!user) return false
  return user.subscription?.status === ESubscriptionStatus.Active;
};

export const isMobile = /iPhone|iPad|iPod|Android|ie|blackberry|fennec/i.test(navigator.userAgent.toLowerCase()) || 'ontouchstart' in window;