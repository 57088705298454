import React, {FC} from 'react';
import {ICreateModelRequest} from "../../../modules/rest";
import {observer} from "mobx-react";
import {personalityData, relationshipData} from "../assets/modules/utils";
import {useTranslation} from "react-i18next";
import StepItem from "./StepItem";
import AppStore from "../../../store/AppStore";


interface Props {
  onStepChange: (data?: Partial<ICreateModelRequest>) => void;
  data?: Partial<ICreateModelRequest>;
}

const Step11Relationship: FC<Props> = observer(({onStepChange, data}) => {
  const {t} = useTranslation();

  const handleStepChange = (relationship: ICreateModelRequest['relationship']) => () => {
    onStepChange({relationship});
  }

  return (
    <>
      <div className='row g-3'>
        {Object.entries(relationshipData).map(([value, item], i) => (
          <div className='col-6 col-md-4' key={i}>
            <StepItem
              onClick={handleStepChange(value)}
              active={data?.relationship === value}
              className='flex-column h-100'
            >
              <div className='item__personality_title'>
                <span className='me-2'>{item.icon}</span>
                <span className='text-14'>{t(item[AppStore.gender])}</span>
              </div>
            </StepItem>
          </div>
        ))}
      </div>
    </>
  );
})

export default Step11Relationship;