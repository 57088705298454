import React, {FC} from 'react';
import {observer} from "mobx-react";
import home from "../../assets/icons/home.svg";
import chat from "../../assets/icons/chat.svg";
import create from "../../assets/icons/create_ai_small.svg";
import generate from "../../assets/icons/generate.svg";
import star from "../../assets/icons/star.svg";
import {NavLink} from "react-router-dom";
import {ReactSVG} from "react-svg";
import {useTranslation} from "react-i18next";

type TMenu = { path: string, title?: string, smallTitle?: string, icon?: any, needUser?: boolean }

const menuData: TMenu[] = [
  {path: '/', title: 'HOME', icon: home, needUser: false},
  {path: '/conversation', title: 'CHAT', icon: chat, needUser: true},
  {path: '/create-ai', title: 'CREATE', icon: create, needUser: false},
  {path: '/request-photo', title: 'GENERATE', icon: generate, needUser: true},
  {path: '/companions', title: 'MY_AI', icon: star, needUser: false}
];

const Footer: FC = observer(() => {
  const {t} = useTranslation();

  return (
    <footer>
      <nav>
        <ul>
          {menuData.map((menu) => (
            <li key={menu.path}>
              <NavLink to={menu.path} className={`menu-item`}>
                <ReactSVG src={menu.icon} className='react-icon me-2'/>
                <span className='menu-item-text'>
                  <span className='text-truncate'>{t(menu.title!)}</span>
                </span>
                {/*{menu.smallTitle ? <span className='menu-item-text__small'>{t(menu.smallTitle)}</span> : null}*/}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </footer>
  );
})

export default Footer;