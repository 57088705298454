import React, {FC} from 'react';
import {IConversation} from "../../../../modules/rest";
import Avatar from "../../../../components/Avatar";
import {thumb, thumbAvatar} from "../../../../modules/utils";
import ActiveListModel from "../../../../components/ActiveListModel";

interface Props {
  data: IConversation;
  active?: boolean;
  onClick: () => void;
}

const ChatsListItem: FC<Props> = ({data, active, onClick}) => {
  return (
    <ActiveListModel onClick={onClick} active={active} model={data.model}>
      {data.lastMessage ?
        <div className='chats-list-item-message text-14'>
          {data.lastMessage.photo
            ?
            <div>
              <img src={thumb(data.lastMessage.photo?.id, 176)} alt=""/>
              <span className='ms-1'>Photo</span>
            </div>
            :
            <p className='text-truncate'>{data.lastMessage?.text}</p>
          }
        </div>
        :
        null
      }
    </ActiveListModel>
  );
}

export default ChatsListItem;