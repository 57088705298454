import React, {FC, useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react';
import {thumb, thumbAvatar} from '../../../modules/utils';
import {API} from '../../../modules/api';
import {EFieldGroup, ESortOrder, IAsset, IConversation, IPhoto} from '../../../modules/rest';
import {openChatLvlModal, openGalleryModal} from '../../../modals';
import AppStore from '../../../store/AppStore';
import {useTranslation} from 'react-i18next';
import Avatar from '../../../components/Avatar';
import ProfileSettings from './ProfileSettings';
import ModelLvlProgress from './ModelLvlProgress';
import arrow from '../../../assets/icons/double-arrow_t.svg';
import expand from '../../../assets/icons/expand.svg';
import arrow_right from '../../../assets/icons/arrow_right.svg';
import {ReactSVG} from 'react-svg';
import OutsideClick from '../../../components/OutsideClick';
import {useLocation, useNavigate} from "react-router-dom";
import {toJS} from "mobx";
import BlurComponent from "../../../components/BlurComponent";


interface Props {
  className?: string;
  canOpenPhoto?: boolean;
  conversation?: IConversation;
  onHideChatProfile?: () => void;

}

const ModelProfile: FC<Props> = observer(({
                                            className,
                                            conversation,
                                            canOpenPhoto = true,
                                            onHideChatProfile
                                          }) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const _scroll: any = useRef(null);
  const [infoVisible, setInfoVisible] = useState(false);
  const [blurVisible, setBlurVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gallery, setGallery] = useState<IPhoto[]>([]);

  const model = conversation?.model;

  useEffect(() => {
    if (model?.id && AppStore.user?.id) getGallery();
  }, [model?.id]);


  const getGallery = async () => {
    setLoading(true);
    try {
      const gallery = await API.Models.getPhotos(model?.id!, {
        limit: 5,
        order: ESortOrder.ASC
      }, [EFieldGroup.PhotoModel]);
      setGallery(gallery.data);
    } catch (e) {

    } finally {
      setLoading(false);
    }
  };

  const toggleInfoVisible = () => {
    setInfoVisible(prevState => !prevState);
  };

  const handleScroll = (e: any) => {
    setBlurVisible(Boolean(e.target.scrollTop > 0));
  };

  const handleOpenGallery = (image?: IAsset|null) => () => {
    if (!canOpenPhoto) return null;
    openGalleryModal({image, model, options: ['generate']}).then((res) => {
      if (res === 'generate') {
        navigate('/request-photo', {state: {model: toJS(model)}})
      }
    })
  };
  const handleOpenLvlModal = () => {
    openChatLvlModal(conversation!).then(path => {
      if (path) navigate(path, {state: {prevPathname: location.pathname}});
    })
  };

  // if (!model) return null;
  return (
    <OutsideClick outsideClickEvent={onHideChatProfile ? onHideChatProfile : () => {
    }} className={`model-profile${blurVisible ? ' blur-visible' : ''} ${className || ''}`}>
      <div className={`model-profile-head${infoVisible ? ' info-visible' : ''}`}>
        <div className='model-profile-head-content'>
          <ProfileSettings conversation={conversation}/>
          {onHideChatProfile
            ?
            <ReactSVG src={arrow_right} className='react-icon hide__profile_arrow' onClick={onHideChatProfile}/>
            :
            null
          }
          <div className="btn-expand-container" onClick={() => openGalleryModal({image: model?.image, model})}>
            <ReactSVG src={expand} className='react-icon btn-expand'/>
            <Avatar image={thumbAvatar(model?.image?.id, 176)} size='xl'/>
          </div>
          <h4 className='model-profile-head-text mt-3'>{model?.name}</h4>
          <div className='model-profile-head-text mt-2'>
            <span className='text-dark me-2'>{t('YEARS_OLD', {years: model?.age})}</span>
            <span className='text-accent model-profile-head-more' onClick={toggleInfoVisible}>{t('MORE')}</span>
          </div>
          <div className='model-profile-head-about-wrap'>
            <div className='model-profile-head-about'>
              <p className='mb-2 text-dark'>{model?.aboutLocal}</p>
              <span className='text-accent cursor-pointer' onClick={toggleInfoVisible}>{t('HIDE')}</span>
            </div>
          </div>
          <ModelLvlProgress
            onClick={handleOpenLvlModal}
            className='mt-4'
            active
            lvl={conversation?.currentLevel}
            progress={conversation?.progress}
          />
        </div>
      </div>
      <div className='model-profile-gallery'>
        <div className={`model-profile-gallery-title`}>
          <span className='position-relative'>{t('MEDIA')}</span>
          <ReactSVG src={arrow} className='react-icon'
                    onClick={() => _scroll?.current?.scrollTo({top: 0, behavior: 'smooth'})}/>
        </div>
        <div className='model-profile-gallery-list' onScroll={handleScroll} ref={_scroll}>
          {loading || !gallery?.length
            ?
            <BlurComponent isNsfw={model?.image?.isNsfw}
                           contentContainerClassName={'model-profile-image'}
                           className='btn-expand-container'
                           onClick={handleOpenGallery(model?.image)}>
              <ReactSVG src={expand} className='react-icon btn-expand'/>
              <img src={thumb(model?.image?.id, 800)} alt='img'/>
            </BlurComponent>
            :
            gallery.map((item) => (
              <BlurComponent isNsfw={item?.photo?.isNsfw}
                             contentContainerClassName={'model-profile-image'}
                             className='btn-expand-container' key={item.id}
                             onClick={handleOpenGallery(item.photo)}>
                <ReactSVG src={expand} className='react-icon btn-expand'/>
                <img src={thumb(item.photo.id, 800)} alt='img'/>
              </BlurComponent>
            ))}

        </div>
      </div>

    </OutsideClick>
  );
});

export default ModelProfile;