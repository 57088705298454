import React, {FC, InputHTMLAttributes, useCallback, useState} from 'react';

import {ReactSVG} from 'react-svg';
import lock_svg from '../../assets/icons/key.svg';
import eye_svg from '../../assets/icons/eye.svg';
import eyelashes_svg from '../../assets/icons/eye_no.svg';
import {useTranslation} from "react-i18next";

interface Props extends InputHTMLAttributes<any> {
  label?: string;
  className?: string;
  error?: boolean;
  onForgotPassword?: () => void;
}

const InputPassword: FC<Props> = ({
                                    label,
                                    className,
                                    onForgotPassword,
                                    error,
                                    ...props
                                  }) => {
  const {t} = useTranslation();
  const [visible, setVisible] = useState(false);

  const toggleVisible = useCallback(() => {
    setVisible(visible => !visible);
  }, []);
  return (
    <div className={`form-group${error ? ' error' : ''} ${className || ''}`}>
      <div className='d-flex justify-content-between'>
        <label className={`muted mb-2`}>
          <span>{t(label || 'PASSWORD')}</span>
          {props.required ? <span className='text-danger text-12'>*</span> : null}
        </label>
        {onForgotPassword ?
          <div className='form-password-forgot text-accent' onClick={onForgotPassword}>{t('PASSWORD_FORGOT')}</div> : null}
      </div>
      <div className='form-control-wrap'>
        <input
          className={`form-control form-control-icon form-control-password`}
          {...props}
          type={visible ? 'text' : 'password'}
          placeholder={t(props.placeholder || 'YOUR_PASSWORD') || ''}
          name={String(props?.value)}
          autoComplete='new-password'
        />
        <ReactSVG src={lock_svg} className='react-icon input-icon'/>
        <ReactSVG
          src={visible ? eye_svg : eyelashes_svg}
          className='react-icon input-pass-eye'
          onClick={toggleVisible}
        />
      </div>
    </div>
  );
};

export default InputPassword;