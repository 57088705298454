import React, {FC, useState} from 'react';
import {ReactSVG} from "react-svg";
import {useTranslation} from "react-i18next";
import chevron from '../../../assets/icons/chevron_r_16.svg';
import Progress from "../../../components/Progress";
import {openChatLvlModal} from "../../../modals";

interface Props {
  className?: string;
  active?: boolean;
  lvl?: number;
  progress?: number;
  onClick: () => void;
}

const ModelLvlProgress: FC<Props> = ({className, active, lvl, progress, onClick}) => {
  const {t} = useTranslation();

  return (
    <div className={`model-lvl${active ? ' active' : ''} ${className || ''}`} onClick={onClick}>
      <div className='d-flex align-items-center justify-content-between'>
        <div className='d-flex align-items-center'>
          <div className='text-14'>{t("LEVEL")}</div>
          <ReactSVG src={chevron} className='react-icon model-lvl-arrow'/>
        </div>

        <div className='text-14'>
          <span className='text-obscure'>{t('lvl')}</span>
          <span> {lvl || 1}</span>
          <span className='text-obscure'>/{8}</span>
        </div>
      </div>
      <Progress progress={progress || 0} className='mt-2'/>
    </div>
  );
}

export default ModelLvlProgress;