import React, {FC} from 'react';
import LegalInformationPageWrapper from "../components/LegalInformationPageWrapper";

interface Props {
}

const CookiesPolicy: FC<Props> = () => {
  return (
    <LegalInformationPageWrapper title='COOKIE_POLICY'>

    </LegalInformationPageWrapper>
  );
}

export default CookiesPolicy;