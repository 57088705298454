import React, {FC} from 'react';
import {ESender, IAsset, IMessage } from "../../modules/rest";
import Avatar from "../Avatar";
import {thumb, thumbAvatar} from "../../modules/utils";
import {ReactSVG} from "react-svg";
import image from '../../assets/icons/image.svg';
import expand from '../../assets/icons/expand.svg';
import BlurComponent from "../BlurComponent";

interface Props {

  data?: IMessage;
  typing?: boolean;
  uploading?: boolean;
  avatarId?: string;
  hideAvatar?: boolean;
  message?: string;
  className?: string;
  name?: string|null;
  onImageClick?: (photo?: IAsset|null) => void;
}

const Message: FC<Props> = ({
                              typing,
                              message,
                              className,
                              data,
                              hideAvatar,
                              avatarId,
                              uploading,
                              name,
                              onImageClick
                            }) => {
  if (typing) return (
    <div className='message-container typing'>
      <div className='text-medium-14'>{name}</div>
      <div className={`message-typing ${data?.sender || ''} ${className || ''}`}>
        <div className="message-typing-item"/>
        <div className="message-typing-item"/>
        <div className="message-typing-item"/>
      </div>
      {!hideAvatar && <Avatar image={thumbAvatar(avatarId, 176)} size={'xs'}/>}
    </div>
  )
  if (uploading) return (
    <div className='message-container uploading'>
      <div className='text-medium-14'>{name}</div>
      <div className={`message-uploading ${data?.sender || ''} ${className || ''}`}>
        <ReactSVG src={image} className='react-icon'/>
        <div className="message-uploading-progress"/>
      </div>
      {!hideAvatar && <Avatar image={thumbAvatar(avatarId, 176)} size={'xs'}/>}
    </div>
  )
  if (message) return (
    <div className={`message-container ${data?.sender || ''} ${className || ''}`}>
      <div className="message">
        <div className='message-text'>{message}</div>
      </div>

    </div>
  )
  return (
    <div
      className={`message-container${hideAvatar ? ' hide-avatar' : ''} ${data?.sender} ${className || ''}`}
      id={`message_${data?.id}`}>
      <div className="message">
        {data?.photo
          ?
          <BlurComponent
            isNsfw={data.photo.isNsfw}
            className='message-photo btn-expand-container'
            onClick={() => onImageClick!(data?.photo || null)}>
            <ReactSVG src={expand} className='react-icon btn-expand'/>
            <img src={thumb(data.photo.id, 800)} alt="img"/>
          </BlurComponent>
          : null
        }
        {data?.text ? <div className='message-text'>{data?.text}</div> : null}

      </div>
      {!hideAvatar &&
        <>
          <div className='message-name'>{name}</div>
          <Avatar image={data?.sender === ESender.Model ? thumbAvatar(avatarId, 176) : ''}
                  size={'xs'}
                  secondaryIcon
                  user={data?.sender === ESender.User}
          />
        </>
      }
    </div>
  );
}

export default Message;