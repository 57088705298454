import React, {FC} from 'react';
import {EModelStyle, ICreateModelRequest} from "../../../modules/rest";
import {observer} from "mobx-react";
import {getImageByModel} from "../assets/modules/utils";
import AppStore from "../../../store/AppStore";
import {useTranslation} from "react-i18next";
import StepItem from "./StepItem";


interface Props {
  onStepChange: (data?: Partial<ICreateModelRequest>) => void;
  data?: Partial<ICreateModelRequest>;
}

const Step1Style: FC<Props> = observer(({onStepChange, data}) => {
  const {t} = useTranslation();

  const handleStepChange = (style: EModelStyle) => () => {
    onStepChange({style});
  }

  return (
    <>
      <div className='create__ai_style'>
        {Object.values(EModelStyle).map(style => (
          <StepItem onClick={handleStepChange(style)} key={style} active={style === data?.style}>
            {/*<SuspenseImg src={getImageByModel(AppStore.gender, 'style', key)}  />*/}
            <img src={getImageByModel(AppStore.gender, 'style', style)} alt=""/>
            <div className='letter-uppercase align-self-end ms-3 text-24'>{t(style.toUpperCase())}</div>
          </StepItem>
        ))}
      </div>
    </>
  );
})

export default Step1Style;