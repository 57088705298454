import React, {FC} from 'react';
import {Radio} from "../../../../../components/FormControls";
import {ISubscriptionDataItem} from "../modules/utils";
import {useTranslation} from "react-i18next";

interface Props {
  data: ISubscriptionDataItem;
  period: string;
  active: boolean;
  onClick: () => void;
}

const SubscriptionsPlanItem: FC<Props> = ({data, active, onClick, period}) => {
  const {t} = useTranslation();

  return (
    <div className={`premium__item premium__item_sub${active ? ' active' : ''}`} onClick={onClick}>
      {data.recommended ?
        <div className="premium__item_badge">
          <div className="premium__item_badge-content">
            <span className=''>{t('RECOMMENDED')}</span>
          </div>
        </div>
        : null
      }
      <div className="premium__item_content">
        <div className="premium__item_sub-icon"/>

        <div className='ps-1 flex-1'>
          <div className='text-dark-14'>{period} {t(data.periodText)}</div>
          <div className='mt-1'>
            <span className='text-medium'>{data.was} </span><span
            className='text-tint text-14'>{t('DISCOUNT_OFF', {discount: data.discount})}</span>
          </div>
        </div>
        <div className='text-dark-14'>{data.price}/{t('MONTH')}</div>
      </div>

    </div>
  );
}

export default SubscriptionsPlanItem;