import React, {FC, useEffect, useState} from 'react';
import {ReactSVG} from "react-svg";
import ellipseTop from '../assets/icons/ellipse-top.svg';
import ellipseBottom from '../assets/icons/ellipse-bottom.svg';
import stars from '../assets/icons/stars.svg';
import done from '../assets/icons/done.svg';
import cancel from '../assets/icons/cancel.svg';
import expand from '../../../assets/icons/expand.svg';
import {useTranslation} from "react-i18next";
import ImageComponent from "../../../components/ImageComponent";
import {thumb, thumbAvatar} from "../../../modules/utils";
import {IAsset, IModel} from "../../../modules/rest";
import Avatar from "../../../components/Avatar";
import {openGalleryModal} from "../../../modals";
import BlurComponent from '../../../components/BlurComponent';

interface Props {
  finished: boolean
  error: string|null;
  title?: string;
  photo?: IAsset|null;
  model?: IModel|null;
  about?: boolean;
  showResult: boolean;
}

const GenerateAI: FC<Props> = ({
                                 finished,
                                 title,
                                 photo,
                                 about,
                                 model,
                                 error,
                                 showResult,
}) => {
  const {t} = useTranslation();
  const [dots, setDots] = useState(0);

  const handleOpen = () => {
    if(!showResult) return;
    openGalleryModal({image: photo, model})
  }


  useEffect(() => {
    if(showResult) return;
    const interval = setInterval(() => {
      setDots(prevState => {
        if (prevState === 3) return 0;
        return prevState + 1
      })
    }, 500);
    return () => {
      clearInterval(interval)
    }
  }, [showResult]);

  return (
    <div
      className={`generate__ai_container${finished ? ' finished' : ''}${error ? ' error' : ''}${showResult ? ' show__result' : ''}`}>
      <div className="generate__ai_image-container">
        <div className="generate__ai_image">
          <div className="generate__ai_bg"/>
          <ReactSVG src={ellipseTop} className='react-icon generate__ai_ellipse-top'/>
          <ReactSVG src={ellipseBottom} className='react-icon generate__ai_ellipse-bottom'/>
          <ReactSVG src={stars} className='react-icon generate__ai_stars'/>
          <ReactSVG src={error ? cancel : done} className='react-icon generate__ai_done'/>
          <div className="generate__ai_text">
            {t(title || 'WAITING')}
            <div className="generate__ai_text-dots">{Array(dots).fill({}).map((_, i) => <span key={i}>.</span>)}</div>
          </div>
        </div>
        {photo ?
          <BlurComponent
            contentContainerClassName='gallery__item_container'
            isNsfw={(photo?.isNsfw) && showResult}
            className='generate__ai_model btn-expand-container'
            onClick={handleOpen}
          >
            <ReactSVG src={expand} className='react-icon btn-expand'/>
            <ImageComponent
              src={thumb(photo.id, 800)}
              preloadSrc={thumb(photo.id, 50)}
              alt={'model_' + photo.id}
            />
            <div className="generate__ai_model-name">
              <span className='position-relative text-medium'>{model?.firstName} <span
                className='text-muted'>{model?.age}</span></span>
            </div>
          </BlurComponent>
          : null
        }

      </div>
      {about &&
        <div className="generate__ai_about-container">
          <div className="generate__ai_about-animate">
            <div className="generate__ai_about-item item__avatar"/>
            <div className='mt-2'>
              <div className="generate__ai_about-item item__title"/>
              <div className="generate__ai_about-item item__title"/>
            </div>
            <div className='mt-2'>
              <div className="generate__ai_about-item item__text"/>
              <div className="generate__ai_about-item item__text"/>
              <div className="generate__ai_about-item item__text"/>
              <div className="generate__ai_about-item item__text"/>
            </div>
          </div>
          {model
            ?
            <div className="generate__ai_about">
              <div className='position-relative' onClick={handleOpen}>
                <Avatar image={thumbAvatar(model.image?.id, 176)} size='xl'/>
                <ReactSVG src={expand} className='react-icon generate__ai_about-expand'/>
              </div>
              <h4 className='mt-3'>{t(model.name)}</h4>
              <div className='text-dark mt-2'>{t('YEARS_OLD', {years: model.age})}</div>
              <p className='mt-3 text-dark text-center' style={{maxWidth: 290}}>{model.aboutLocal}</p>
            </div>
            :
            null
          }

        </div>
      }

    </div>
  );
}

export default GenerateAI;