import asyncModal from 'react-async-modal';
import {ReactSVG} from 'react-svg';
import React, {FC, useLayoutEffect, useRef, useState} from 'react';
import close_svg from '../../assets/icons/close.svg';
import arrow_tr from '../../assets/icons/arrow_tr.svg';
import {observer} from 'mobx-react';
import {IConversation, LevelCost} from '../../modules/rest';
import Avatar from '../../components/Avatar';
import {thumbAvatar} from '../../modules/utils';
import Progress from '../../components/Progress';
import {useTranslation} from 'react-i18next';
import Slider from 'react-slick';
import Button from '../../components/Button';
import {toast} from 'react-toastify';
import {API} from '../../modules/api';
import {openModalNoMoney} from "../ModalNoMoney";
import AppStore from '../../store/AppStore';


const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '88px',
  responsive: [
    {
      breakpoint: 991,
      settings: {
        arrows: false,
        centerPadding: '20px',
      }
    }
  ]
};

interface Props {
  conversation: IConversation;
  resolve(path?: string): void;
}

const ChatLvlModal: FC<Props> = observer(({resolve, conversation}) => {
  const {t} = useTranslation();
  const _slider: any = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(conversation.currentLevel - 1);

  useLayoutEffect(() => {
    _slider?.current?.slickGoTo(conversation.currentLevel - 1, true);
  }, []);

  const handleClose = () => {
    resolve();
  };

  const onSubmit = (level: number) => async () => {
    try {
      await API.Conversations.upgradeLevel(conversation.id, {level});
    } catch (e: any) {
      const code = API.getStatusCode();
      if (code === 402) {
        if (AppStore.user?.isAnonymous) {
          resolve('/auth');
        } else {
          openModalNoMoney("chat").then((path) => {
            if (path) resolve(path);
          });
        }

      } else {
        toast.error(e);
      }
    }
  };

  return (
    <div className='modal__container modal__lvl'>
      <ReactSVG src={close_svg} className='modal-close' onClick={handleClose}/>
      <div className='d-flex p-4 pt-0 mb-2 align-items-center'>
        <Avatar image={thumbAvatar(conversation?.model?.image?.id, 176)}/>
        <div className='w-100 ms-2'>
          <h5>{conversation?.model?.name}</h5>
          <div className='text-14 mt-1'>
            <span className='text-dark'>{t('YOUR_LVL')}</span>
            <span className='text-tint ps-1'>{t(`CONVERSATION_LVL_${conversation.currentLevel || 1}`)}</span>
          </div>
        </div>
      </div>
      <Slider {...settings} ref={_slider} beforeChange={(currentSlide, nextSlide) => setCurrentSlide(nextSlide)}>
        {Object.values(LevelCost).map((price, i) => (
          <div className={`modal__lvl_item-container${currentSlide === i ? ' active' : ''}`} key={i}>
            <div className='modal__lvl_item'>
              <div className='modal__lvl_item-header'>
                <div className='modal__lvl_item-avatarBg'>
                  <Avatar user size='xxl'/>
                  <Avatar image={thumbAvatar(conversation?.model?.image?.id, 280)} size='xxl'/>
                </div>
                <div className='d-flex'>
                  <Avatar user size='xxl' secondaryIcon/>
                  <Avatar image={thumbAvatar(conversation?.model?.image?.id, 280)} size='xxl'/>
                </div>
              </div>
              {conversation.currentLevel === i + 1
                ?
                <>
                  <div className='d-flex justify-content-between'>
                    {t(`CONVERSATION_LVL_${i + 1}`)}
                    <div className='ms-2'>
                      <span className='text-obscure'>{t('lvl')}</span> <span>{i + 1}</span>
                    </div>
                  </div>
                  <Progress progress={conversation.progress} className='mt-2'/>
                </>
                :
                <>
                  <div className='d-flex justify-content-between'>
                    <div>
                      <span className='pe-2'>{t(`CONVERSATION_LVL_${i + 1}`)}</span>
                      <span className='text-obscure'>{t('lvl')}</span> <span>{i + 1}</span>
                    </div>
                    {conversation.currentLevel > i
                      ?
                      <div className='text-muted-14'>{t('LVL_PASSED')}</div>
                      :
                      <div>
                        <span className='text-dark'>{t('PRICE')}:</span>
                        <span className='text-medium ms-2'>{price} ⭐</span>
                      </div>
                    }
                  </div>
                  <div className='modal__lvl_item-separator'/>
                </>
              }
              <h4 className='mt-4 text-tint'>{t(`CONVERSATION_LVL_${i + 1}`)}</h4>
              <p className='mt-3 text-dark'>{t(`CONVERSATION_LVL_${i + 1}_TEXT`)}</p>
              {conversation.currentLevel <= i &&
                <Button
                  onClick={onSubmit(i + 1)}
                  size='md'
                  className='modal__lvl_btn'
                  icon={arrow_tr}
                  iconAbsolute
                  title='RAISE'
                  price={price!}
                />
              }
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
});

const openChatLvlModal = (conversation: IConversation): Promise<string|undefined> => {
  return asyncModal(ChatLvlModal, {conversation}, {
    showCloseIcon: false,
    center: true,
    blockScroll: true,
    classNames: {modalContainer: 'modal__bottom'}
  });
};

export {openChatLvlModal};
