import React, {FC} from 'react';
import chevron_left from '../../../assets/icons/chevron_left.svg';
import chevron_right from '../../../assets/icons/chevron_right.svg';
import check from '../../../assets/icons/check.svg';
import {useTranslation} from "react-i18next";
import Button from "../../../components/Button";
import { ICreateModelRequest} from "../../../modules/rest";
import {observer} from "mobx-react";

interface Props {
  showNext: boolean;
  step: 'summary'|keyof ICreateModelRequest;
  onBack: () => void;
  onSubmit: () => void;
  onNext: () => void;
}

const stepTitles: any = {
  age: "CHOOSE_AGE",
  bodyType: "CHOOSE_BODY_TYPE",
  breastSize: "CHOOSE_BREAST_SIZE",
  buttSize: "CHOOSE_BUTT_SIZE",
  ethnicity: "CHOOSE_ETHNICITY",
  hairColor: "CHOOSE_HAIR_COLOR",
  hairStyle: "CHOOSE_HAIR_STYLE",
  hobbies: "SELECT_INTERESTS",
  personality: "CHOOSE_PERSONALITY",
  interests: "CHOOSE_INTERESTS",
  relationship: "CHOOSE_RELATIONSHIP",
  style: "CHOOSE_STYLE",
  summary: 'SUMMARY'

  // [EGender.Female]: ['CHOOSE_STYLE', 'CHOOSE_ETHNICITY', 'CHOOSE_AGE', 'CHOOSE_HAIR_STYLE', 'CHOOSE_HAIR_COLOR', 'CHOOSE_BODY_TYPE', 'CHOOSE_BREAST_SIZE', 'CHOOSE_BUTT_SIZE', 'CHOOSE_PERSONALITY', 'SELECT_INTERESTS', 'CHOOSE_RELATIONSHIP', 'SUMMARY'],
  // [EGender.Male]: ['CHOOSE_STYLE', 'CHOOSE_ETHNICITY', 'CHOOSE_AGE', 'CHOOSE_HAIR_STYLE', 'CHOOSE_HAIR_COLOR', 'CHOOSE_BODY_TYPE', 'CHOOSE_PERSONALITY', 'SELECT_INTERESTS', 'CHOOSE_RELATIONSHIP', 'SUMMARY'],
}


const StepTitle: FC<Props> = observer(({onBack, step, onSubmit, onNext, showNext}) => {
  const {t} = useTranslation();
  return (
    <div className='create__ai_title'>
      <Button
        btnIcon
        icon={chevron_left}
        btnType='secondary'
        onClick={() => onBack()}
        className={`btn_left${step === 'style' ? ' hidden' : ''}`}
      />
      <h4>{t(stepTitles[step])}</h4>
      {step === 'summary' ?
        <Button
          btnIcon
          icon={check}
          onClick={onSubmit}
          className='btn_right'
        />
        :
        <Button
          btnIcon
          icon={chevron_right}
          btnType='secondary'
          onClick={() => onNext()}
          className={`btn_right${!showNext ? ' hidden' : ''}`}
        />
      }
    </div>
  );
})

export default StepTitle;